import React, {useState} from "react"
import { Route, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { db } from "../firebase"
import { useDispatch } from "react-redux"
import {login} from '../features/user'
import {orders} from '../features/orders'
//import { current } from "@reduxjs/toolkit"



export default function Nonsigned({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  const history = useHistory()
  const [roles, setRoles] = useState()
  const dispatch = useDispatch()

  if (currentUser){  db.collection('users').doc(currentUser.uid).get().then(doc => {
    if(!doc.exists) {return history.push("/PhoneSignUp")}
    dispatch (login({name:doc.data().displayName,role:doc.data().userRoles,email:doc.data().email}))
    setRoles(doc.data().userRoles)
                                  })

  const runorder = async () => {
    try {
      await db.collection('orders').doc(currentUser.uid).get().then(doc => {
        if (doc.data().Rated === true) {
          return dispatch (orders({ordered: true, Rated: true, from:doc.data().from,to:doc.data().to,status:doc.data().status,urgent:doc.data().urgent,whichway:doc.data().whichway,createdAt:'',price:doc.data().price,inOut:'',waiting:doc.data().waiting,}))
        }
        dispatch (orders({ordered: true, Rated: false, from:doc.data().from,to:doc.data().to,status:doc.data().status,urgent:doc.data().urgent,whichway:doc.data().whichway,createdAt:'',price:doc.data().price,inOut:'',waiting:doc.data().waiting,tempid: doc.data().tempid,}))
        }); 
    }
    catch (e) {
      
      dispatch (orders({ordered: false,from:'',to:'',status:'',urgent:'',whichway:''}))
    }
  }
  runorder();

}



  return (
  
    <Route
      {...rest}
      render={props => {
        if (roles) {
          if (roles.includes('user')) {return history.push("/")}
          if (roles.includes('admin')) { return history.push("/admin") }
          if (roles.includes('dispatch')){ return history.push("/dispatch") }
          if (roles.includes('paramedic')){ return history.push("/paramedic") }
          if (roles.includes('manager')){ return history.push("/manager") }
          if (roles.includes('supervisor')){ return history.push("/supervisor") }
          if (roles.includes('')){ return history.push("/PhoneSignUp") }
          } else {return <Component {...props} />}
        
      }}
    ></Route>
  )
}
