import React, { useRef, useState } from "react"
import { Form } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { db, functions } from "../firebase"
import { Link, useHistory } from "react-router-dom"
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Messages } from 'primereact/messages';
import moment from 'moment';
//import { handleUserProfile  } from "../contexts/utils"


export default function PhoneSignUp() {
  const displayName = useRef()
  const NID = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, logout } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [namerr, setNamerr] = useState("")
  const [nid, setNid] = useState("")
  const [emailerr, setEmailerr] = useState("")
  const message = useRef();
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const toast = useRef();
  const timestamp = moment(new Date()).format('MMMM Do YYYY, h:mm a'); // July 12th 2022, 3:52:42 am;

  async function handleSubmit(e) {
    e.preventDefault()

    if (!displayName.current.value) {
      return setNamerr('يرجى تعبئة الاسم بالشكل الصحيح')
      }
      if (!NID.current.value) {
        return setNid('يرجى إدخال الهوية الوطنية بالشكل الصحيح')
        }
        if (NID.current.value.length < 10 || NID.current.value.length > 10 ) {
            return setNid('يرجى التأكد من رقم الهوية الوطنية')
        }

    if (emailRef.current.value && !regex.test(emailRef.current.value.trim())) {
    return setEmailerr('البريد الإلكتروني المدخل غير صحيح، الرجاء التأكد')
        }

     setEmailerr('')
     setNamerr('')
     setNid('')
     setError('')

    try {
      setEmailerr('')
      setError("")
      setNid('')
      setLoading(true)

      const register = functions.httpsCallable('register');
  // call the function and pass data
  register({ name: displayName.current.value, email: emailRef.current.value, NID: NID.current.value, phone: currentUser.phoneNumber, createdAt: timestamp, uid: currentUser.uid}).then(result => {
     console.log(result.data);
  });
      toast.current.show({ severity: 'success', summary: 'تم تسجيلك بنجاح', detail: 'سيتم تحويلك بعد لحظات', life: 9000 });
      setTimeout(() => {history.push("/")}, 10000)


    } catch(err) {
      var eraor = ""
       if (err.code.startsWith("auth/email-already-in-use")) {
        eraor = "البريد الإلكتروني مسجل مسبقاً";
        setEmailerr(eraor)
        return setLoading(false)
      }
     message.current.show({ severity: 'error', content:"فشل تسجيل الدخول: "+err, life: 6000 });
    }

    //setLoading(false)
  }

  async function handleLogout() {
    setError("")
if (!currentUser) {return}
    try {
      await logout()
      history.push("/phonelogin")
    } catch {
      setError("Failed to log out")
    }
  }
  const [userName, setUserName] = useState(localStorage.getItem('lastLoggedInUserName') || 'مرحباً');

  return (
    <>
    <div className="flex flex-column align-items-center justify-content-center">
            <img src={'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo" style={{ width: '150px', height: '150px' }} />
                <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--blue) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5">
                            <img src={`/demo/images/login/user1.png`} alt="Image" height="80" className="mb-3" />
                            <div className="text-900 text-3xl font-medium mb-3">تسجيل جديد</div>
                            
                        </div>

                        <div>
                        <div> 
                          <Toast ref={toast} />
                          <div className="pl-8 pr-8 pb-5">
                          <Messages ref={message} className="rtl" />
                            <div className="">
                            
                              <Form className="rtl" onSubmit={handleSubmit}>
                              <Form.Group id="displayName">
                                  <Form.Label>الاسم الرباعي</Form.Label>
                                  <InputText className = "w-full mb-3" type="text" ref={displayName} />
                                  <div>{namerr && <small id="username-help" className="p-error" style={{fontSize:"small"}}>{namerr}</small>}</div>
                                </Form.Group>
                                <Form.Group id="NID">
                                  <Form.Label>رقم الهوية الوطنية</Form.Label>
                                  <InputText className = "w-full mb-3" type="text" ref={NID} />
                                  <div>{nid && <small id="username-help" className="p-error" style={{fontSize:"small"}}>{nid}</small>}</div>
                                </Form.Group>
                                <Form.Group id="email">
                                  <Form.Label>البريد الإلكتروني</Form.Label>
                                  <InputText className = "w-full mb-3" type="text" ref={emailRef} />
                                  <div>{emailerr && <small id="username-help" className="p-error" style={{fontSize:"small"}}>{emailerr}</small>}</div>
                                </Form.Group>
                                <Button label="تسجيل" disabled={loading} className="w-full" type="submit" />
                              </Form>
                              <div className="w-100 text-center mt-2">
                              <Link onClick={handleLogout} to="/phonelogin">إالغاء الأمر</Link>
                                </div>
                            </div>
                          </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </>
  )
}
