import React,{useState,useEffect,useRef} from "react"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomerService } from '../service/CustomerService';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { db } from "../firebase"
import {useAuth} from '../contexts/AuthContext'
import { TabMenu } from 'primereact/tabmenu';




//import {motion} from "framer-motion"
//import { Button } from 'primereact/button';




export default function Hastory() {
    const [customers2, setCustomers2] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [filters1, setFilters1] = useState(null);
    //const user = useSelector ((state) => state.user.value )
   // const order = useSelector ((state) => state.order.value )
    const [us, setUs] = useState([]);
    const { currentUser } = useAuth()
    const [activeIndex, setActiveIndex] = useState(0);
    const op = useRef(null);




    const customerService = new CustomerService();
    useEffect(() => {
        try {
             db.collection('archive').where('userid', '==', currentUser.uid).get().then((snapshot) => {
                if (snapshot){
                 console.log('Exists!!')
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc)
                  add.push({ id: doc.id, ...doc.data() })  
              })
              //console.log(add)
              setUs(add);
                } else {
                db.collection('orders').doc(currentUser.uid).get().then(doc => {
                 //console.log(snapshot.docs)
                 let add = [{simpleFrom:doc.data().simpleFrom,simpleTo:doc.data().simpleTo,status:doc.data().status,urgent:doc.data().urgent,whichway:doc.data().whichway,createdAt:doc.data().createdAt,price:doc.data().price,inOut:'',waiting:doc.data().waiting, tempid:doc.data().tempid,paymentmessage: doc.data().paymentmessage,showpay: doc.data().showpay}]
                 //console.log(add)
                 setUs(add);
                })
                }

              });
               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'representative': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
            'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    const getCustomers = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            return d;
        });
    }
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }
    var i=-1;
    const urgbody = (rowData) => {
        //console.log(i)
        i++
        if (i === us.length) {
            i=0;
        }
        if (us[i].urgent === 'غير طارئ') {
            return <span className={`customer-badge status-${'new'}`}>{us[i].urgent}</span>;
        }
        if (us[i].urgent === 'طارئ') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].urgent}</span>;
        }
        //return <span className={`customer-badge status-${us[i].urgent}`}>{us[i].urgent}</span>; 
    }
    var a=-1;
    const statusBodyTemplate = (rowData) => {
        a++
        if (a === us.length) {
            a=0;
        }

        if (us[i].status === 'تحت المراجعة') {
            return <span className={`customer-badge status-${'proposal'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'انتظار الدفع') {
            return <span className={`customer-badge status-${'negotiation'}`}>{us[i].status}</span>;
        } 
        if (us[i].status === 'قيد التنفيذ') { 
            return <span className={`customer-badge status-${'new'}`}>{us[i].status}</span>; 
        }
        if (us[i].status === 'تم بنجاح') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'ملغى') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].status}</span>;
        }
    }


    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    const balanceTemplate = (rowData) => {
        return (
            <span className="text-bold">
                {formatCurrency(rowData.balance)}
            </span>
        )
    }
    //console.log(us)


    const toggle = (event) => {
        op.current.toggle(event);
    };

    //console.log(dropdownValue)
    //console.log(radioValue)




    const wizardItems = [

        { label: 'جميع الطلبات', command: () => 
        {  try { setLoading2(true);
             db.collection('archive').where('userid', '==', currentUser.uid).get().then((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc)
                  add.push({ id: doc.id, ...doc.data() })  
              })
              //console.log(add)
              setUs(add);
              });
              setLoading2(false);
          }
          catch (e) { 
              console.log(e)
              setLoading2(false);
          }
        
        //setUs([order])
        }
},
    ];

  return (
    <> 
    <div className="grid p-fluid">
    

            <div className="col-12">
    

                <div className="card">
                <div className="w-100 text-center mt-">
                
                    <h5>الطلبات السابقة</h5>
                    </div>
                    <TabMenu className="rtl" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    <DataTable value={us}  loading={loading2} scrollDirection="both" className="mt-3" emptyMessage='لا يوجد طلبات'>
                        
                        <Column field="simpleFrom" header="المنزل" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="simpleTo" header="الوجهة" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="urgent" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }}  body={urgbody} ></Column>
                        <Column field="whichway" header="نوع الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="status" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} body={statusBodyTemplate}></Column>
                        <Column field="waiting" header="إنتظار الطاقم" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="price" header="السعر" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={true}></Column>
                        <Column field="createdAt" header="تاريخ الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={false}></Column>
                    </DataTable>
                </div>
            </div>

            </div>


    

    </>
  )
}
