import {createSlice} from '@reduxjs/toolkit'

var initialStateValue = {name:'',role:[''],phonenum:'',}


export const userSlice = createSlice({
    name: 'user',
    initialState: {value:initialStateValue}, // Can Have More Data in future
    reducers: {
        login: (state, action) => {
            state.value = action.payload
        },
    }
})

export const {login} = userSlice.actions

export default userSlice.reducer