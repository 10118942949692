import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom"
//import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { useAuth } from "../contexts/AuthContext"
import { Alert } from "react-bootstrap"
//import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { functions } from "../firebase"
import {useSelector} from 'react-redux'


//import { Form, Button, Card } from "react-bootstrap"
import "bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css"
//import {GoogleAutoComplete} from 'react-google-autocomplete-address-fields';
//import Autocomplete from "react-google-autocomplete";



const Updateprofile = (props) => {
   // const { register, handleSubmit, formState: { errors } } = useForm();
   // const onSubmit = data => console.log(data);
    //console.log(errors);
    const user = useSelector ((state) => state.user.value )
    //const order = useSelector ((state) => state.order.value )
    const [dropdownItem, setDropdownItem] = useState(null);
    const dropdownItems = [
        { name: 'Option 1', code: 'Option 1' },
        { name: 'Option 2', code: 'Option 2' },
        { name: 'Option 3', code: 'Option 3' }
    ];
  const { currentUser, updatePassword, logout } = useAuth()
  const emailRef = useRef()
  const displaynameref = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const [match, setMatch] = useState("")
  const [passerr, setPasserr] = useState("")
  const [same, setSame] = useState("")
  const [emailerr, setEmailerr] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  //const history = useHistory()
  const toast = useRef();

//console.log(user);
//console.log(order);

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setSame("كلمة المرور غير متطابقة")
    }  if (passwordRef.current.value === currentUser.password){
        setSame('')
      return setMatch("كلمة المرور الجديدة لايمكن ان تتطابق مع كلمة المرور السابقة")  
    }




    const promises = []
    setLoading(true)
    setError("")
    setSame('')
    if (emailRef.current.value.trim() !== user.email) {
      const update = functions.httpsCallable('update');
      // call the function and pass data
      update({ email: emailRef.current.value.trim(), name: displaynameref.current.value, uid: currentUser.uid}).then(result => {
         console.log(result.data);
      });
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }
    if (displaynameref.current.value !== user.name) {
      const update = functions.httpsCallable('update');
  // call the function and pass data
  update({ email: emailRef.current.value.trim(), name: displaynameref.current.value, uid: currentUser.uid}).then(result => {
     console.log(result.data);
  });
      //db.collection('users').doc(currentUser.uid).update({ displayName: displaynameref.current.value});
    }
    Promise.all(promises)
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'تم تحديث البيانات بنجاح', detail: '', life: 5000 });
      //setTimeout(() => {history.push("/")}, 5500)
      })
      .catch((err) => {
        var eraor = ""
       // console.log(err)
      if (err.code.startsWith("auth/requires-recent-login")) {
       eraor = "انتهت مدة تسجيل الدخول، يرجى إعادة تسجيل الدخول";
       setError("فشلت عملية تحديث البيانات: "+eraor)
       setTimeout(() => {logout()}, 15000)
       return setLoading(false)
      }
      else if (err.code.startsWith("auth/email-already-in-use")) {
        eraor = "البريد الإلكتروني مسجل مسبقاً";
        setEmailerr(eraor)
        return setLoading(false)
      }
      else if (err.code.startsWith("auth/weak-password")) {
        eraor = "كلمة المرور ضعيفة، الرجاء إدخال ٦ حروف وأرقام على الأقل";
        setPasserr(eraor)
        return setLoading(false)
      }
       
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
      
  }

    return (
    
        <div className="grid">
            <div className="col-12">
            <div className="card p-fluid">
                <div className="text-center mb-4">
                    <h5>ملف التعريف</h5>
                    </div>
                    {error && <Alert className="rtl" variant="danger">{error}</Alert>}
                    <Toast ref={toast} />
                    <div className="formgrid grid rtl">
                        <div className="field col">
                            <label htmlFor="email">البريد الإلكتروني</label>
                            <InputText id="email" ref={emailRef} type="text" defaultValue={user.email} />
                            <div>{emailerr && <small id="username-help" className="p-error">{emailerr}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="password">كلمة المرور الجديدة</label>
                            <InputText id="password" ref={passwordRef} type="password" placeholder="اتركها فارغة، إن لم تريد تغيير كلمة المرور"  />
                            <div>{passerr && <small id="username-help" className="p-error">{passerr}</small>}</div>
                            <div>{match && <small id="username-help" className="p-error">{match}</small>}</div>
                            <div>{same && <small id="username-help" className="p-error">{same}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="password">تأكيد كلمة المرور الجديدة</label>
                            <InputText id="password" ref={passwordConfirmRef} type="password" placeholder="اتركها فارغة، إن لم تريد تغيير كلمة المرور"  />
                            <div>{same && <small id="username-help" className="p-error">{same}</small>}</div>
                        </div>
                    </div>
                </div>
                <div className="card">

                <div className="p-fluid formgrid grid rtl">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="fullname">الإسم الرباعي</label>
                            <InputText id="fullname" ref={displaynameref} type="text" defaultValue={user.name} />
                        </div>

                        <div className="field col-12">
                            <label htmlFor="address">العنوان</label>
                            <InputTextarea disabled={true} id="address" rows="4" />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="city">المدينة</label>
                            <InputText  disabled={true} id="city" type="text" />
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="state">المنطقة</label>
                            <Dropdown  disabled={true} id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="الرجاء الإختيار"></Dropdown>
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="zip">الصندوق البريدي</label>
                            <InputText  disabled={true} id="zip" type="text" />
                        </div>
                    </div>

      <div className="w-100 text-center mt-2">
        <button disabled={loading} onClick={handleSubmit}  className="p-button p-component p-button-raised mr-2 mb-2" variant="link" type="submit">
          تحديث البيانات
        </button>
      </div>

                </div>
                <div className="w-100 text-center mt-2">
        <Link to="/" className="p-button-text mr-2 mb-2">
            إلغاء الأمر
        </Link>
  

      </div>


            </div>
        </div>
        
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Updateprofile, comparisonFn);
