import React,{useState,useEffect,useRef} from "react"
import { ToggleButton } from 'primereact/togglebutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomerService } from '../service/CustomerService';
import {useSelector} from 'react-redux'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { db } from "../firebase"
import { Form } from "react-bootstrap"
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { TabMenu } from 'primereact/tabmenu';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import {useAuth} from '../contexts/AuthContext'
import { Chip } from 'primereact/chip';
import { Tag } from 'primereact/tag';
import {useHistory} from 'react-router-dom';
import {MdVerified} from "react-icons/md";

//import {motion} from "framer-motion"
//import { Button } from 'primereact/button';




export default function Supervisor() {
    const [customers2, setCustomers2] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [filters1, setFilters1] = useState(null);
    const user = useSelector ((state) => state.user.value )
   // const order = useSelector ((state) => state.order.value )
    const [us, setUs] = useState([]);
    const [para, setPara] = useState(null);
    const [nod, setNod] = useState(true);
    const [par, setPar] = useState(true);
    const [hid, setHid] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [radioValue, setRadioValue] = useState(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [dis, setDis] = useState(false);
    const priceRef = useRef();
    const timestamp = moment(new Date()).format('MMMM Do YYYY, h:mm a'); // July 12th 2022, 3:52:42 am;

    const [err0,setErr0] = useState();
    const [err1,setErr1] = useState();
    const [err2,setErr2] = useState();
    const [suc,setSuc] = useState();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const op = useRef(null);
    const [noattach,setNoattach] = useState(true);
    const [nomemo,setNomemo] = useState(true);
    const toast = useRef();
    const [canceled, setCanceled] = useState(false);
    const { currentUser } = useAuth()
    const [tickToday, setTickToday] = useState(true);
    const [tickTodayText, setTickTodayText] = useState(true);
    const [star, setStar] = useState(true);
    const history = useHistory();


    const customerService = new CustomerService();
    useEffect(() => {
        if (user.uid === 'pJishGcQDZbY0mwMYLOCxrnSWIM2' || user.uid === '29avuJD7oQgaclNAtzXcm1MG0Fd2' || user.uid === 'BaFFNAioiUSAbbms1HBbhfuMD4G2') {
            setStar(false);
        }
        try {
             db.collection('orders').where('status', '==', 'قيد التنفيذ').get().then((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc)
                  add.push({ id: doc.id, ...doc.data() })  
              })
              //console.log(add)
              setUs(add);
              });
               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'representative': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
            'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    const getCustomers = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            return d;
        });
    }
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }
    var i=-1;
    const urgbody = (rowData) => {
        //console.log(i)
        i++
        if (i === us.length) {
            i=0;
        }
        if (us[i].urgent === 'غير طارئ') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].urgent}</span>;
        }
        if (us[i].urgent === 'طارئ') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].urgent}</span>;
        }
        //return <span className={`customer-badge status-${us[i].urgent}`}>{us[i].urgent}</span>; 
    }
    var a=-1;
    const statusBodyTemplate = (rowData) => {
        a++
        if (a === us.length) {
            a=0;
        }

        if (us[i].status === 'تحت المراجعة') {
            return <span className={`customer-badge status-${'proposal'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'انتظار الدفع') {
            return <span className={`customer-badge status-${'negotiation'}`}>{us[i].status}</span>;
        } 
        if (us[i].status === 'قيد التنفيذ') { 
            return <span className={`customer-badge status-${'new'}`}>{us[i].status}</span>; 
        }
        if (us[i].status === 'تم بنجاح') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'ملغى') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].status}</span>;
        }
    }

    var b=-1;
    const SubscriberTemplate = (rowData) => {
        b++
        if (b === us.length) {
            b=0;
        }

        if (us[i].Subscriber === true) {
            return <MdVerified style={{ fontSize: '2rem' }}/>
        }
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    const balanceTemplate = (rowData) => {
        return (
            <span className="text-bold">
                {formatCurrency(rowData.balance)}
            </span>
        )
    }
    //console.log(us)

    if (para !== null) {
       // console.log(para)
        const dropdownValues = [
            { name: 'NewYork', code: 'NY' },
            { name: 'Rome', code: 'RM' },
            { name: 'London', code: 'LDN' },
            { name: 'Istanbul', code: 'IST' },
            { name: 'Paris', code: 'PRS' }
        ];
    }
    const toggle = (event) => {
        op.current.toggle(event);
    };

    //console.log(dropdownValue)
    //console.log(radioValue)
    var tim = moment().format('hh:mma')
    var calendor = moment().format("DD MM YYYY hh:mma");
    var today = moment().format('L');
    var timo = moment( tim ,'hh:mma');
    var beginningTime ;
    var endTime ;
    var shftda = moment(calendor, "DD MM YYYY hh:mma");
    useEffect(() => {
        //console.log(calendor)
        //console.log(tim)
        //console.log(timo)
        try {
             db.collection('ambulances').where('Availability', '==', 'available').get().then((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                if (doc.data().Today === today || doc.data().Anothaday === today){
                    if (shftda.isBefore(moment(doc.data().ShiftDate1, "DD MM YYYY hh:mma"))) { 
                   add.push({ id: doc.id, name: doc.data().Name, time: doc.data().Time, date: doc.data().ShiftDate, date1: doc.data().ShiftDate1, Para1: doc.data().FirstParaUid, Para2: doc.data().SecondParaUid, carnum: doc.data().Carnum,Firstparaname: doc.data().FirstParamedic,Secondparaname: doc.data().SecondParamedic})   
                    }
                }
                //console.log(doc.data().ShiftDate);
                  //console.log(doc.data())
                  //add.push({ id: doc.id, name: doc.data().Name, time: doc.data().Time, date: doc.data().ShiftDate, date1: doc.data().ShiftDate1, Para1: doc.data().FirstParaUid, Para2: doc.data().SecondParaUid})  
              })
              //console.log(add)
              setPara(add);
              //console.log(add)
              });
               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const rowExpansionTemplate = (data) => {
    
        if (expandedRows.length > 1) {
            setExpandedRows(null);
        }
        
        //console.log(expandedRows)
        if (data.attachment !== null) {
            setNoattach(false)
        } else {setNoattach(true)}

        if (data.HospitalMemo !== null) {
            setNomemo(false)
        } else {setNomemo(true)}

        if (data.status === 'ملغى') {
            setCanceled(true)
        } else {setCanceled(false)}

        if (data.status === 'قيد التنفيذ' && data.ambulance === '') {
            setPar(false)
        } else {setPar(true)}



        const confirmationDialogFooter = (
            <>
            <div className="w-100 text-center mt-">
                <Button type="button" label="لا" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
                <Button type="button" label="نعم" icon="pi pi-check" onClick={() => {setDisplayConfirmation(false); decline()} } className="p-button-text" autoFocus />
                </div>
            </>
        );
        

        if(data.TimeFrom.split(',')[0] === timestamp.split(',')[0]){
            setTickToday(false);
            setTickTodayText(true);
        } else {
            setTickToday(true);
            setTickTodayText(false);
        }

        async function decline() {
    
          try { setDis(true)
    
            await db.collection('orders').doc(data.id).update({
                status: 'ملغى',
              })

              await db.collection('archive').add({
                displayName: data.displayName,
                email: data.email,
                userid: data.id,
                phonenum: data.phonenum,
                attachment: data.attachment,
                from: data.from,
                to: data.to,
                HospitalMemo: data.HospitalMemo,
                Hospital: data.Hospital,
                locationfrom: data.locationfrom,
                locationto: data.locationto,
                whichway: data.whichway,
                urgent: data.urgent,
                waiting: data.waiting,
                fromcity: data.fromcity,
                simpleFrom: data.simpleFrom,
                simpleTo: data.simpleTo,
                tocity: data.tocity,
                paramedic: data.paramedic,
                status: 'ملغى',
                createdAt: data.createdAt,
                CanceledAt: timestamp,
                price:data.price,
                inOut:data.inOut,
                finished: false,
              })
            await db.collection("orders").doc(data.id).delete().then(() => {
                console.log("تم حذف الملف من الطلبات وحفظه في الأرشيف");
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
              setSuc('!تم تحديث حالة الطلب بنجاح')
              setDis(false)
          } catch (error) {
              console.log(error)
              setDis(false)
          }
        }



        async function handleSubmit(e) {
            e.preventDefault()
            setDis(true)
            beginningTime = moment(dropdownValue.time.split('-')[0], 'hh:mma');
            endTime = moment(dropdownValue.time.split('-')[1], 'hh:mma');
            var shftdate = moment(dropdownValue.date, "DD MM YYYY hh:mma");
            var shftdate0 = moment(dropdownValue.date1, "DD MM YYYY hh:mma");

            //console.log(calendor.isBefore(shftdate0));

        if (!priceRef.current.value && par){
            setErr1('يرجى تحديد السعر')
            return setDis(false)
        }
        if (radioValue === null && par) {
            setErr2('يرجى تحديد نوع الرحلة')
           return setDis(false)
        }  
        
        
          try { setDis(true)

            if (data.status === 'قيد التنفيذ') {
                await db.collection('orders').doc(data.id).update({
                    paramedic: [dropdownValue.Para1, dropdownValue.Para2],
                    ambulance: dropdownValue.carnum,
                    ambdoc: dropdownValue.id,
                    Secondparaname: dropdownValue.Secondparaname,
                    Firstparaname: dropdownValue.Firstparaname,
                    ambulancePickedAt: timestamp,
                    ambulancePickedBy: user.name+' ,ID '+currentUser.uid,
                    status_comment: dropdownValue.carnum +" تم إختيار سيارة الإسعاف ",
                  })
                  setSuc('!تم تحديث حالة الطلب بنجاح')
                  setDis(false)
                  setErr1('')
                  setErr2('')
    
                  await db.collection('ambulances').doc(dropdownValue.id).update({
                      Availability: 'busy',
                  }) 
            }

          } catch (error) {
              console.log(error)
              setDis(false)
          }
        }


        
        return (
            <div className="w-100 text-center mt-">
                <Toast ref={toast} />
                <div className="surface-section mb-7">
    <div className="font-medium text-3xl text-900 mb-3">{data.Subscriber ? <MdVerified/> : null} طلب {data.displayName}<a rel="noopener noreferrer" href={data.attachment} target="_blank"><Button icon="pi pi-paperclip" className="p-button-rounded p-button-success p-button-text mr-2 mb-2" hidden={noattach}></Button></a></div>
    <Button onClick={() => {navigator.clipboard.writeText(data.phonenum).then(toast.current.show({ severity: 'success', summary: '!تم نسخ رقم العميل', detail: 'يمكنك الآن لصق الرقم', life: 4000 }))}} icon="pi pi-phone" className="p-button-rounded p-button-secondary p-button-text mr-2 mb-2" style={{ fontSize: '2rem' }}/>
    <a rel="noopener noreferrer" href={`https://wa.me/${data.phonenum}`} target="_blank"><Button icon="pi pi-whatsapp" className="p-button-rounded p-button-success p-button-text mr-2 mb-2" /></a>

    <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.email}</div>
            <div className="text-500 w-6 md:w-2 font-medium">إيميل العميل</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <Chip label={data.TimeFrom} className="mr-2"></Chip>
                <Chip label="->" className="mr-2"></Chip>
                <Chip label={data.TimeTo}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">موعد العميل</div>
        </li>
            <li  className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"><Chip label="معلومات إضافية"  icon="pi pi-info-circle" style={{ fontWeight: 'bold' }}></Chip></div>
            </li>
            <li className="flex align-items-center py-3 px-2 surface-border flex-wrap mt-3">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip id="inputnumber" label={data.userAGE+' years old'}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">العمر</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip id="inputnumber" label={data.details}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">الحالة المرضية وتفاصيل اخرى</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip id="inputnumber" label={data.userWEIGHT+' kg'}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">الوزن</div>
            </li>
            
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>

    </ul>
    <div>
                <div>
                <Tag className="mr-2 mb-2 " icon="pi pi-check" value={data.acceptedAt+" : تم قبول هذا الطلب في "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-user" severity="info" value={data.acceptedBy?.split(",")[0]+" : من قِبل "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-credit-card" severity="success" value={" طريقة الدفع : "+data.paymentMETHOD} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-flag" value={" حالة الطلب المفصلة "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-spin pi-spinner" value={data.status_comment} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
            </div>
</div>
        <Form onSubmit={handleSubmit}>
          
            <Form.Group className="text-center mb-4 rtl" id="Order-urgent" hidden={true}>
            <InputText type="text" placeholder="سعر الرحلة" ref={priceRef} ></InputText> 
            <div className="text-center mb-4">{err1 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err1}</small>}</div>
            </Form.Group>
            <Form.Group className="text-center mb-4" id="Order-urgent" hidden={par}>
              <h5>اختيار سيارة الإسعاف</h5>

              <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)} options={para} optionLabel="name" placeholder="سيارات الإسعاف" emptyMessage='لايوجد سيارات إسعاف مستعدة لقبول الطلب' />
              <div className="text-center mb-4">{err0 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err0}</small>}</div>
            </Form.Group>
            <Form.Group hidden={true} className="text-center mb-4" style={{display: 'flex', justifyContent: 'center'}}>

                            <div className="field-radiobutton" style={{paddingRight:8}}>
                                <RadioButton inputId="option1" name="option" value="In" checked={radioValue === 'In'} onChange={(e) => setRadioValue(e.value)} />
                                <label htmlFor="option1">داخلية</label>
                            </div>
                            <div className="field-radiobutton">
                                <RadioButton inputId="option2" name="option" value="Out" checked={radioValue === 'Out'} onChange={(e) => setRadioValue(e.value)} />
                                <label htmlFor="option2">خارجية</label>
                            </div>
            </Form.Group>
            
            <div className="text-center mb-4">{err2 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err2}</small>}</div>

            <div className="text-center mb-4">{suc && <small id="order-help" style={{fontSize:"small", color:"green"}} className="p-error">{suc}</small>}</div>
             <Form.Group className="text-center mb-4 rtl" id="Order-urgent" hidden={nomemo || canceled}>
                 <h6>وجهة العميل: {data.Hospital?.split(",")[0]}</h6>
            <Panel header="ملاحظات العميل لقسم الطوارئ" toggleable collapsed>
                        <p>{data.HospitalMemo}</p>
                    </Panel>
            </Form.Group>
            <p style={{ color:"red" }} hidden={tickTodayText}>
					لا يمكن تحديد سيارة الإسعاف قبل او بعد موعد الطلب
				</p>
            <Button label="قبول وتحديث الطلب" className="mr-2 mb-2" type="submit" disabled={dis || tickToday} loading={dis} hidden={canceled} />
          </Form>
            </div>
        );
    }

    const wizardItems = [
        { label: 'الطلبات الجديدة', command: () => {  
            
            try { setLoading2(true);
            db.collection('orders').where('status', '==', 'تحت المراجعة').get().then((snapshot) => {
                //console.log(snapshot.docs)
                let add = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 add.push({ id: doc.id, ...doc.data() })  
             })
             //console.log(add)
             setUs(add);
             });
             setLoading2(false);
         }
         catch (e) { 
             console.log(e)
             setLoading2(false);
         }
       
       //setUs([order])
       }},

        { label: 'جميع الطلبات', command: () => 
            {  try { setLoading2(true);
                 db.collection('orders').get().then((snapshot) => {
                     //console.log(snapshot.docs)
                     let add = []
                  snapshot.docs.forEach((doc) => {
                      //console.log(doc)
                      add.push({ id: doc.id, ...doc.data() })  
                  })
                  //console.log(add)
                  setUs(add);
                  });
                  setLoading2(false);
              }
              catch (e) { 
                  console.log(e)
                  setLoading2(false);
              }
            
            //setUs([order])
            }
    },
        { label: 'الطلبات الملغية', command: () => 
        {  try { setLoading2(true);
            db.collection('archive').where('status', '==', 'ملغى').get().then((snapshot) => {
                //console.log(snapshot.docs)
                let add = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 add.push({ id: doc.id, ...doc.data() })  
             })
             //console.log(add)
             setUs(add);
             });
             setLoading2(false);
         }
         catch (e) { 
             console.log(e)
             setLoading2(false);
         }
       
       //setUs([order])
       }
    
    },
    ];

  return (
    <> 
    <div className="grid p-fluid">
    

            <div className="col-12 md:col-12">
    

                <div className="card">
                <div align="right" hidden={star}><Button onClick={()=>{history.push("/manager/ambview")}} icon="pi pi-star-fill" className="mr-2 mb-2"></Button></div>
                <div className="w-100 text-center mt-">
                
                    <h5>الطلبات</h5>
                    </div>
                    <DataTable value={us}  loading={loading2} scrollDirection="both" className="mt-3" emptyMessage='لا يوجد طلبات'
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data) } rowExpansionTemplate={rowExpansionTemplate}responsiveLayout="scroll">
                        <Column expander style={{ width: '5em' }} />
                        <Column field="simpleFrom" header="المنزل" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="simpleTo" header="الوجهة" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="urgent" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={hid} body={urgbody} ></Column>
                        <Column field="whichway" header="نوع الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="status" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} body={statusBodyTemplate}></Column>
                        <Column field="waiting" header="إنتظار الطاقم" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="price" header="السعر" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={nod}></Column>
                        <Column field="createdAt" header="تاريخ الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={false}></Column>
                        <Column field="Subscriber" header="" style={{  flexGrow: 1, flexBasis: '160px' }} body={SubscriberTemplate} hidden={false}></Column>
                    </DataTable>
                </div>
                <div className="w-100 text-center mt-">
                <Button label="ادفع الآن" className="p-button-rounded mr-2 mb-2" hidden={nod} />
                </div>
            </div>

            </div>


    

    </>
  )
}
