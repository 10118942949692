import React,{useState,useEffect,useRef} from "react"
import { ToggleButton } from 'primereact/togglebutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomerService } from '../service/CustomerService';
import {useSelector} from 'react-redux'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { db } from "../firebase"
import { Form } from "react-bootstrap"
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { TabMenu } from 'primereact/tabmenu';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { Menu } from 'primereact/menu';
import { Link, useHistory } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { AutoComplete } from 'primereact/autocomplete';
import { Paramedics } from '../service/Paramedics';
import { NodeService } from '../service/NodeService';



//import {motion} from "framer-motion"
//import { Button } from 'primereact/button';




export default function AmbulancesView() {
    const [customers2, setCustomers2] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [filters1, setFilters1] = useState(null);
    //const user = useSelector ((state) => state.user.value )
   // const order = useSelector ((state) => state.order.value )
    const [us, setUs] = useState([]);
    const [para, setPara] = useState(null);
    const [nod, setNod] = useState(true);
    const [par, setPar] = useState(true);
    const [hid, setHid] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [radioValue, setRadioValue] = useState(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [dis, setDis] = useState(false);
    const priceRef = useRef();
    const timestamp = moment(new Date()).format('MMMM Do YYYY, h:mm a'); // July 12th 2022, 3:52:42 am;

    const [err0,setErr0] = useState();
    const [err1,setErr1] = useState();
    const [err2,setErr2] = useState();
    const [suc,setSuc] = useState();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const op = useRef(null);
    const [noattach,setNoattach] = useState(true);
    const [nomemo,setNomemo] = useState(true);
    const toast = useRef();
    const [canceled, setCanceled] = useState(false);
    const history = useHistory()
    const [calendarValue, setCalendarValue] = useState(null);
    const [time, setTime] = useState(null);
    const [time0, setTime0] = useState(null);
    const [teamleader,setTeamleader] = useState(null);
    const [secondpara, setSecondpara] = useState(null);
    const [anothaday,setAnothaday] = useState(null);
    const [selectedAutoValue, setSelectedAutoValue] = useState(null);
    const [selectedAutoValue0, setSelectedAutoValue0] = useState(null);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(null);
    const [treeSelectNodes, setTreeSelectNodes] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const [loading, setLoading] = useState(null);
    const dropdownValues = [
        { name: 'N01', code: 'N01' },
        { name: 'N02', code: 'N02' },
        { name: 'N03', code: 'N03' },
        { name: 'N04', code: 'N04' },
        { name: 'N05', code: 'N05' },
        { name: 'N06', code: 'N06' },
        { name: 'N07', code: 'N07' },
        { name: 'N08', code: 'N08' },
        { name: 'N09', code: 'N09' },
        { name: 'N10', code: 'N10' },
        { name: 'N11', code: 'N11' },
        { name: 'N12', code: 'N12' },
        { name: 'N13', code: 'N13' },
        { name: 'N14', code: 'N14' },
        { name: 'N15', code: 'N15' },
        { name: 'N16', code: 'N16' },
        { name: 'N17', code: 'N17' },
        { name: 'N18', code: 'N18' },
        { name: 'N19', code: 'N19' },
        { name: 'N20', code: 'N20' },
        { name: 'N21', code: 'N21' },
        { name: 'N22', code: 'N22' },
        { name: 'N23', code: 'N23' },
        { name: 'N24', code: 'N24' },
        { name: 'N25', code: 'N25' },
        { name: 'N26', code: 'N26' },
        { name: 'N27', code: 'N27' },
        { name: 'N28', code: 'N28' },
        { name: 'N29', code: 'N29' },
        { name: 'N30', code: 'N30' },
        { name: 'N31', code: 'N31' },
        { name: 'N32', code: 'N32' },
        { name: 'N33', code: 'N33' },
        { name: 'N34', code: 'N34' },
        { name: 'N35', code: 'N35' },
        { name: 'N36', code: 'N36' },
        { name: 'N37', code: 'N37' },
        { name: 'N38', code: 'N38' },
        { name: 'N39', code: 'N39' },
        { name: 'N40', code: 'N40' },
        { name: 'N41', code: 'N41' },
        { name: 'N42', code: 'N42' },
        { name: 'N43', code: 'N43' },
        { name: 'N44', code: 'N44' },
        { name: 'N45', code: 'N45' },
        { name: 'N46', code: 'N46' },
        { name: 'N47', code: 'N47' },
        { name: 'N48', code: 'N48' },
        { name: 'N49', code: 'N49' },
        { name: 'N50', code: 'N50' }
      ];


    const customerService = new CustomerService();
    useEffect(() => {
        try {
             db.collection('ambulances').where('Availability', '==', 'available').get().then((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc)
                  add.push({ id: doc.id, ...doc.data() })  
              })
              //console.log(add)
              setUs(add);
              });
               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const countryService = new Paramedics();
        const nodeService = new NodeService();
        countryService.getCountries().then(data => setAutoValue(data));
        nodeService.getTreeNodes().then(data => setTreeSelectNodes(data));
    }, []);
      const searchCountry = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            }
            else {
                setAutoFilteredValue(autoValue.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'representative': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
            'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    const getCustomers = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            return d;
        });
    }
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }
    var i=-1;
    const urgbody = (rowData) => {
        //console.log(i)
        i++
        if (i === us.length) {
            i=0;
        }
        if (us[i].urgent === 'غير طارئ') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].urgent}</span>;
        }
        if (us[i].urgent === 'طارئ') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].urgent}</span>;
        }
        //return <span className={`customer-badge status-${us[i].urgent}`}>{us[i].urgent}</span>; 
    }
    var a=-1;
    const statusBodyTemplate = (rowData) => {
        a++
        if (a === us.length) {
            a=0;
        }

        if (us[i].status === 'تحت المراجعة') {
            return <span className={`customer-badge status-${'proposal'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'انتظار الدفع') {
            return <span className={`customer-badge status-${'negotiation'}`}>{us[i].status}</span>;
        } 
        if (us[i].status === 'قيد التنفيذ') { 
            return <span className={`customer-badge status-${'new'}`}>{us[i].status}</span>; 
        }
        if (us[i].status === 'تم بنجاح') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'ملغى') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].status}</span>;
        }
    }


    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    const balanceTemplate = (rowData) => {
        return (
            <span className="text-bold">
                {formatCurrency(rowData.balance)}
            </span>
        )
    }
    //console.log(us)

    if (para !== null) {
       // console.log(para)
        const dropdownValues = [
            { name: 'NewYork', code: 'NY' },
            { name: 'Rome', code: 'RM' },
            { name: 'London', code: 'LDN' },
            { name: 'Istanbul', code: 'IST' },
            { name: 'Paris', code: 'PRS' }
        ];
    }
    const toggle = (event) => {
        op.current.toggle(event);
    };

    //console.log(dropdownValue)
    //console.log(radioValue)

    useEffect(() => {
        try {
             db.collection('users').where('availability', '==', 'available').get().then((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc.data())
                  add.push({ id: doc.id, name: doc.data().displayName })  
              })
              //console.log(add)
              setPara(add);
              //console.log(add)
              });
               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const menuitems = [
        {
            label: 'المسعفين',
            items: [
                {
                    label: 'إضافة شفت',
                    icon: 'pi pi-fw pi-plus',
                    command: () => {history.push("/manager")}
                },
                {
                    label: 'تعديل الشفتات الموجودة',
                    icon: 'pi pi-fw pi-user-edit',
                    command: () => {history.push("/manager/ambview")}
                }
            ]
        }
      ];
    const rowExpansionTemplate = (data) => {
    
        if (expandedRows.length > 1) {
            setExpandedRows(null);
        }
        
        //console.log(expandedRows)
        if (data.attachment !== null) {
            setNoattach(false)
        } else {setNoattach(true)}

        if (data.HospitalMemo !== null) {
            setNomemo(false)
        } else {setNomemo(true)}

        if (data.status === 'ملغى') {
            setCanceled(true)
        } else {setCanceled(false)}

        if (data.status === 'قيد التنفيذ') {
            setPar(false)
        } else {setPar(true)}

        

        const confirmationDialogFooter = (
            <>
            <div className="w-100 text-center mt-">
                <Button type="button" label="لا" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
                <Button type="button" label="نعم" icon="pi pi-check" onClick={() => {setDisplayConfirmation(false); decline()} } className="p-button-text" autoFocus />
                </div>
            </>
        );
        


        async function decline() {
    
          try { setDis(true)
    
            await db.collection("ambulances").doc(data.id).delete().then(() => {
                console.log("تم حذف الشفت");
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
              setSuc('!تم حذف الشفت بنجاح')
              window.location.reload();
              setDis(false)
          } catch (error) {
              console.log(error)
              setDis(false)
          }
        }


        var mix;
        var anotha = false;
        var calendor = moment(calendarValue?.toString()).format('L');
        var calendor1day = moment(calendarValue?.toString()).add(1, 'days').format('L');

        async function handleSubmit(e) {
            e.preventDefault()
            if (calendarValue == null) {
                return setErr0('الرجاء إدخال التاريخ')
              }
              setErr0()
              if (dropdownValue == null) {
                return setErr1('الرجاء إختيار سيارة الإسعاف')
              }
              setErr1()
              if (time == null) {
                return setErr2('الرجاء إدخال الوقت')
              }
              if (time === "") {
                return setErr2('الرجاء إدخال الوقت')
              }
              //console.log(time.split(' ')[1])
              if (time.split(':')[0] > '12') {
                return setErr2('نظام الساعات ١٢ ساعة')
              }
              if (time.split(':')[1].split(' ')[0] > '59') {
                return setErr2('نظام الدقائق ليس اكثر من ٥٩ دقيقة')
              }
              setErr2()
              if (time0 == null) {
                return setErr2('الرجاء إدخال الوقت')
              }
              if (time0 === "") {
                return setErr2('الرجاء إدخال الوقت')
              }
              //console.log(time.split(' ')[1])
              if (time0.split(':')[0] > '12') {
                return setErr2('نظام الساعات ١٢ ساعة')
              }
              if (time0.split(':')[1].split(' ')[0] > '59') {
                return setErr2('نظام الدقائق ليس اكثر من ٥٩ دقيقة')
              }
              setErr2()
              if (selectedAutoValue == null) {
                return setTeamleader('يرجى إدخال قائد الفريق')
            }
             setTeamleader()
              if (selectedAutoValue0 == null) {
              return setSecondpara('يرجى إدخال قائد الفريق')
            }
             setSecondpara()
             if (time.split(' ')[1] === 'pm' && time0.split(' ')[1] === 'am') {
              setAnothaday(true);
               anotha = true;
            }
            mix = moment(calendor).format('LL');

            try{
                //console.log(mix);
              setSuc();
              setDisabled(true);
              setLoading(true);
              if (anotha === true){
                //console.log('Anothaday')
                  await db.collection('ambulances').doc(data.id).update({
                  Carnum: dropdownValue.name,
                  Time: time+'-'+time0,
                  FirstParamedic: selectedAutoValue.name,
                  SecondParamedic: selectedAutoValue0.name,
                  ShiftDate: calendor,
                  ShiftDate1: calendor1day,
                  Availability: 'available',
              }) 
              } else {
                //console.log('NormaDay')
                await db.collection('ambulances').doc(data.id).update({
                Carnum: dropdownValue.name,
                Time: time+'-'+time0,
                FirstParamedic: selectedAutoValue.name,
                SecondParamedic: selectedAutoValue0.name,
                ShiftDate: calendor,
                Availability: 'available',
              }) 
              }
              
              setDropdownValue(null);
              setCalendarValue(null);
              setTime(null);
              setTime0(null);
              setSelectedAutoValue(null);
              setSelectedAutoValue0(null);
              setAnothaday(false);
              setDisabled(false);
              setLoading(false);
              setSuc('!تم تحديث الشفت بنجاح');
              window.location.reload();
              
              }
              
              catch(err) {
              console.log(err)
              }
              
              
              
        }


        
        return (
            <div className="w-100 text-center mt-">
                <Toast ref={toast} />
                <h4> شفت {data.Today}</h4>
        <Form onSubmit={handleSubmit}>
          
            <Form.Group className="text-center mb-4 rtl" id="Order-urgent" hidden={!par || canceled}>
            <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)} options={dropdownValues} optionLabel="name" placeholder="إختار سيارة الإسعاف" />
            <div className="text-center mb-4">{err1 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err1}</small>}</div>
            </Form.Group>
            <Form.Group className="text-center mb-4" id="Order-urgent" hidden={false}>
              <h5>التاريخ</h5>
              <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>
              <div className="text-center mb-4">{err0 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err0}</small>}</div>
            </Form.Group>
            <h5>الوقت</h5>
            <Form.Group hidden={!par || canceled} className="text-center mb-4" style={{display: 'flex', justifyContent: 'center'}}>
            
                <InputMask  placeholder={'بداية الشفت'} className={"text-center"} id="inputmask" mask="99:99 am" value={time} onChange={(e) => setTime(e.value)} /> 
                <InputMask  placeholder={'نهاية الشفت'} className={"text-center"} id="inputmask" mask="99:99 am" value={time0} onChange={(e) => setTime0(e.value)} />
            </Form.Group>
            <div className="text-center mb-4">{err2 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err2}</small>}</div>

             <Form.Group className="text-center mb-4" id="Order-urgent" hidden={nomemo || canceled}>
             <h5>قائد الفرقة</h5>
             <AutoComplete multiple={false} placeholder="البحث" id="dd" dropdown value={selectedAutoValue} onChange={(e) => setSelectedAutoValue(e.value)} suggestions={autoFilteredValue} completeMethod={searchCountry} field="name"  />
            </Form.Group>
            <div className="text-center mb-4">{teamleader && <small id="city-help" style={{fontSize:"small"}} className="p-error">{teamleader}</small>}</div>
            <Form.Group className="text-center mb-4" id="Order-urgent" hidden={nomemo || canceled}>
             <h5>المسعف الثاني</h5>
             <AutoComplete multiple={false} placeholder="البحث" id="dd" dropdown value={selectedAutoValue0} onChange={(e) => setSelectedAutoValue0(e.value)} suggestions={autoFilteredValue} completeMethod={searchCountry} field="name"  />
            </Form.Group>
            <div className="text-center mb-4">{secondpara && <small id="city-help" style={{fontSize:"small"}} className="p-error">{secondpara}</small>}</div>
            <Button label="تحديث الشفت" className="mr-2 mb-2" type="submit" disabled={disabled} loading={loading}/>
            <Button label="حذف الشفت" className="p-button-danger p-button-text mr-2 mb-2" disabled={disabled} onClick={() => {decline()} } loading={loading}/>
            <div className="text-center mb-4">{suc && <small id="order-help" style={{fontSize:"small", color:"green"}} className="p-error">{suc}</small>}</div>
          </Form>
            </div>
        );
    }

    const wizardItems = [
        { label: 'الطلبات الجديدة', command: () => {  
            
            try { setLoading2(true);
            db.collection('orders').where('status', '==', 'تحت المراجعة').get().then((snapshot) => {
                //console.log(snapshot.docs)
                let add = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 add.push({ id: doc.id, ...doc.data() })  
             })
             //console.log(add)
             setUs(add);
             });
             setLoading2(false);
         }
         catch (e) { 
             console.log(e)
             setLoading2(false);
         }
       
       //setUs([order])
       }},

        { label: 'جميع الطلبات', command: () => 
            {  try { setLoading2(true);
                 db.collection('orders').get().then((snapshot) => {
                     //console.log(snapshot.docs)
                     let add = []
                  snapshot.docs.forEach((doc) => {
                      //console.log(doc)
                      add.push({ id: doc.id, ...doc.data() })  
                  })
                  //console.log(add)
                  setUs(add);
                  });
                  setLoading2(false);
              }
              catch (e) { 
                  console.log(e)
                  setLoading2(false);
              }
            
            //setUs([order])
            }
    },
        { label: 'الطلبات الملغية', command: () => 
        {  try { setLoading2(true);
            db.collection('archive').where('status', '==', 'ملغى').get().then((snapshot) => {
                //console.log(snapshot.docs)
                let add = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 add.push({ id: doc.id, ...doc.data() })  
             })
             //console.log(add)
             setUs(add);
             });
             setLoading2(false);
         }
         catch (e) { 
             console.log(e)
             setLoading2(false);
         }
       
       //setUs([order])
       }
    
    },
    ];

  return (
    <> 
    <div className="grid p-fluid">
    

            <div className="col-12 md:col-10">
    

                <div className="card">
                <div className="w-100 text-center mt-">
                
                    <h5>شفتات المسعفين المتاحة / المنتهية</h5>
                    </div>
                    <DataTable value={us}  loading={loading2} scrollDirection="both" className="mt-3" emptyMessage='لا يوجد شفتات مسعفين، يُمكنك إضافة الشفتات بالضغط على إضافة شفتات'
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data) } rowExpansionTemplate={rowExpansionTemplate}responsiveLayout="scroll">
                        <Column expander style={{ width: '5em' }} />
                        <Column field="Carnum" header="رقم السيارة" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="Today" header="تاريخ الشفت" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="FirstParamedic" header="قائد الفرقة" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="SecondParamedic" header="المسعف الثاني" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="Time" header="الوقت" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                       
                    </DataTable>
                </div>
                <div className="w-100 text-center mt-">
                <Button label="ادفع الآن" className="p-button-rounded mr-2 mb-2" hidden={nod} />
                </div>
            </div>
            <div className="col-12 md:col-2">
             <Menu model={menuitems} style={{fontSize:"big"}} />
            </div>
            </div>


    

    </>
  )
}
