import React,{useState,useEffect, useRef} from "react"

import { Image } from "primereact/image";
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Paramedics } from '../service/Paramedics';
import { NodeService } from '../service/NodeService';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import { db } from "../firebase"
import { Menu } from 'primereact/menu';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';




export default function Manager() {
  const [dropdownValue, setDropdownValue] = useState(null);
  const dropdownValues = [
    { name: 'N01', code: 'N01' },
    { name: 'N02', code: 'N02' },
    { name: 'N03', code: 'N03' },
    { name: 'N04', code: 'N04' },
    { name: 'N05', code: 'N05' },
    { name: 'N06', code: 'N06' },
    { name: 'N07', code: 'N07' },
    { name: 'N08', code: 'N08' },
    { name: 'N09', code: 'N09' },
    { name: 'N10', code: 'N10' },
    { name: 'N11', code: 'N11' },
    { name: 'N12', code: 'N12' },
    { name: 'N13', code: 'N13' },
    { name: 'N14', code: 'N14' },
    { name: 'N15', code: 'N15' },
    { name: 'N16', code: 'N16' },
    { name: 'N17', code: 'N17' },
    { name: 'N18', code: 'N18' },
    { name: 'N19', code: 'N19' },
    { name: 'N20', code: 'N20' },
    { name: 'N21', code: 'N21' },
    { name: 'N22', code: 'N22' },
    { name: 'N23', code: 'N23' },
    { name: 'N24', code: 'N24' },
    { name: 'N25', code: 'N25' },
    { name: 'N26', code: 'N26' },
    { name: 'N27', code: 'N27' },
    { name: 'N28', code: 'N28' },
    { name: 'N29', code: 'N29' },
    { name: 'N30', code: 'N30' },
    { name: 'N31', code: 'N31' },
    { name: 'N32', code: 'N32' },
    { name: 'N33', code: 'N33' },
    { name: 'N34', code: 'N34' },
    { name: 'N35', code: 'N35' },
    { name: 'N36', code: 'N36' },
    { name: 'N37', code: 'N37' },
    { name: 'N38', code: 'N38' },
    { name: 'N39', code: 'N39' },
    { name: 'N40', code: 'N40' },
    { name: 'N41', code: 'N41' },
    { name: 'N42', code: 'N42' },
    { name: 'N43', code: 'N43' },
    { name: 'N44', code: 'N44' },
    { name: 'N45', code: 'N45' },
    { name: 'N46', code: 'N46' },
    { name: 'N47', code: 'N47' },
    { name: 'N48', code: 'N48' },
    { name: 'N49', code: 'N49' },
    { name: 'N50', code: 'N50' }
  ];
  const [selectedAutoValue, setSelectedAutoValue] = useState(null);
  const [selectedAutoValue0, setSelectedAutoValue0] = useState(null);
  const [autoFilteredValue, setAutoFilteredValue] = useState([]);
  const [autoValue, setAutoValue] = useState(null);
  const [treeSelectNodes, setTreeSelectNodes] = useState(null);
  const [date, setDate] = useState(null);
  const [carnum, setCarnum] = useState(null);
  const [tim, setTim] = useState(null);
  const [teamleader, setTeamleader] = useState(null);
  const [secondpara, setSecondpara] = useState(null);
  const [calendarValue, setCalendarValue] = useState(null);
  const [time, setTime] = useState(null);
  const [time0, setTime0] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const [loading, setLoading] = useState(null);
  const [anothaday, setAnothaday] = useState(false);
  const history = useHistory()
  const toast = useRef();



  useEffect(() => {
    const countryService = new Paramedics();
    const nodeService = new NodeService();
    countryService.getCountries().then(data => setAutoValue(data));
    nodeService.getTreeNodes().then(data => setTreeSelectNodes(data));
}, []);
  const searchCountry = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            setAutoFilteredValue([...autoValue]);
        }
        else {
            setAutoFilteredValue(autoValue.filter((country) => {
                return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            }));
        }
    }, 250);
};

const menuitems = [
  {
      label: 'المسعفين',
      items: [
          {
              label: 'إضافة شفت',
              icon: 'pi pi-fw pi-plus',
              command: () => {history.push("/manager")}
          },
          {
              label: 'تعديل الشفتات الموجودة',
              icon: 'pi pi-fw pi-user-edit',
              command: () => {history.push("/manager/ambview")}
          }
      ]
  }
];


var calendor = moment(calendarValue?.toString()).format('L');
var calendor1day = moment(calendarValue?.toString()).add(1, 'days').format('L');
var mix;
var anotha=false;
async function handleSubmit(e) {
  e.preventDefault()  

  if (calendarValue == null) {
    return setDate('الرجاء إدخال التاريخ')
  }
  setDate()
  if (dropdownValue == null) {
    return setCarnum('الرجاء إختيار سيارة الإسعاف')
  }
  setCarnum()
  if (time == null) {
    return setTim('الرجاء إدخال الوقت')
  }
  if (time === "") {
    return setTim('الرجاء إدخال الوقت')
  }
  //console.log(time.split(' ')[1])
  if (time.split(':')[0] > '12') {
    return setTim('نظام الساعات ١٢ ساعة')
  }
  if (time.split(':')[1].split(' ')[0] > '59') {
    return setTim('نظام الدقائق ليس اكثر من ٥٩ دقيقة')
  }
  if (time.split(' ')[1] !== 'pm' && time.split(' ')[1] !== 'am') {
    return setTim('AM او PM يجب التحديد ما إذا كانت الفترة الزمنية')
  }
  if (time0.split(' ')[1] !== 'pm' && time0.split(' ')[1] !== 'am') {
    return setTim('AM او PM يجب التحديد ما إذا كانت الفترة الزمنية')
  }
  setTim()
  if (time0 == null) {
    return setTim('الرجاء إدخال الوقت')
  }
  if (time0 === "") {
    return setTim('الرجاء إدخال الوقت')
  }
  //console.log(time.split(' ')[1])
  if (time0.split(':')[0] > '12') {
    return setTim('نظام الساعات ١٢ ساعة')
  }
  if (time0.split(':')[1].split(' ')[0] > '59') {
    return setTim('نظام الدقائق ليس اكثر من ٥٩ دقيقة')
  }
  setTim()
  if (selectedAutoValue == null) {
    return setTeamleader('يرجى إدخال قائد الفريق')
}
 setTeamleader()
  if (selectedAutoValue0 == null) {
  return setSecondpara('يرجى إدخال قائد الفريق')
}
 setSecondpara()
 if (time.split(' ')[1] === 'pm' && time0.split(' ')[1] === 'am') {
  setAnothaday(true);
   anotha = true;
}

mix = moment(calendor).format('LL');

try{
  //console.log(mix);
setDisabled(true);
setLoading(true);
if (anotha === true){
  //console.log('Anothaday')
    await db.collection('ambulances').doc(mix+' '+dropdownValue.name+' '+time+'-'+time0).set({
    Carnum: dropdownValue.name,
    Time: time+'-'+time0,
    FirstParamedic: selectedAutoValue.name,
    SecondParamedic: selectedAutoValue0.name,
    ShiftDate: moment(calendor+' '+time).format('DD MM YYYY hh:mma'),
    ShiftDate1: moment(calendor1day+' '+time0).format('DD MM YYYY hh:mma'),
    Today: moment(calendor).format('L'),
    Anothaday: moment(calendor1day).format('L'),
    Name: '('+dropdownValue.name+')  '+time+' - '+time0,
    Availability: 'available',
}) 
} else {
  //console.log('NormaDay')
  await db.collection('ambulances').doc(mix+' '+dropdownValue.name+' '+time+'-'+time0).set({
  Carnum: dropdownValue.name,
  Time: time+'-'+time0,
  FirstParamedic: selectedAutoValue.name,
  SecondParamedic: selectedAutoValue0.name,
  FirstParaUid: selectedAutoValue.uid,
  SecondParaUid: selectedAutoValue0.uid,
  ShiftDate: moment(calendor+' '+time).format('DD MM YYYY hh:mma'),
  ShiftDate1: moment(calendor+' '+time0).format('DD MM YYYY hh:mma'),
  Today: moment(calendor).format('L'),
  Anothaday: '',
  Name: '('+dropdownValue.name+')  '+time+' - '+time0,
  Availability: 'available',
}) 
}

setDropdownValue(null);
setCalendarValue(null);
setTime(null);
setTime0(null);
setSelectedAutoValue(null);
setSelectedAutoValue0(null);
setAnothaday(false);
setDisabled(false);
setLoading(false);
toast.current.show({ severity: 'success', summary: 'تم إضافة الشفت بنجاح', detail: 'يمكنك الآن إضافة المزيد', life: 4000 });


}

catch(err) {
console.log(err)
}


}


  return (
    <>
    <Toast ref={toast} />
    <div className="grid">
    <div className="col-12 md:col-10">
      <div className="text-center">
      <Image preview={false} src="assets/demo/images/galleria/galleria16.png" alt="galleria" width={250} />
        <div className="mt-2">
      <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)} options={dropdownValues} optionLabel="name" placeholder="إختار سيارة الإسعاف" />
      <div className="text-center mb-4">{carnum && <small id="city-help" style={{fontSize:"small"}} className="p-error">{carnum}</small>}</div>
      </div>
      <div className="mt-2">
      <h5>التاريخ</h5>
      <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>
      <InputText className="ml-4" id="name2" type="text" disabled={true} value={calendor1day+' نهاية هذا الشفت ستكون في يوم'} hidden={!anothaday} />
      <div className="text-center mb-4">{date && <small id="city-help" style={{fontSize:"small"}} className="p-error">{date}</small>}</div>
      </div>
      <div className="mt-2">
      <h5>الوقت</h5>
      <InputMask  placeholder={'بداية الشفت'} className={"text-center"} id="inputmask" mask="99:99 am" value={time} onChange={(e) => setTime(e.value)} /> 
      <InputMask  placeholder={'نهاية الشفت'} className={"text-center"} id="inputmask" mask="99:99 am" value={time0} onChange={(e) => setTime0(e.value)} />
      <div className="text-center mb-4">{tim && <small id="city-help" style={{fontSize:"small"}} className="p-error">{tim}</small>}</div>
      </div>
      <div className="mt-4">
      <h5>قائد الفرقة</h5>
      <AutoComplete multiple={false} placeholder="البحث" id="dd" dropdown value={selectedAutoValue} onChange={(e) => setSelectedAutoValue(e.value)} suggestions={autoFilteredValue} completeMethod={searchCountry} field="name"  />
      <div className="text-center mb-4">{teamleader && <small id="city-help" style={{fontSize:"small"}} className="p-error">{teamleader}</small>}</div>
      </div>
      <div className="mt-4">
      <h5>المسعف الثاني</h5>
      <AutoComplete multiple={false} placeholder="البحث" id="dd" dropdown value={selectedAutoValue0} onChange={(e) => setSelectedAutoValue0(e.value)} suggestions={autoFilteredValue} completeMethod={searchCountry} field="name"  />
      <div className="text-center mb-4">{secondpara && <small id="city-help" style={{fontSize:"small"}} className="p-error">{secondpara}</small>}</div>
      </div>
      <div className="mt-4">
      <Button label="حفظ" className="mr-2 mb-2" onClick={handleSubmit} disabled={disabled} loading={loading}/>
      </div>
      </div>

    
    </div>
    <div className="col-12 md:col-2">
    <Menu model={menuitems} style={{fontSize:"big"}} />
    </div>
    </div>

    

    </>
  )
}
