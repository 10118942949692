import React,{useState,useEffect,useRef} from "react"
import styled from "styled-components";
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { ToggleButton } from 'primereact/togglebutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomerService } from '../service/CustomerService';
import {useSelector} from 'react-redux'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { db } from "../firebase"
import { Form } from "react-bootstrap"
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import {useAuth} from '../contexts/AuthContext'
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { SelectButton } from 'primereact/selectbutton';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import firebase from "firebase/app"
import { Chip } from 'primereact/chip';
import { Tag } from 'primereact/tag';
import { Paramedics } from '../service/Paramedics';
import { NodeService } from '../service/NodeService';
import moment from 'moment';
import {MdVerified} from "react-icons/md";

//import {motion} from "framer-motion"
//import { Button } from 'primereact/button';




export default function Para() {
    const [idFrozen, setIdFrozen] = useState(false);
    const [customers2, setCustomers2] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [filters1, setFilters1] = useState(null);
    //const user = useSelector ((state) => state.user.value )
   // const order = useSelector ((state) => state.order.value )
    const [us, setUs] = useState([]);
    const [para, setPara] = useState(null);
    const [nod, setNod] = useState(true);
    const [hid, setHid] = useState(false);
    const [selectedAutoValue, setSelectedAutoValue] = useState(null);
    const [selectedAutoValue0, setSelectedAutoValue0] = useState(null);
    const [selectedAutoValue1, setSelectedAutoValue1] = useState(null);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(null);
    const [treeSelectNodes, setTreeSelectNodes] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [radioValue, setRadioValue] = useState(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [dis, setDis] = useState(false);
    const priceRef = useRef();
    const [err0,setErr0] = useState();
    const [err1,setErr1] = useState();
    const [err2,setErr2] = useState();
    const [suc,setSuc] = useState();
    const { currentUser } = useAuth()
    const [calendarValue, setCalendarValue] = useState(null);
    const [selectButtonValue1, setSelectButtonValue1] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue2, setSelectButtonValue2] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue3, setSelectButtonValue3] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue4, setSelectButtonValue4] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue5, setSelectButtonValue5] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue6, setSelectButtonValue6] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue7, setSelectButtonValue7] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue8, setSelectButtonValue8] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue9, setSelectButtonValue9] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue10, setSelectButtonValue10] = useState({ name: 'لا', code: 'O2' });
    const [selectButtonValue11, setSelectButtonValue11] = useState({ name: 'لا', code: 'O2' });
    const [checkboxValue, setCheckboxValue] = useState([]);
    const [noattach,setNoattach] = useState(true);
    const storageRef = firebase.storage().ref();
    const [add,setAdd] = useState(true);
    const [add1,setAdd1] = useState(true);
    const [imageUpload, setImageUpload] = useState(null);
    const [appurl, setAppurl] = useState();
    const [images, setImages] = useState([]);
    const [urls, setUrls] = useState([]);
    const [date, setDate] = useState(null);
    const [teamleader, setTeamleader] = useState(null);
    const [secondparamedic, setSecondparamedic] = useState(null);
    const [carnumber, setCarnumber] = useState(null);
    const [cartemp, setCartemp] = useState(null);
    const [medicalrecord, setMedicalrecord] = useState(null);
    const [weight, setWeight] = useState(null);
    const [height, setHeight] = useState(null);
    const [done, setDone] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [disabled1, setDisabled1] = useState(false);
    const dateRef = useRef();
    const parInfoRef = useRef();
    const teamleaderRef = useRef();
    const secondparamedRef = useRef();
    const carnumRef = useRef();
    const cartempRef = useRef();
    const patInfoRef = useRef();
    const patientNameRef = useRef();
    const medicalrecRef = useRef();
    const weightRef = useRef();
    const heightRef = useRef();
    const tripRef = useRef();

    const [onRoute, setOnRoute] = useState(null);
    const [route, setRoute] = useState(null);
    const [atPatient, setAtPatient] = useState(null);
    const [patient, setPatient] = useState(null);
    const [movingFromPT, setMovingFromPT] = useState(null);
    const [movingPT, setMovingPT] = useState(null);
    const [arrivedAtDest, setArrivedAtDest] = useState(null);
    const [aratdest, setAratdest] = useState(null);
    const [offLoad, setOffLoad] = useState(null);
    const [offL, setOffL] = useState(null);

    const PtRef = useRef();
    const [time, setTime] = useState(null);
    const rrRef = useRef();
    const hrRef = useRef();
    const spo2Ref = useRef();
    const bpRef = useRef();
    const gcsRef = useRef();
    const rbsRef = useRef();
    const tRef = useRef();

    const [time0, setTime0] = useState(null);
    const rr0Ref = useRef();
    const hr0Ref = useRef();
    const spo20Ref = useRef();
    const bp0Ref = useRef();
    const gcs0Ref = useRef();
    const rbs0Ref = useRef();
    const t0Ref = useRef();
    
    const [time1, setTime1] = useState(null);
    const rr1Ref = useRef();
    const hr1Ref = useRef();
    const spo21Ref = useRef();
    const bp1Ref = useRef();
    const gcs1Ref = useRef();
    const rbs1Ref = useRef();
    const t1Ref = useRef();
    const [tStuff, setTStuff] = useState(null);

    const Ifyes = useRef();
    const [ofy, setOfy] = useState(null);
    const lRef = useRef();
    const viaRef = useRef();
    const othercommentRef = useRef();


    const Ptransfer = useRef(); // move to
    const [pTRA,setPTRA] = useState(null);
    const fromRef = useRef();
    const toRef = useRef();
    const paramecidSignRef = useRef();
    const receiverRef = useRef();
    const recivingSignRef = useRef();
    const personalIdRef = useRef();


    const [cordered,setCordered] = useState(null);
    const [saved,setSaved] = useState(true);
    const timestamp =  moment(new Date()).format('MMMM Do YYYY, h:mm a'); // July 12th 2022, 3:52:42 am;;
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [links,setLinks] = useState(null);
    const [otherCC,setOtherCC] = useState(null);
    const [ptv,setPtv] = useState(null);
    const [loading,setLoading] = useState(false);
    const [loading1,setLoading1] = useState(false);
    const [aknowledged, setAknowledged] = useState(false);
    const [displayConfirmation1, setDisplayConfirmation1] = useState(false);
    const confirmationDialogFooter = (
        <>

        </>
    );

    const selectButtonValues1 = [
        { name: 'نعم', code: 'O1' },
        { name: 'لا', code: 'O2' },
    ];

    const confirmationDialogFooter1 = (
        <>
        <div className="w-100 text-center mt-">
            <Button type="button" label="لا" icon="pi pi-times" onClick={() => setDisplayConfirmation1(false)} className="p-button-text" />
            <Button type="button" label="نعم" icon="pi pi-check" onClick={() => {setDisplayConfirmation1(false); setAknowledged(true)} } className="p-button-text" autoFocus />
            </div>
        </>
    );
    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

        setCheckboxValue(selectedValue);
    };




console.log(selectButtonValue1)
    const customerService = new CustomerService();
    useEffect(() => {
        try {
             db.collection('orders').where('paramedic', 'array-contains', currentUser.uid).where('status', '==', 'قيد التنفيذ').onSnapshot((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc)
                  setSelectedAutoValue(doc.data().Firstparaname)
                  setSelectedAutoValue0(doc.data().Secondparaname)
                  add.push({ id: doc.id, ambulance: doc.data().ambulance, ...doc.data() })  
              })
              //console.log(add)
              setUs(add);
              });
               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'representative': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
            'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    const getCustomers = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            return d;
        });
    }
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }
    var i=-1;
    const urgbody = (rowData) => {
        console.log(i)
        i++
        if (i === us.length) {
            i=0;
        }
        return <span className={`customer-badge status-${us[i].urgent}`}>{us[i].urgent}</span>; 
    }
    var a=-1;
    const statusBodyTemplate = (rowData) => {
        a++
        if (a === us.length) {
            a=0;
        }

        if (us[i].status === 'تحت المراجعة') {
            return <span className={`customer-badge status-${'proposal'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'انتظار الدفع') {
            return <span className={`customer-badge status-${'negotiation'}`}>{us[i].status}</span>;
        } 
        if (us[i].status === 'قيد التنفيذ') {
            return <span className={`customer-badge status-${'new'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'تم بنجاح') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'ملغى') {
            return <span className={`customer-badge status-${'unqualified'}`}>{us[i].status}</span>;
        }
    }

    var b=-1;
    const SubscriberTemplate = (rowData) => {
        b++
        if (b === us.length) {
            b=0;
        }

        if (us[i].Subscriber === true) {
            return <MdVerified style={{ fontSize: '2rem' }}/>
        }
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    const balanceTemplate = (rowData) => {
        return (
            <span className="text-bold">
                {formatCurrency(rowData.balance)}
            </span>
        )
    }
    console.log(us)

    //console.log(dropdownValue)
    //console.log(radioValue)
    useEffect(() => {
        const countryService = new Paramedics();
        const nodeService = new NodeService();
        countryService.getCountries().then(data => setAutoValue(data));
        nodeService.getTreeNodes().then(data => setTreeSelectNodes(data));
    }, []);

    const rowExpansionTemplate = (data) => {

       

        const searchCountry = (event) => {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    setAutoFilteredValue([...autoValue]);
                }
                else {
                    setAutoFilteredValue(autoValue.filter((country) => {
                        return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            }, 250);
        };
        
        async function decline() {
    
          try { setDis(true)
    
            await db.collection('orders').doc(data.id).update({
                status: 'ملغى',
              })
              setSuc('!تم تحديث حالة الطلب بنجاح')
              setDis(false)
          } catch (error) {
              console.log(error)
              setDis(false)
          }
        }




        const onSelect = (file) => {
            
            for (let i = 0; i < file.files.length; i++) {

            if (file.files[i].size >= 1000000 ){
              return setNoattach('حجم المرفق يجب ان لا يكون اكبر من ١ ميقابايت')
            } else {
                setDone(true);
            const newImage = file.files[i];
            newImage["id"] = Math.random();
              setNoattach('')
              setImages((prevState) => [...prevState, newImage]);
            }
        }
          }
          
          const onClear = () => {
            //console.log('Cleared!!')
            setNoattach('')
          }

          const Container = styled.div`
          display: inline-block;
          padding: 3em;
          background: #C7D2FE66;
          border-radius: 10px;
          z-index: 1;
          position: relative;
          backdrop-filter: blur(10px);
          border: 2px solid transparent;
          background-clip: border-box;
          `;


          const onBeforeUpload = () => {
            const promises = [];
            images.map((image) => {
                var uploadTask = storageRef.child(`paramedicOrders/${data.id}/${image.id + image.name}`).put(image);
                promises.push(uploadTask);
                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                      //console.log('Upload is paused');
                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                   // alert("الرجاء الإنتظار لرفع المرفقات")
                    setDisplayConfirmation(true);
                    setDisabled1(true);
                    setLoading(true);
                      break;
                      default:
                      break;
                  }
                }, 
                (error) => {
                  console.log(error)
                }, 
                () => {
                  // Upload completed successfully, now we can get the download URL
                  //toast.current.show({ severity: 'success', summary: 'تم الرفع بنجاح', detail: 'سيتم مراجعة الموعد بعد لحظات', life: 4000 });
                  uploadTask.snapshot.ref.getDownloadURL().then((urls) => {
                    //console.log('File available at', urls);
                    setUrls((prevState) => [...prevState, urls]);
                    //console.log('Uploaded!!')
                    //db.collection('orders').doc(currentUser.uid).update({ attachment: urls})
                  });
                }
              );
              });

              Promise.all(promises)
              .then(() => {console.log(':P')})
              .catch((err) => console.log(err));
          }

        if (images.length === urls.length && done === true){
            db.collection('orders').doc(data.id).update({ ParaMedicAttach: urls})
            setLinks(urls);
            setDisplayConfirmation(false);
            setDisabled1(false);
            setLoading(false);
            setDone(false);
            setSaved(false);
            setCordered('تم حفظ التقرير، يمكنك الآن إتمام الطلب');
            console.log(links)
        }
          //console.log("images: ", images);
          //console.log("urls", urls);


/*            <Form.Group className="text-center mb-4" id="Order-urgent">
              <h5>اختيار المسعف</h5>

              <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)} options={para} optionLabel="name" placeholder="المسعفين" emptyMessage='لايوجد مسعفين مستعدين لقبول الطلب' />
              <div className="text-center mb-4">{err0 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err0}</small>}</div>
            </Form.Group>
            
            
            <button className="add" onClick={() => {

                }}>
              <img src="/plus.svg" alt="compass" />
              </button>
            
            */
        //<div> {data.locationfrom.map(loc => <div>{loc.lng}</div>)}</div>
              // https://maps.google.com/?q=7835 At Taif Rd, Alaziziyyah, Makkah 24243 2729, Saudi Arabia

              // DATE savior <div>{calendarValue?.toString().split(' ')[0]} {calendarValue?.toString().split(' ')[1]} {calendarValue?.toString().split(' ')[2]} {calendarValue?.toString().split(' ')[3]}</div>

              async function handleSubmitpar(e) {
                e.preventDefault() 
                var otherC;
                var PTVS;
                setDisabled(true);
                if (calendarValue == null) {
                    setDisabled(false);
                    window.scrollTo({ behavior: 'smooth', top: dateRef.current.offsetTop })
                    return setDate('الرجاء إدخال التاريخ')
                }
                let calendor = calendarValue?.toString().split(' ')[0]+'/'+calendarValue?.toString().split(' ')[1]+'/'+calendarValue?.toString().split(' ')[2]+'/'+calendarValue?.toString().split(' ')[3]
                if (selectedAutoValue == null) {
                    setDisabled(false);
                    setDate();
                    window.scrollTo({ behavior: 'smooth', top: parInfoRef.current.offsetTop })
                    return setTeamleader('يرجى إدخال قائد الفريق')
                }
                if (selectedAutoValue0 == null) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    window.scrollTo({ behavior: 'smooth', top: parInfoRef.current.offsetTop })
                    return setSecondparamedic('لا يمكن ترك هذه الخانة فارغة')
                }
                if (!carnumRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    window.scrollTo({ behavior: 'smooth', top: parInfoRef.current.offsetTop })
                    return setCarnumber('لا يمكن ترك هذه الخانة فارغة')
                }
                if (!cartempRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    window.scrollTo({ behavior: 'smooth', top: parInfoRef.current.offsetTop })
                    return setCartemp('لا يمكن ترك هذه الخانة فارغة')
                }
                if (!medicalrecRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    window.scrollTo({ behavior: 'smooth', top: patInfoRef.current.offsetTop })
                    return setMedicalrecord('لا يمكن ترك هذه الخانة فارغة')
                }
                if (!weightRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    window.scrollTo({ behavior: 'smooth', top: patInfoRef.current.offsetTop })
                    return setWeight('لا يمكن ترك هذه الخانة فارغة')
                }
                if (!heightRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    window.scrollTo({ behavior: 'smooth', top: patInfoRef.current.offsetTop })
                    return setHeight('لا يمكن ترك هذه الخانة فارغة')
                }
                if (onRoute == null){
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    window.scrollTo({ behavior: 'smooth', top: tripRef.current.offsetTop })
                    return setRoute('لا يمكن ترك هذه الخانة فارغة')
                }
                if (atPatient == null){
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    window.scrollTo({ behavior: 'smooth', top: tripRef.current.offsetTop })
                    return setPatient('لا يمكن ترك هذه الخانة فارغة')
                }
                if (movingFromPT == null){
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    setPatient();
                    window.scrollTo({ behavior: 'smooth', top: tripRef.current.offsetTop })
                    return setMovingPT('لا يمكن ترك هذه الخانة فارغة')
                }
                if (arrivedAtDest == null){
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    setPatient();
                    setMovingPT();
                    window.scrollTo({ behavior: 'smooth', top: tripRef.current.offsetTop })
                    return setAratdest('لا يمكن ترك هذه الخانة فارغة')
                }
                if (offLoad == null){
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    setPatient();
                    setMovingPT();
                    setAratdest();
                    window.scrollTo({ behavior: 'smooth', top: tripRef.current.offsetTop })
                    return setOffL('لا يمكن ترك هذه الخانة فارغة')
                }


                if (add === false) {
                    if (!rrRef.current.value || !rr0Ref.current.value || !hrRef.current.value || !hr0Ref.current.value || !spo2Ref.current.value || !spo20Ref.current.value || !bpRef.current.value || !bp0Ref.current.value || !gcsRef.current.value || !gcs0Ref.current.value || !rbsRef.current.value || !rbs0Ref.current.value || !tRef.current.value || !t0Ref.current.value) {
                    window.scrollTo({ behavior: 'smooth', top: PtRef.current.offsetTop })
                    return setTStuff('لا يمكن ترك هذه الخانات فارغة')
                    }  
                    PTVS = {Time:time, RR:rrRef.current.value, HR:hrRef.current.value, SPO2:spo2Ref.current.value, BP:bpRef.current.value, GCS:gcsRef.current.value, RBS:rbsRef.current.value, T:tRef.current.value, Time0:time0, RR0:rr0Ref.current.value, HR0:hr0Ref.current.value, SPO20:spo20Ref.current.value, BP0:bp0Ref.current.value, GCS0:gcs0Ref.current.value, RBS0:rbs0Ref.current.value, T0:t0Ref.current.value}
                    setPtv(PTVS);
                }
                if (add1 === false) {
                    if (!rrRef.current.value || !rr0Ref.current.value || !rr1Ref.current.value || !hrRef.current.value || !hr0Ref.current.value || !hr1Ref.current.value || !spo2Ref.current.value || !spo20Ref.current.value || !spo21Ref.current.value || !bpRef.current.value || !bp0Ref.current.value || !bp1Ref.current.value || !gcsRef.current.value || !gcs0Ref.current.value || !gcs1Ref.current.value || !rbsRef.current.value || !rbs0Ref.current.value || !rbs1Ref.current.value || !tRef.current.value || !t0Ref.current.value || !t1Ref.current.value) {
                        window.scrollTo({ behavior: 'smooth', top: PtRef.current.offsetTop })
                        return setTStuff('لا يمكن ترك هذه الخانات فارغة')
                        }
                        PTVS = {Time:time, RR:rrRef.current.value, HR:hrRef.current.value, SPO2:spo2Ref.current.value, BP:bpRef.current.value, GCS:gcsRef.current.value, RBS:rbsRef.current.value, T:tRef.current.value, Time0:time0, RR0:rr0Ref.current.value, HR0:hr0Ref.current.value, SPO20:spo20Ref.current.value, BP0:bp0Ref.current.value, GCS0:gcs0Ref.current.value, RBS0:rbs0Ref.current.value, T0:t0Ref.current.value, Time1:time1, RR1:rr1Ref.current.value, HR1:hr1Ref.current.value, SPO21:spo21Ref.current.value, BP1:bp1Ref.current.value, GCS1:gcs1Ref.current.value, RBS1:rbs1Ref.current.value, T1:t1Ref.current.value}
                        setPtv(PTVS);
                    }
                if (add && add1 === true) {
                    if (!rrRef.current.value || !hrRef.current.value || !spo2Ref.current.value || !bpRef.current.value || !gcsRef.current.value || !rbsRef.current.value || !tRef.current.value ) {
                        window.scrollTo({ behavior: 'smooth', top: PtRef.current.offsetTop })
                        return setTStuff('لا يمكن ترك هذه الخانات فارغة')
                    }
                     PTVS = {Time: time, RR: rrRef.current.value, HR: hrRef.current.value, SPO2: spo2Ref.current.value, BP: bpRef.current.value, GCS:gcsRef.current.value, RBS: rbsRef.current.value, T: tRef.current.value}
                     setPtv(PTVS);
                    }



                if (!lRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    setPatient();
                    setMovingPT();
                    setAratdest();
                    setOffL();
                    setTStuff();
                    window.scrollTo({ behavior: 'smooth', top: Ifyes.current.offsetTop })
                    return setOfy('لا يمكن ترك هذه الخانات فارغة')
                }

                if (!viaRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    setPatient();
                    setMovingPT();
                    setAratdest();
                    setOffL();
                    setTStuff();
                    window.scrollTo({ behavior: 'smooth', top: Ifyes.current.offsetTop })
                    return setOfy('لا يمكن ترك هذه الخانات فارغة')
                }

                if (!othercommentRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    setPatient();
                    setMovingPT();
                    setAratdest();
                    setOffL();
                    setTStuff();
                    setOfy();
                    otherC = null;
                } else { otherC = othercommentRef.current.value
                    setOtherCC(othercommentRef.current.value)}


                if (!paramecidSignRef.current.value || !receiverRef.current.value || !recivingSignRef.current.value || !personalIdRef.current.value) {
                    setDisabled(false);
                    setDate();
                    setTeamleader();
                    setSecondparamedic();
                    setCarnumber();
                    setCartemp();
                    setMedicalrecord();
                    setWeight();
                    setHeight();
                    setRoute();
                    setPatient();
                    setMovingPT();
                    setAratdest();
                    setOffL();
                    setTStuff();
                    setOfy();
                    window.scrollTo({ behavior: 'smooth', top: Ptransfer.current.offsetTop })
                    return setPTRA('لا يمكن ترك هذه الخانات فارغة')
                }

                if ((data.paymentMETHOD==="كاش" || data.paymentMETHOD==="شبكة") && aknowledged === false) {
                    setDisplayConfirmation1(true);
                    return setDisabled(false);
                    //console.log("CHANGED")
                }

                try { setDisabled(true);
                    onBeforeUpload();
                    setOfy();
                    await db.collection('ambulances').doc(data.ambdoc).update({
                        Availability: 'available',
                    }) 

                    await db.collection('orders').doc(data.id).update({
                        Calendar: calendor,
                        TeamLeader: selectedAutoValue,
                        SecondParamedic: selectedAutoValue0,
                        CarNum: carnumRef.current.value,
                        CarTemp: cartempRef.current.value,
                        CompletedAt: timestamp,
                        PatientName: patientNameRef.current.value,
                        MedicalRecord: medicalrecRef.current.value,
                        Weight: weightRef.current.value,
                        Height: heightRef.current.value,
                        OnRoute: onRoute,
                        AtPatient: atPatient,
                        MovingFromPt: movingFromPT,
                        ArrivedAt: arrivedAtDest,
                        OffLoad: offLoad,
                        PTVS: PTVS,
                        Bedridden: selectButtonValue1.name,
                        FoleyCatheter: selectButtonValue2.name,
                        CanPtSit: selectButtonValue3.name,
                        PegTube: selectButtonValue4.name,
                        NeedOx: selectButtonValue5.name,
                        NGtube: selectButtonValue6.name,
                        NeedSuction: selectButtonValue7.name,
                        UsedAmbBefore: selectButtonValue8.name,
                        OnGoingMeds: selectButtonValue9.name,
                        MaleSitter: selectButtonValue10.name,
                        Complicationsduringtransportation: selectButtonValue11.name,
                        L: lRef.current.value,
                        Via: viaRef.current.value,
                        Treatment: checkboxValue,
                        OtherComment: otherC,
                        ParamedicSign: paramecidSignRef.current.value,
                        Reviver: receiverRef.current.value,
                        ReceivingSign: recivingSignRef.current.value,
                        PersonaliD: personalIdRef.current.value,
                        finished: true,
                        status: 'تم بنجاح',
                        status_comment: "تم إتمام الطلب وإنهاء الطلب",

                      })
                      setCordered('تم حفظ التقرير بنجاح');
                } catch (err) {
                    console.log(err)
                }                
            
            }




            async function handleEnd(e) {
                e.preventDefault() 

                try { setDisabled1(true);
                      setLoading1(true);
                      setCordered();
                    setOfy();
                    await db.collection('archive').add({
                        displayName: data.displayName,
                        email: data.email,
                        userid: data.id,
                        phonenum: data.phonenum,
                        attachment: data.attachment,
                        from: data.from,
                        to: data.to,
                        HospitalMemo: data.HospitalMemo,
                        Hospital: data.Hospital,
                        locationfrom: data.locationfrom,
                        locationto: data.locationto,
                        whichway: data.whichway,
                        urgent: data.urgent,
                        waiting: data.waiting,
                        fromcity: data.fromcity,
                        simpleFrom: data.simpleFrom,
                        simpleTo: data.simpleTo,
                        tocity: data.tocity,
                        paramedic: data.paramedic,
                        ambulance: data.ambulance,
                        ParaMedicAttach: links,
                        status: 'تم بنجاح',
                        createdAt: data.createdAt,
                        CompletedAt: timestamp,
                        price:data.price,
                        inOut:data.inOut,
                        finished: true,
                        Calendar: data.Calendar,
                        TeamLeader: selectedAutoValue,
                        SecondParamedic: selectedAutoValue0,
                        CarNum: carnumRef.current.value,
                        CarTemp: cartempRef.current.value,
                        PatientName: patientNameRef.current.value,
                        MedicalRecord: medicalrecRef.current.value,
                        Weight: weightRef.current.value,
                        Height: heightRef.current.value,
                        OnRoute: onRoute,
                        AtPatient: atPatient,
                        MovingFromPt: movingFromPT,
                        ArrivedAt: arrivedAtDest,
                        OffLoad: offLoad,
                        PTVS: ptv,
                        Bedridden: selectButtonValue1.name,
                        FoleyCatheter: selectButtonValue2.name,
                        CanPtSit: selectButtonValue3.name,
                        PegTube: selectButtonValue4.name,
                        NeedOx: selectButtonValue5.name,
                        NGtube: selectButtonValue6.name,
                        NeedSuction: selectButtonValue7.name,
                        UsedAmbBefore: selectButtonValue8.name,
                        OnGoingMeds: selectButtonValue9.name,
                        MaleSitter: selectButtonValue10.name,
                        Complicationsduringtransportation: selectButtonValue11.name,
                        L: lRef.current.value,
                        Via: viaRef.current.value,
                        Treatment: checkboxValue,
                        OtherComment: otherCC,
                        ParamedicSign: paramecidSignRef.current.value,
                        Reviver: receiverRef.current.value,
                        ReceivingSign: recivingSignRef.current.value,
                        PersonaliD: personalIdRef.current.value,
                        status_comment: "تم إتمام الطلب وإنهاء الطلب",

                      })
                    setCordered('!لقد تم إتمام هذا الطلب بنجاح');
                    setLoading1(false);
                    setSaved(false);
                    db.collection("orders").doc(data.id).delete().then(() => {
                        console.log("تم حذف الملف من الطلبات وحفظه في الأرشيف");
                    }).catch((error) => {
                        console.error("Error removing document: ", error);
                    });
                } catch (err) {
                    console.log(err)
                }                
            
            }








        return (
        
            <div className="w-100 text-center mt-">
                <Dialog header="تأكيد الدفع قبل قبول الطلب" visible={displayConfirmation1} onHide={() => setDisplayConfirmation1(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter1}>
          <div className="flex align-items-center justify-content-center mb-3">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            </div>
        <div align="right">
            ،لم يتم دفع المبلغ من قِبل العميل
        </div>
        <div className="mt-3 mb-2" align="right">
        بنقرك على "حفظ التقرير" تعي ان الطلب غير مدفوع ويجب إتمام عملية الدفع بشكل مباشر مع العميل
        </div>
        <div align="right">
        لذلك يُرجى التأكد من إتمام العملية مع العميل اولاً
        </div>
        <div className="mt-3" align="right">
            هل تم إتمام عملية الدفع؟
        </div>
        <div align="center">
            بالضغط على نعم سوف تستطيع حفظ وإنهاء الطلب
        </div>
            
            </Dialog>
                <h5 className="w-100 text-center mb-5 mt-">{data.Subscriber ? <MdVerified/> : null} طلب {data.displayName}<a rel="noopener noreferrer" href={data.attachment} target="_blank"><Button icon="pi pi-paperclip" className="p-button-rounded p-button-success p-button-text mr-2 mb-2" hidden={noattach}></Button></a></h5>
                <div className="w-100 text-center mb-5 mt-">{data.Subscriber ? <div>طلبات المشتركين قد لاتحتوي دائماً على روابط قوقل مابز صحيحة</div> : null}</div>
        <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{<Chip icon="pi pi-home" label={<a href={"https://www.google.com/maps/search/?api=1&query="+data.from} target="_blank" rel="noreferrer noopener">{data.from}</a>}></Chip>}</div>
            <div className="text-500 w-6 md:w-2 font-medium">موقع العميل</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap mb-5">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{<Chip icon="pi pi-map-marker" label={<a href={"https://maps.google.com/?q="+data.to} target="_blank" rel="noreferrer noopener">{data.to}</a>}></Chip>}</div>
            <div className="text-500 w-6 md:w-2 font-medium">وجهة العميل</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <Chip label={data.TimeFrom} className="mr-2"></Chip>
                <Chip label="->" className="mr-2"></Chip>
                <Chip label={data.TimeTo}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">موعد العميل</div>
        </li>
            <li  className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"><Chip label="معلومات إضافية"  icon="pi pi-info-circle" style={{ fontWeight: 'bold' }}></Chip></div>
            </li>
            <li className="flex align-items-center py-3 px-2 surface-border flex-wrap mt-3">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip id="inputnumber" label={data.userAGE+' years old'}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">العمر</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip id="inputnumber" label={data.details}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">الحالة المرضية وتفاصيل اخرى</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip id="inputnumber" label={data.userWEIGHT+' kg'}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">الوزن</div>
            </li>
            
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>

        </ul>
            <div>
                <div>
                <Tag className="mr-2 mb-2 " icon="pi pi-check" value={data.acceptedAt+" : تم قبول هذا الطلب في "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-user" severity="info" value={data.acceptedBy?.split(",")[0]+" : من قِبل "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
                <div>
                <Tag className="mr-2 mb-2 " icon="pi pi-check" value={data.ambulancePickedAt+" : تم إختيار السيارة في "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-car" severity="info" value={data.ambulancePickedBy?.split(",")[0]+" : من قِبل "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-credit-card" severity="success" value={" طريقة الدفع : "+data.paymentMETHOD} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-flag" value={" حالة الطلب المفصلة "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-spin pi-spinner" value={data.status_comment} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
            </div>
        

                <h5 className="rtl w-100 text-center mt-">تقرير نقل المريض</h5>
                <div ref={dateRef} className="card">
                <h5 className="rtl">التاريخ</h5>
                    <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>
                    <div className="text-center mb-4">{date && <small id="city-help" style={{fontSize:"small"}} className="p-error">{date}</small>}</div>
                </div>
                <div ref={parInfoRef} className="card p-fluid">
                    <h5 >معلومات المسعفين</h5>
                    <div className="formgrid grid">
                    <div className="field col">
                            <label htmlFor="email2">رقم السيارة</label>
                            <InputText ref={carnumRef} id="email2" type="text" defaultValue={data.ambulance} />
                            <div className="text-center mb-4">{carnumber && <small id="city-help" style={{fontSize:"small"}} className="p-error">{carnumber}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">درجة حرارة السيارة</label>
                            <InputText ref={cartempRef} id="email2" type="text" />
                            <div className="text-center mb-4">{cartemp && <small id="city-help" style={{fontSize:"small"}} className="p-error">{cartemp}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">المسعف الثاني</label>
                            <AutoComplete multiple={false} placeholder="البحث" id="dd" dropdown value={selectedAutoValue0} onChange={(e) => setSelectedAutoValue0(e.value)} suggestions={autoFilteredValue} completeMethod={searchCountry} field="name"  />
                            <div className="text-center mb-4">{secondparamedic && <small id="city-help" style={{fontSize:"small"}} className="p-error">{secondparamedic}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="name2">قائد الفريق</label>
                            <AutoComplete multiple={false} placeholder="البحث" id="dd" dropdown value={selectedAutoValue} onChange={(e) => setSelectedAutoValue(e.value)} suggestions={autoFilteredValue} completeMethod={searchCountry} field="name"  />
                            <div className="text-center mb-4">{teamleader && <small id="city-help" style={{fontSize:"small"}} className="p-error">{teamleader}</small>}</div>
                        </div>

                    </div>
                </div>

                <div ref={patInfoRef} className="card">
                <h5 class="rtl">معلومات المريض</h5>
                <div className="p-fluid formgrid grid rtl">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="firstname2">اسم المريض</label>
                            <InputText ref={patientNameRef} id="firstname2" type="text" defaultValue={data.displayName} />
                        </div>

                        <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">رقم السجل الطبي</label>
                            <InputText ref={medicalrecRef} id="name2" type="text" />
                            <div className="text-center mb-4">{medicalrecord && <small id="city-help" style={{fontSize:"small"}} className="p-error">{medicalrecord}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">الوزن</label>
                            <InputText ref={weightRef} id="email2" type="text" />
                            <div className="text-center mb-4">{weight && <small id="city-help" style={{fontSize:"small"}} className="p-error">{weight}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">الطول</label>
                            <InputText ref={heightRef} id="email2" type="text" />
                            <div className="text-center mb-4">{height && <small id="city-help" style={{fontSize:"small"}} className="p-error">{height}</small>}</div>
                        </div>

                    </div>
                    </div>
                    </div>

                    <div ref={tripRef} className="card">
                <h5 className="">Trip information</h5>
                <div className="p-fluid formgrid grid">

                        <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">On - Route</label>
                            <InputMask id="inputmask" mask="99:99" value={onRoute} onChange={(e) => setOnRoute(e.value)} />
                            <div className="text-center mb-4">{route && <small id="city-help" style={{fontSize:"small"}} className="p-error">{route}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">At Patient</label>
                            <InputMask id="inputmask" mask="99:99" value={atPatient} onChange={(e) => setAtPatient(e.value)} />
                            <div className="text-center mb-4">{patient && <small id="city-help" style={{fontSize:"small"}} className="p-error">{patient}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Moving From PT</label>
                            <InputMask id="inputmask" mask="99:99" value={movingFromPT} onChange={(e) => setMovingFromPT(e.value)} />
                            <div className="text-center mb-4">{movingPT && <small id="city-help" style={{fontSize:"small"}} className="p-error">{movingPT}</small>}</div>
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">Arrive at Destination</label>
                            <InputMask id="inputmask" mask="99:99" value={arrivedAtDest} onChange={(e) => setArrivedAtDest(e.value)} />
                            <div className="text-center mb-4">{aratdest && <small id="city-help" style={{fontSize:"small"}} className="p-error">{aratdest}</small>}</div>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Off-load</label>
                            <InputMask id="inputmask" mask="99:99" value={offLoad} onChange={(e) => setOffLoad(e.value)}/>
                            <div className="text-center mb-4">{offL && <small id="city-help" style={{fontSize:"small"}} className="p-error">{offL}</small>}</div>
                        </div>
                    </div>
                    </div>
                    </div>


                    <div ref={PtRef} className="card">
                <h5 className="">Pt V.S</h5>
                <div className="p-fluid formgrid grid">

                        <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="firstname2">TIME</label>
                            <InputMask id="inputmask" mask="99:99" value={time} onChange={(e) => setTime(e.value)}/>
                        </div>
                        <div className="field col">
                            <label htmlFor="name2">RR</label>
                            <InputText ref={rrRef} id="name2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">HR</label>
                            <InputText ref={hrRef} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Spo2</label>
                            <InputText ref={spo2Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">BP</label>
                            <InputText ref={bpRef} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">GCS</label>
                            <InputText ref={gcsRef} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">RBS</label>
                            <InputText ref={rbsRef} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">T</label>
                            <InputText ref={tRef} id="email2" type="text" />
                        </div>
                        <div className="field col">
                        <Button icon="pi pi-plus" className="p-button-rounded mr-2 mb-2" onClick={() => setAdd(false)}/>
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2 mb-2" onClick={() => {setAdd(true)
                        setAdd1(true)}}/>
                        </div>
                    </div>
                    </div>

                    <div className="p-fluid formgrid grid" hidden={add}>

                        <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="firstname2">TIME</label>
                            <InputMask id="inputmask" mask="99:99" value={time0} onChange={(e) => setTime0(e.value)}/>
                        </div>
                        <div className="field col">
                            <label htmlFor="name2">RR</label>
                            <InputText ref={rr0Ref} id="name2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">HR</label>
                            <InputText ref={hr0Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Spo2</label>
                            <InputText ref={spo20Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">BP</label>
                            <InputText ref={bp0Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">GCS</label>
                            <InputText ref={gcs0Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">RBS</label>
                            <InputText ref={rbs0Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">T</label>
                            <InputText ref={t0Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                        <Button icon="pi pi-plus" className="p-button-rounded mr-2 mb-2" onClick={() => setAdd1(false)}/>
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2 mb-2" onClick={() => setAdd1(true)}/>
                        </div>
                    </div>
                    </div>

                    <div className="p-fluid formgrid grid" hidden={add1}>

                        <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="firstname2">TIME</label>
                            <InputMask id="inputmask" mask="99:99" value={time1} onChange={(e) => setTime1(e.value)}/>
                        </div>
                        <div className="field col">
                            <label htmlFor="name2">RR</label>
                            <InputText ref={rr1Ref} id="name2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">HR</label>
                            <InputText ref={hr1Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Spo2</label>
                            <InputText ref={spo21Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">BP</label>
                            <InputText ref={bp1Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">GCS</label>
                            <InputText ref={gcs1Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">RBS</label>
                            <InputText ref={rbs1Ref} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">T</label>
                            <InputText ref={t1Ref} id="email2" type="text" />
                        </div>
                    </div>
                    </div>
                    <div className="text-center mb-4">{tStuff && <small id="city-help" style={{fontSize:"small"}} className="p-error">{tStuff}</small>}</div>
                    </div>

                    <div className="card p-fluid">
                    <h5 className="rtl">حالة المريض</h5>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">طريح الفراش</label>
                            <SelectButton value={selectButtonValue1} onChange={(e) => setSelectButtonValue1(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Foley catheter</label>
                            <SelectButton value={selectButtonValue2} onChange={(e) => setSelectButtonValue2(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">هل يمكن للمريض الجلوس على الكرسي</label>
                            <SelectButton value={selectButtonValue3} onChange={(e) => setSelectButtonValue3(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Peg tube</label>
                            <SelectButton value={selectButtonValue4} onChange={(e) => setSelectButtonValue4(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">بحاجة إلى أكسجين</label>
                            <SelectButton value={selectButtonValue5} onChange={(e) => setSelectButtonValue5(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">NG tube</label>
                            <SelectButton value={selectButtonValue6} onChange={(e) => setSelectButtonValue6(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">need suction</label>
                            <SelectButton value={selectButtonValue7} onChange={(e) => setSelectButtonValue7(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">استخدم سيارة الإسعاف من قبل</label>
                            <SelectButton value={selectButtonValue8} onChange={(e) => setSelectButtonValue8(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">Ongoing meds</label>
                            <SelectButton value={selectButtonValue9} onChange={(e) => setSelectButtonValue9(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Male sitter</label>
                            <SelectButton value={selectButtonValue10} onChange={(e) => setSelectButtonValue10(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">مضاعفات أثناء النقل؟</label>
                            <SelectButton value={selectButtonValue11} onChange={(e) => setSelectButtonValue11(e.value)} options={selectButtonValues1} optionLabel="name" />
                        </div>
                    </div>
                </div>

                <div ref={Ifyes} className="card p-fluid">
                <h5>If yes Interventions provided</h5>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2"> L </label>
                            <InputText ref={lRef} id="name2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2"> Via </label>
                            <InputText ref={viaRef} id="email2" type="text" />
                        </div>
                    </div>
                    <div className="text-center mb-4">{ofy && <small id="city-help" style={{fontSize:"small"}} className="p-error">{ofy}</small>}</div>
                    <div className="grid">
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption1" name="option" value="No Treatment" checked={checkboxValue.indexOf('No Treatment') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">No Treatment</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption2" name="option" value="o2" checked={checkboxValue.indexOf('o2') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption2">O2</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="IV Fluid" checked={checkboxValue.indexOf('IV Fluid') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">IV Fluid</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="CPR" checked={checkboxValue.indexOf('CPR') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">CPR</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="Nebulizing" checked={checkboxValue.indexOf('Nebulizing') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">Nebulizing</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="Suction" checked={checkboxValue.indexOf('Suction') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">Suction</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="IV Cannula" checked={checkboxValue.indexOf('IV Cannula') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">IV Cannula</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="Electrical Shock" checked={checkboxValue.indexOf('Electrical Shock') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">Electrical Shock</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="Airway device" checked={checkboxValue.indexOf('Airway device') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">Airway device</label>
                            </div>
                        </div>
                    </div>
                    <div className="field col">
                            <label htmlFor="name2">Other comment</label>
                            <InputText ref={othercommentRef} id="name2" type="text" />
                        </div>
                </div>

                <div ref={Ptransfer} className="card p-fluid">
                    <h5 >PT.is transferred</h5>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">From</label>
                            <InputText ref={fromRef} id="name2" type="text" defaultValue={data.simpleFrom}/>
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">To</label>
                            <InputText ref={toRef} id="email2" type="text" defaultValue={data.simpleTo} />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name2">Paramedic signature</label>
                            <InputText ref={paramecidSignRef} id="name2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Receiver</label>
                            <InputText ref={receiverRef} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Receiving signature</label>
                            <InputText ref={recivingSignRef} id="email2" type="text" />
                        </div>
                        <div className="field col">
                            <label htmlFor="email2">Personal ID</label>
                            <InputText ref={personalIdRef} id="email2" type="text" />
                        </div>
                    </div>
                    <div className="text-center mb-4">{pTRA && <small id="city-help" style={{fontSize:"small"}} className="p-error">{pTRA}</small>}</div>
                </div>
                <div className="card p-fluid">
                <FileUpload onSelect={onSelect} multiple onBeforeUpload={onBeforeUpload} onRemove={onClear} chooseLabel="إرفاق صور" uploadOptions={{className:"hidden"}} cancelOptions={{className:"hidden"}} name="demo[]"  accept="image/jpeg,image/png, application/pdf" maxFileSize={1000000} invalidFileSizeMessageDetail='حجم المرفق اكبر من المسموح به'/>
                </div>
                <div className="w-100 text-center mt-">
                <Button label="حفظ التقرير" className="mr-2 mb-2" onClick={handleSubmitpar} disabled={disabled} loading={loading}/>
                </div>
                <Dialog closable={false} header="الرجاء الإنتظار" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} modal footer={confirmationDialogFooter}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                <span>يتم الآن رفع جميع المرفقات</span>
                            </div>
                        </Dialog>
                <div className="w-100 text-center mt-">
                <Button label="إتمام و إنهاء الطلب" className="p-button-outlined p-button-success mr-2 mb-2" onClick={handleEnd} hidden={saved} disabled={disabled1} loading={loading1}/>
                <div className="text-center mb-4">{cordered && <small id="city-help" style={{fontSize:"small", color:"green"}} className="p-error">{cordered}</small>}</div>
                </div>
            </div>

        );
    }



  return (
    <>
<div className="col-12">
                <div className="card">
                <div className="w-100 text-center mt-">
                    <h5>الطلبات</h5>
                    </div>
                    <DataTable value={us}  loading={loading2} scrollDirection="both" className="mt-3" emptyMessage='لا يوجد طلبات'
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}responsiveLayout="scroll">
                        <Column expander style={{ width: '5em' }} />
                        <Column field="simpleFrom" header="المنزل" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="simpleTo" header="الوجهة" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="urgent" header="نوع الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={hid} body={urgbody} ></Column>
                        <Column field="whichway" header="نوع الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="status" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} body={statusBodyTemplate}></Column>
                        <Column field="waiting" header="إنتظار الطاقم" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="price" header="السعر" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={nod}></Column>
                        <Column field="createdAt" header="تاريخ الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={false}></Column>
                        <Column field="Subscriber" header="" style={{  flexGrow: 1, flexBasis: '160px' }} body={SubscriberTemplate} hidden={false}></Column>
                    </DataTable>
                </div>
                <div className="w-100 text-center mt-">
                <Button label="ادفع الآن" className="p-button-rounded mr-2 mb-2" hidden={nod} />
                </div>
            </div>


    

    </>
  )
}
