import React, {useState} from "react"
import { Route, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import {useSelector} from 'react-redux'
import Loading from '../pages/Loading';
import OrdersList from '../OrdersList';
import { db } from "../firebase"


export default function UserRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  //const location = useLocation()
  const [roles, setRoles] = useState()
  const history = useHistory()
  const user = useSelector ((state) => state.user.value )
  const order = useSelector ((state) => state.order.value )

  return (
    <Route
      {...rest}
      render={props => {
        if (currentUser){ db.collection('users').doc(currentUser.uid).get().then(doc => {
          if(!doc.exists) {return history.push("/PhoneSignUp")}
                                        })
          
          setRoles(user.role)
          
          if (roles) {
            if (roles.includes('user')) { 
              //console.log(order.Rate)
            if (order.ordered === false || order.Rated === true) {
              return  <Component {...props} /> 
            }
            if (order.ordered === true && order.status !=='ملغى' && order.Rated !== true){
              return <OrdersList/>
            }

            else {return  <Component {...props} />}
              }
          
          
            if (roles.includes('admin')){return history.push("/admin")} 
          
            else {return <Loading/>}
          }
        } else {return history.push("/phonelogin")}
        //return currentUser ? <Component {...props} /> : <Redirect to="/login" state={{from : location}} replace />
      }}
    ></Route>
  )
}
