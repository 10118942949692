import React, {useState} from "react"
//import { Button } from 'primereact/button';
import { useAuth } from "../contexts/AuthContext"
import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom"
import { Skeleton } from 'primereact/skeleton';



export default function Err() {

    const history = useHistory()
    const { currentUser } = useAuth()
    const user = useSelector ((state) => state.user.value )
    const [roles, setRoles] = useState()

    setTimeout(() => {if (currentUser){
        setRoles(user.role)
        if (roles) {
        if (roles.includes('admin')) { return  history.push("/admin") }else if (roles.includes('user')){return history.push("/")}
        if (roles.includes('dispatch')){ return history.push("/dispatch") }
        if (roles.includes('paramedic')){ return history.push("/paramedic") }
        if (roles.includes('manager')){ return history.push("/manager") }
        if (roles.includes('supervisor')){ return history.push("/supervisor") }
        } //else {return history.push("/PhoneSignUp")}
      } else {return history.push("/login")}}, 10)
    
//<div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
  return (
    <>
                    <div className="border-round border-1 surface-border p-4">
                    <Skeleton width="100%" height="350px"></Skeleton>
                    <div className="flex justify-content-between mt-3">
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                        </div>


    </>
  )
}
