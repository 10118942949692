import React from "react"
//import {motion} from "framer-motion"
//import { Button } from 'primereact/button';




export default function Err() {
   
  
    
  return (
    <>

<div className="wrapper">
  <div className="text_group">
    <p className="text_404">٤٠٤</p>
    <p className="text_lost">يبدو ان الصفحة التي تبحث عنها <br />فُقدت في الفضاء</p>
  </div>
  <div className="window_group">
    <div className="window_404">
      <div className="stars"></div>
    </div>
  </div>
</div>


    

    </>
  )
}
