import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Sidebar } from 'primereact/sidebar';
import { Column } from 'primereact/column';
import { Link, useHistory } from 'react-router-dom';
import { ProductService } from '../service/ProductService';
import { db } from "../firebase"
import { Tag } from 'primereact/tag';


const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: .4
        }
    ]
};


const Dashboard = (props) => {
    const layoutColorMode = ('light')
    const [users, setUsers] = useState();
    const [orders, setOrders] = useState();
    const [us, setUs] = useState([]);
    const [ambulances, setAmbulances] = useState();
    const [Busy, setBusy] = useState();
    const [archives, setArchives] = useState();
    const [revenue, setRevenue] = useState();
    const [revenue0, setRevenue0] = useState();
    const [ratings, setRatings] = useState([]);
    const history = useHistory()
    const [visibleFullScreen, setVisibleFullScreen] = useState(false);

    useEffect(() => {

        try {

            db.collection('users').onSnapshot(order => {
                var usar;
                
                usar = order.size // will return the collection size
                setUsers(usar);
              });
              let busy = [];
              let ambulance = [];
              db.collection('ambulances').onSnapshot(order => {
                order.docs.forEach((doc) => {
                    //console.log(doc)
                    if (doc.data().Availability === 'busy'){
                        busy.push(doc.data().Availability)  
                    }

                    if (doc.data().Availability === 'available'){
                        ambulance.push(doc.data().Availability) 
                    }
                    //console.log(doc.data().Availability)
                    //add.push(doc.data().price++)  
                })
                 // will return the collection size
                setAmbulances(ambulance.length);
                setBusy(busy.length);
                //console.log(busy)
              });


            db.collection('orders').onSnapshot(order => {
                var ordars;
                
                ordars = order.size // will return the collection size
                setOrders(ordars);
              });
              db.collection('archive').onSnapshot(snap => {
                var  archive;
                archive = snap.size // will return the collection size
                setArchives(archive);
              });

              db.collection('archive').where('status', 'not-in', ['انتظار الدفع', 'تحت المراجعة', 'ملغى']).onSnapshot((snapshot) => {
                //console.log(snapshot.docs)
                let add = [0]
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                // console.log(doc.data().price)
                 add.push(doc.data().price++)  
             })
             //console.log(add)
             var sum = add.reduce((x, y) => x + y);
             //console.log(sum)
             setRevenue(sum);
             });
             
             db.collection('orders').where('status', 'not-in', ['انتظار الدفع', 'تحت المراجعة', 'ملغى']).onSnapshot((snapshot) => {
                //console.log(snapshot.docs)
                let add = [0]
                let recent = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 //console.log(doc.data().price)
                 add.push(doc.data().price++)  
                 recent.push({ id: doc.id, ...doc.data() })
             })
             //console.log(add)
             var sum = add.reduce((x, y) => x + y);
             //console.log(sum)
             setRevenue0(sum);
             setUs(recent);
             });

             /////////////// ORDERS         REVIEWS ////////////
             let recent = []
             db.collection('orders').where('Rated', '==', 'true').onSnapshot((snapshot) => {
                //console.log(snapshot.docs)

             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 //console.log(doc.data().price)
                 recent.push({ id: doc.id, ...doc.data() })
             })
             //console.log(add)
             //console.log(sum)
             setRatings(recent);
             });

             ///////////// From Archive /////////////

             db.collection('archive').where('Rated', '==', 'true').onSnapshot((snapshot) => {
                //console.log(snapshot.docs)
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 //console.log(doc.data().price)
                 recent.push({ id: doc.id, ...doc.data() })
             })
             //console.log(add)
             //console.log(sum)
             setRatings(recent);
             });

          }
          
          catch (e) { 
              console.log(e)
          }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
//console.log(orders);
//console.log(archives);
    const [products, setProducts] = useState(null);
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [lineOptions, setLineOptions] = useState(null)

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsSmall().then(data => setProducts(data));
    }, []);

    useEffect(() => {
        if (layoutColorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    async function handlePara(e) {
        e.preventDefault() 

        try { 

        } catch (err) {
            console.log(err)
        }                
    
    }
    const data = [
        { text: 'a', description: 'b', width: '50%', color: 'orange' },
        { text: 'a', description: 'b', width: '16%', color: 'cyan' },
        { text: 'a', description: 'b', width: '67%', color: 'pink' },
        { text: 'a', description: 'b', width: '35%', color: 'green' },
        { text: 'a', description: 'b', width: '80%', color: 'purple' }
      ];

    return (




        <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0 rtl">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">الطلبات الإجمالية</span>
                            <div className="text-900 font-medium text-xl">{orders+archives}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-shopping-cart text-blue-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-500">الطلبات الحالية</span>
                    <span className="text-green-500 font-medium">{orders}  في الإنتظار/قيد التنفيذ </span>
                    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button label="المزيد" className="p-button-rounded mr-2 mb-2" onClick={()=>{history.push("/dispatch")}} />
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0 rtl">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">الدخل المدفوع</span>
                            <div className="text-900 font-medium text-xl"> {revenue+revenue0} ريال </div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-wallet text-orange-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">%52+ </span>
                    <span className="text-500">مقارنة بالإسبوع الماضي</span>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button label="المزيد" className="p-button-rounded mr-2 mb-2" />
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0 rtl">
                    <div className="flex justify-content-between mb-3">
                        <div>

                            <span className="block text-500 font-medium mb-3">المستخدمين المسجلين</span>
                            <div className="text-900 font-medium text-xl">{users}</div>
                            
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-user text-cyan-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-500">مسجلين حديثًا</span>
                    <span className="text-green-500 font-medium">2  </span>
                    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Sidebar visible={visibleFullScreen} onHide={() => setVisibleFullScreen(false)} baseZIndex={1000} fullScreen>
                            <h1 style={{ fontWeight: 'normal' }} className="rtl" >تحت الإنشاء</h1>
                        </Sidebar>
                    <Button label="المزيد" className="p-button-rounded mr-2 mb-2" onClick={() => setVisibleFullScreen(true)} />
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
            
            <div className="card mb-0 rtl">
                    <div className="flex justify-content-between mb-3">
                        <div>
                        
                            <span className="block text-500 font-medium mb-3">سيارات الإسعاف المتاحة</span>
                            <div className="text-900 font-medium text-xl">{ambulances}</div>
                            
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-car text-cyan-500 text-xl"/>
                        </div>
                    </div>
                
                    <span className="text-500">سيارات الإسعاف المشغولة</span>
                    <span className="p-error font-medium">{Busy}</span>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button label="المزيد" className="p-button-rounded mr-2 mb-2" onClick={()=>{history.push("/manager/ambview")}} />
                    </div>
                </div>
                
            </div>

            <div className="col-12 xl:col-6">
                <div className="card rtl">
                    <h5>المبيعات الحديثة</h5>
                    <DataTable value={us} rows={5} paginator responsiveLayout="scroll">
                        <Column header=" " body={(data) => <img className="shadow-2" src={`assets/demo/images/product/ambulance.png`} alt={data.image} width="50"/>}/>
                        <Column field="displayName" header="إسم العميل" sortable style={{width: '35%'}} className="text-center mt-" />
                        <Column field="price" header="السعر" sortable style={{width: '35%'}} className="text-center mt-" body={(data) => formatCurrency(data.price)}/>
                        <Column field="status" header="حالة الطلب" sortable style={{width: '35%'}} className="text-center mt-"/>
                        <Column header="العرض" style={{width:'15%'}} className="text-center mt-" body={() => (
                            <>
                                <Button icon="pi pi-search" type="button" className="p-button-text"/>
                            </>
                        )}/>
                    </DataTable>
                </div>
                <div className="card rtl">
                    <div className="flex justify-content-between align-items-center mb-5">
                        <h5>الأكثر مبيعاً</h5>
                        <div>
                            <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu1.current.toggle(event)}/>
                            <Menu ref={menu1} popup model={[{ label: 'Add New', icon: 'pi pi-fw pi-plus' }, { label: 'Remove', icon: 'pi pi-fw pi-minus' }]}/>
                        </div>
                    </div>
                <ul className="list-none p-0 m-0">
                {data.map((item) => (
                    <li key={item.text + item.description} className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                    <div>
                        <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{item.text}</span>
                        <div className="mt-1 text-600">{item.description}</div>
                    </div>
                    <div className="mt-2 md:mt-0 flex align-items-center">
                        <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{height: '8px'}}>
                        <div className={`bg-${item.color}-500 h-full`} style={{width: item.width}}/>
                        </div>
                        <span className={`text-${item.color}-500 ml-3 font-medium`}>{item.width}</span>
                    </div>
                    </li>
                ))}
                </ul>
  
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card rtl">
                    <h5>نظرة عامة على المبيعات</h5>
                    <Chart type="line" data={lineData} options={lineOptions} />
                </div>

                <div className="card rtl">
                    <div className="flex align-items-center justify-content-between mb-4">
                        <h5>الإشعارات</h5>
                        <div>
                            <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu2.current.toggle(event)}/>
                            <Menu ref={menu2} popup model={[{label: 'Add New', icon: 'pi pi-fw pi-plus'}, {label: 'Remove', icon: 'pi pi-fw pi-minus'}]}/>
                        </div>
                    </div>

                    <span className="block text-600 font-medium mb-3">اليوم</span>
                    <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                                <i className="pi pi-dollar text-xl text-blue-500"/>
                            </div>
                            <span className="text-900 line-height-3">تحت الإنشاء
						<span className="text-700"> تحت الإنشاء <span className="text-blue-500">79$</span></span>
					</span>
                        </li>
                        <li className="flex align-items-center py-2">
                            <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
                                <i className="pi pi-download text-xl text-orange-500"/>
                            </div>
                            <span className="text-700 line-height-3">Your request for withdrawal of <span className="text-blue-500 font-medium">2500$</span> has been initiated.</span>
                        </li>
                    </ul>

                    <span className="block text-600 font-medium mb-3">بالأمس</span>
                    <ul className="p-0 m-0 list-none">
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                                <i className="pi pi-dollar text-xl text-blue-500"/>
                            </div>
                            <span className="text-900 line-height-3">Keyser Wick
						<span className="text-700"> has purchased a black jacket for <span className="text-blue-500">59$</span></span>
					</span>
                        </li>
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
                                <i className="pi pi-question text-xl text-pink-500"/>
                            </div>
                            <span className="text-900 line-height-3">Jane Davis
						<span className="text-700"> has posted a new questions about your product.</span>
					</span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
