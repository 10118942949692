import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReactNotificationComponent = ({ title, body }) => {
  let hideNotif = title === "";

  if (!hideNotif) {
    toast.error(<Display />);
  }
 
  function Display() {
    const audio = new Audio('https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7');
    audio.play();
    return (    
      <div>
        <h4>{title}</h4> 
        <p>{body}</p> 
      </div>
    ); 
  }
 
  return (
    <ToastContainer
    position="top-right"
    autoClose={6000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={true}
    pauseOnFocusLoss
    draggable  
    pauseOnHover 
    icon = {<img src="https://emojipedia-us.s3.amazonaws.com/source/skype/289/ambulance_1f691.png" width="60" height="60"/>}
    theme="light"
    />
  );
};

ReactNotificationComponent.defaultProps = {
  title: "This is title",
  body: "Some body",
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ReactNotificationComponent;