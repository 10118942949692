import React from 'react';
import SearchLocationInput from '../SearchLocationInput';


const Emp = (props) => {



    

    return (

    <SearchLocationInput onChange={() => null} />

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Emp, comparisonFn);
