import React, {useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useAuth } from "./contexts/AuthContext"
import { db } from "./firebase"
import {useSelector,useDispatch} from 'react-redux'
import {login} from './features/user'
import {orders} from './features/orders'

export const AppTopbar = (props,{Theme}) => {
    const user = useSelector ((state) => state.user.value )
    const order = useSelector ((state) => state.order.value )
    const dispatch = useDispatch()
    const history = useHistory()
    const [noR, setNoR] = useState(true)
    useEffect(() => {
        if (user.role.includes('user') && user.role.length<=0 && currentUser){
            setNoR(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const { currentUser, logout } = useAuth()
    const [error, setError] = useState("")
    if(history.location.pathname ==='/login' || history.location.pathname ==='/phonelogin' || history.location.pathname ==='/forgot-password'
    || history.location.pathname ==='/signup' || history.location.pathname ==='/PhoneSignUp'){return null;}

    
    if (user.role.includes("") && currentUser){
        db.collection('users').doc(currentUser.uid).get().then(doc => {
        dispatch (login({name:doc.data().displayName,email:doc.data().email,role:doc.data().userRoles,phonenum:'',uid: currentUser.uid,}))
        if (doc.data().userRoles.includes('user')){
            setNoR(false);
        }
    })
    }



    if (order.ordered !== true && order.ordered !== false && currentUser){  
        const runorder = async () => {
            try {
              await db.collection('orders').doc(currentUser.uid).get().then(doc => {
                if (doc.exists){
                    db.collection('orders').doc(currentUser.uid).onSnapshot(doc => {
                    dispatch (orders({ordered: true, Rated: doc.data().Rated, from:doc.data().from,to:doc.data().to,status:doc.data().status,urgent:doc.data().urgent,whichway:doc.data().whichway,createdAt:doc.data().createdAt,price:doc.data().price,inOut:'',waiting:doc.data().waiting, tempid:doc.data().tempid,paymentmessage: doc.data().paymentmessage,showpay: doc.data().showpay, TimeFrom: doc.data().TimeFrom, TimeTo: doc.data().TimeTo, status_comment: doc.data().status_comment, paymentMETHOD: doc.data().paymentMETHOD,}))
                 });
                } else {
                 dispatch (orders({ordered: false,from:'',to:'',status:'',urgent:'',createdAt:'',showpay: false,}))
                }
                
            }); 
            }
            catch (e) {
              
              dispatch (orders({ordered: false,from:'',to:'',status:''}))
            }
          }
          runorder();  
    }
    

      
      async function handleLogout() {
        setError("")
    if (!currentUser) {return}
        try {
            localStorage.setItem('lastLoggedInUserName', ' مرحباً ' +user.name);
          await logout()
          dispatch (login({name:'',email:'',role:'',phonenum:'', uid: '',}))
          history.push("/phonelogin")
        } catch {
          setError("Failed to log out")
        }
      }

     


    return (
        <div className="layout-topbar" >
            
            <Link to='/' style={{ textDecoration: 'none' }} className="layout-topbar-logo" >
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/>
                <span>NOAAWIN</span> 
            </Link>

 

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                
                    <li>
                        <button hidden={noR} className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-history" onClick={()=>{history.push("/history")}}/>
                            <span onClick={()=>{history.push("/history")}}>History</span>
                        </button>
                    </li>
                    <li hidden={true}>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-moon" onClick={()=>{Theme()}}/>
                            <span onClick={()=>{Theme()}}>Theme</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-user" onClick={()=>{history.push("/profile")}}/>
                            <span onClick={()=>{history.push("/profile")}}>Profile</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-sign-out" onClick={handleLogout}/>
                            <span onClick={handleLogout}>Logout {error}</span>
                        </button>
                    </li>
                </ul>
        </div>
    );
}
