import React, { useRef, useState, useContext  } from "react"
import { Form } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Messages } from 'primereact/messages';
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
//import { Message } from 'primereact/message';



export default function PhoneSignIn() {
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const [flag, setFlag] = useState(false);
  const { setUpRecaptcha } = useAuth();
  const [emailerr, setEmailerr] = useState("")
  const [resend, setResend] = useState(true);
  //const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  //const [loggedin, setLoggedin] = useState('0')
  //const history = useHistory()
  const message = useRef();
  //const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const toast = useRef();
  //const { currentUser } = useAuth()
  //var lol;


 /*if (currentUser && lol === '1') {
    console.log (lol+' you are a user')
    //history.push('/');
  }*/


  async function handleSubmit(e) {
     e.preventDefault()
     //console.log(number);
     setEmailerr("");
        if (number && isValidPhoneNumber(number) ? 'true' : setEmailerr("الرجاء إدخال رقم هاتف صحيح"))
     
    try {
      setEmailerr('')
      setLoading(true)
      const response = await setUpRecaptcha(number);
      setResult(response);
      //console.log(response);
      setFlag(true);

    } catch(err) {
        console.log(err)
        if(err.code === "auth/too-many-requests"){
            setEmailerr('لقد حظرنا جميع الطلبات الواردة من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى في وقت لاحق.'); 
        } else {setEmailerr(err.message)}
    }
    setLoading(false)
  }


  const verifyOtp = async (e) => {
    e.preventDefault();
    setEmailerr("");
    if (otp === "" || otp === null) return setEmailerr('يرجى إدخال الرمز');

    setTimeout(() => {setResend(false)}, 10000)
    
    try {
        setLoading(true)
      await result.confirm(otp);
      //history.push("/404");
      toast.current.show({ severity: 'success', summary: 'الرمز المدخل صحيح', detail: 'سيتم تحويلك بعد لحظات', life: 4000 });
      //return console.log(result+' LOLL')
    } catch (err) {
        console.log(err) 
        setLoading(false)
        if(err.code === "auth/invalid-verification-code"){
            setEmailerr('الرمز المدخل غير صحيح'); 
        }
    }
  };

  const resendo = async (e) => {
    e.preventDefault();
    setEmailerr("");
    //if (otp === "" || otp === null) return setEmailerr('يرجى إدخال الرمز');

    setTimeout(() => {setResend(true)}, 10000)
    
    try {
      setEmailerr('')
      setLoading(true)
      const response = await setUpRecaptcha(number);
      setResult(response);
      
      //setFlag(true);

    } catch(err) {
        console.log(err)
        if(err.code === "auth/too-many-requests"){
            setEmailerr('لقد حظرنا جميع الطلبات الواردة من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى في وقت لاحق.'); 
        } else {setEmailerr(err.message)}
    }
    setLoading(false)
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
       <InputText
     ref={ref}
     {...props}
       />
    );
 });
 
        /*      <div className="w-100 text-center mt-" style={{ display: flag ? "block" : "none" }}>
          <Button label="إعادة ارسال الرمز" onClick={resendo} className="p-button-link mb-2 mr-2" disabled={resend}></Button>
          </div>*/

          const [password, setPassword] = useState('');
          const [checked, setChecked] = useState(false);
          const [userName, setUserName] = useState(localStorage.getItem('lastLoggedInUserName') || 'مرحباً');
          const containerClassName = classNames('surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden');

  return (
    <>
        <div >
            <div className="flex flex-column align-items-center justify-content-center">
            <img src={'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo" style={{ width: '150px', height: '150px' }} />
                <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5">
                            <img src={`/demo/images/login/user1.png`} alt="Image" height="80" className="mb-3" />
                            <div className="text-900 text-3xl font-medium mb-3">! {userName}</div>
                            <span className="text-600 font-medium">يُرجى إدخال رقم الهاتف للإستمرار</span>
                        </div>

                        <div>
                        <div> 
                          <Toast ref={toast} />
                            <div className="pl-8 pr-8 pb-5">
                            
                            <Messages ref={message} className="rtl" />
                              <div className="">
                                <Form onSubmit={handleSubmit} style={{ display: !flag ? "block" : "none" }}>
                                <div className="w-100 text-center mt-">  
                                <Form.Group id="email">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="SA"
                                    value={number}
                                    onChange={setNumber}
                                    placeholder="ادخل رقم الهاتف"
                                    inputComponent={CustomInput}
                                    autoFocus={true}
                                  //numberInputProps={{ className: "py-2 px-3 text-grey-darkest" }}
                                
                                  />
                                  
                                  <div>{emailerr && <small id="username-help" className="p-error" style={{fontSize:"small"}}>{emailerr}</small>}</div>
                                  <div className="w-100 text-center ml-">
                                  <div id="recaptcha-container" style={{textAlign: 'center', display: 'inline-block'}} className="mt-4" ></div>
                                  </div>
                                    </Form.Group>
                                    </div>  

                                  <div className="w-100 text-center mt-">
                                  <button disabled={loading} className="p-button p-component p-button-raised mr-2 mb-2" type="submit">
                                    تسجيل الدخول
                                  </button>
                                  </div>
                                </Form>


                                <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
                                <Form.Group className="mb-3" controlId="formBasicOtp">
                                  <Form.Control
                                    type="otp"
                                    placeholder="يرجى إدخال الرمز المرسل لرقم الهاتف"
                                    onChange={(e) => setOtp(e.target.value)}
                                  />
                                  <div className="w-100 text-center mb-3">
                                <div>{emailerr && <small id="username-help" className="p-error text-center" style={{fontSize:"small"}}>{emailerr}</small>}</div>
                                </div>
                                </Form.Group>
                                <div className="w-100 text-center mt-">
                                  <button disabled={loading} type="submit" className="p-button p-component p-button-raised mr-2 mb-2">
                                    تفعيل
                                  </button>          
                                </div>
                              </Form>
                              </div>
                              <div className="w-100 text-center mt-8" style={{ color: 'grey', display: !flag ? "block" : "none" }}>
                              يمكنك التسجيل او تسجيل الدخول بإدخال رقم الهاتف في الأعلى
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}
