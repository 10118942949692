import React,{useState,useEffect, useRef} from "react"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomerService } from './service/CustomerService';
import {useSelector} from 'react-redux'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { db, functions } from "./firebase"
import {useAuth} from './contexts/AuthContext'
import { Dialog } from 'primereact/dialog';
import styled from "styled-components";
import { Rating } from 'primereact/rating';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chip } from 'primereact/chip';
import { Tag } from 'primereact/tag';
import orders from "./features/orders";


//import {motion} from "framer-motion"
//import { Button } from 'primereact/button';




export default function OrdersList() {
    const [customers2, setCustomers2] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [filters1, setFilters1] = useState(null);
    const user = useSelector ((state) => state.user.value )
    const order = useSelector ((state) => state.order.value )
    const [us, setUs] = useState([]);
    const [nod, setNod] = useState(true);
    const [difMethod, setDifMethod] = useState(false);
    const [paydis, setPaydis] = useState(false);
    const [hid, setHid] = useState(false);
    const { currentUser } = useAuth()
    const [displayBasic, setDisplayBasic] = useState(false);
    const [ratingValue, setRatingValue] = useState(null);
    const [ratingValue0, setRatingValue0] = useState(null);
    const [ratingValue1, setRatingValue1] = useState(null);
    const [notsatisfied, setNotsatisfied] = useState(true);
    const [ratingDisload, setRatingDisload] = useState(false);
    const [notmessage, setNotmessage] = useState(null);
    const notesRef = useRef();
    const [nonote, setNonote] = useState(null);
    const [hidUrg, setHidUrg] = useState(true);
    const [hidNonurg, sethidNonurg] = useState(true);
    const [hidStatus,setHidstatus] = useState(true);
    const [hidStatus1,setHidstatus1] = useState(true);
    const [hidStatus2,setHidstatus2] = useState(true);
    const [hidStatus3,setHidstatus3] = useState(true);
    const [hidStatus4,setHidstatus4] = useState(true);

    const basicDialogFooter = <Button type="button" label="تقديم" onClick={ async () => { 
        if (ratingValue+ratingValue0+ratingValue1 <= 12 && notsatisfied === true) {
            setNotmessage('نحن نعتذر منك. يبدو ان تجربتك معنا لم تكن مرضيه بالكافي، الرجاء كتابة الأسباب في خانة الملاحظات ')
            return setNotsatisfied(false);
        }
        if (notsatisfied === false && !notesRef.current.value) {
            return setNonote('لا يمكن ترك خانة الملاحظات فارغة')
        }

        try { setRatingDisload(true)
            const review = functions.httpsCallable('review');
            // call the function and pass data
            review({ uid:currentUser.uid, SpeedEfficiencyRating: ratingValue, ParaRating: ratingValue0, AllExperienceRating: ratingValue1, NotSatisfiedComment: notesRef.current.value, }).then(result => {
               console.log(result.data);
            }); 
              setDisplayBasic(false)
        }
        catch (e) {
            console.log(e);
        }

        }} icon="pi pi-check" className="p-button-success" disabled={ratingDisload} loading={ratingDisload} />;
        var paymant;
    const customerService = new CustomerService();

    useEffect(() => {
        setLoading2(true);
        setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        //console.log(order)
        if (order.price !== ''){
            setNod(false);
        }
        if (order.status === 'تم بنجاح'){
           // setOrdered(true);
           setDisplayBasic(true);
            setNod(true);
       }
       if ((order.status_comment !== '' && order.status === 'قيد التنفيذ') || order.status === 'تم بنجاح' || order.price === '' ){
        setNod(true);
        //console.log('We got him '+order.tempid);
       } else {setNod(false);}

        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'representative': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
            'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    }


    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    const getCustomers = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            return d;
        });
    }
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }
    
    const urgbody = (rowData) => {
        if (order.urgent === 'طارئ') {
            setHidUrg(false);
            sethidNonurg(true);
            return <span className={`customer-badge status-${'طارئ'}`}>{order.urgent}</span>;
        }
        if (order.urgent === 'غير طارئ') {
            sethidNonurg(false);
            setHidUrg(true);
            return <span className={`customer-badge status-${'new'}`}>{order.urgent}</span>;
        }  
    }



    async function handleSubmit(e) {
        e.preventDefault() 
        if (order.status !== 'انتظار الدفع'){return setNod(true);}
        setPaydis(true);
      try {
        window.Moyasar.init({
            // Required
            // Specify where to render the form
            // Can be a valid CSS selector and a reference to a DOM element
            element: '.mysr-form',
        
        
            language: 'ar', // Displaying the form in Arabic
        
            // Required
            // Amount in the smallest currency unit
            // For example:
            // 10 SAR = 10 * 100 Halalas
            // 10 KWD = 10 * 1000 Fils
            // 10 JPY = 10 JPY (Japanese Yen does not have fractions)
            amount: parseInt(order.price+'00'),
        
            // Required
            // Currency of the payment transation
            currency: 'SAR',
        
            // Required
            // A small description of the current payment process
            description: 'طلب خدمة نقل طبي من نعاون',
        
            // Required
            publishable_api_key: 'pk_live_zKVDvEoc6v761tWtdiYrxQx729hdk5uoHDKz7bDf',
        
            // Required
            // This URL is used to redirect the user when payment process has completed
            // Payment can be either a success or a failure, which you need to verify on you system (We will show this in a couple of lines)
            callback_url: 'https://us-central1-backend-noaawin-saas.cloudfunctions.net/payment?ud='+currentUser.uid,
        
            // Optional
            // Required payments methods
            // Default: ['creditcard', 'applepay', 'stcpay']
            methods: [
                'creditcard',
                'stcpay',
                'applepay',
            ],
            apple_pay: {

                country: 'SA',
    
                label: 'Noaawin for Medical Transportation',
    
                validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
    
            },
            
            /*on_completed: function (payment) {
                return new Promise(function (resolve, reject) {
                    // savePayment is just an example, your usage may vary.
                    if (payment.status === 'initiated'){
                     db.collection('orders').doc(currentUser.uid).update({
                            tempid: payment.id,
                          })
                    }
                    //console.log(payment.status);
                    paymant = payment;
                    resolve();
                    return console.log(paymant);
                    
                });
            },
            
        
          on_failure: function (error) {
            return new Promise(function (resolve, reject) {
                // savePayment is just an example, your usage may vary.
                paymant = error;
                return console.log(paymant);
            });
        },*/
        
          
        });

        /*await db.collection('orders').doc(currentUser.uid).update({
            status: 'قيد التنفيذ',
          })
          //console.log('!تم تحديث حالة الطلب بنجاح')*/



      } catch (error) {
          console.log(error)
      }
    }

    const statusBodyTemplate = (rowData) => {
        if (order.status === 'تحت المراجعة') {
            setHidstatus(false);
            setHidstatus1(true);
            setHidstatus2(true);
            setHidstatus3(true);
            setHidstatus4(true);
            return <span className={`customer-badge status-${'proposal'}`}>{order.status}</span>;
        }
        if (order.status === 'انتظار الدفع') {
            setHidstatus(true);
            setHidstatus1(false);
            setHidstatus2(true);
            setHidstatus3(true);
            setHidstatus4(true);
            return <span className={`customer-badge status-${'negotiation'}`}>{order.status}</span>;
        } 
        if (order.status === 'قيد التنفيذ') {
            setHidstatus(true);
            setHidstatus1(true);
            setHidstatus2(false);
            setHidstatus3(true);
            setHidstatus4(true);
            return <span className={`customer-badge status-${'new'}`}>{order.status}</span>;
        }
        if (order.status === 'تم بنجاح') {
            setHidstatus(true);
            setHidstatus1(true);
            setHidstatus2(true);
            setHidstatus3(false);
            setHidstatus4(true);
            return <span className={`customer-badge status-${'qualified'}`}>{order.status}</span>;
        }
        if (order.status === 'ملغى') {
            setHidstatus(true);
            setHidstatus1(true);
            setHidstatus2(true);
            setHidstatus3(true);
            setHidstatus4(false);
            return <span className={`customer-badge status-${'طارئ'}`}>{order.status}</span>;
        }   
    }
    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    const balanceTemplate = (rowData) => {
        return (
            <span className="text-bold">
                {formatCurrency(rowData.balance)}
            </span>
        )
    }

    //console.log(us)
  return (
    <>

<div className="col-12">
                <div hidden={true} className="card">
                <div className="w-100 text-center mt-">
                    <h5>الطلبات</h5>
                    </div>
                    <DataTable value={us} scrollable scrollHeight="400px" loading={loading2} scrollDirection="both" className="mt-3">
                        <Column field="from" header="المنزل" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="to" header="الوجهة" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="urgent" header="نوع الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={hid} body={urgbody} ></Column>
                        <Column field="whichway" header="نوع الرحلة" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="status" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} body={statusBodyTemplate}></Column>
                        <Column field="waiting" header="إنتظار الطاقم" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="price" header="السعر" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={nod}></Column>
                        <Column field="createdAt" header="تاريخ الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={false}></Column>
                    </DataTable>
                </div>




                <div className="w-100 text-center mt-">
                <div className="surface-section card">
    <div className="font-medium text-3xl text-900 mb-3">الطلبات</div>
    <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{order.from}</div>
            <div className="text-500 w-6 md:w-2 font-medium">المنزل</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{order.to}</div>
            <div className="text-500 w-6 md:w-2 font-medium">الوجهة</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div hidden={hidUrg} className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> <Tag severity="danger" value="طارئ" rounded></Tag> </div>
            <div hidden={hidNonurg} className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> <Tag className="mr-2" value= "غير طارئ" rounded></Tag> </div>
            <div className="text-500 w-6 md:w-2 font-medium">نوع الطلب</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{order.whichway}</div>
            <div className="text-500 w-6 md:w-2 font-medium">نوع الرحلة</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div hidden={hidStatus} className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> <Tag className="mr-2" icon="pi pi-spin pi-spinner" severity="warning" value="تحت المراجعة"></Tag> </div>
            <div hidden={hidStatus1} className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value="انتظار الدفع"></Tag> </div>
            <div hidden={hidStatus2} className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> <Tag className="mr-2" icon="pi pi-spin pi-spinner" value="قيد التنفيذ"></Tag> </div>
            <div hidden={hidStatus3} className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> <Tag className="mr-2" icon="pi pi-check" severity="success" value="تم بنجاح"></Tag> </div>
            <div hidden={hidStatus4} className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> <Tag icon="pi pi-times" severity="danger" value="ملغى"></Tag> </div>
            <div className="text-500 w-6 md:w-2 font-medium">حالة الطلب</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{order.paymentMETHOD
            ? order.paymentMETHOD
            : "( سوف يتم التواصل معك بخصوص طريقة الدفع المناسبة )"}
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">طريقة الدفع</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{order.waiting}</div>
            <div className="text-500 w-6 md:w-2 font-medium">إنتظار الطاقم</div>
        </li>
        <div hidden={nod} >
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div  className="text-500 w-6 md:w-2 font-medium"></div>
            <div  className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{order.price+' ريال'}</div>
            <div  className="text-500 w-6 md:w-2 font-medium">السعر</div>
        </li>
        </div>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{order.createdAt}</div>
            <div className="text-500 w-6 md:w-2 font-medium">تاريخ الطلب</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <Chip label={order.TimeFrom} className="mr-2"></Chip>
                <Chip label="->" className="mr-2"></Chip>
                <Chip label={order.TimeTo}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium">موعد الطلب</div>
        </li>
    </ul>
</div>
</div>
                <div className="w-100 text-center mt-">
                <Button label="ادفع الآن" className="p-button-rounded mr-2 mb-2" hidden={nod} disabled={paydis} onClick={handleSubmit} />
                </div>
                <div className="w-100 text-center mt-">
                <div hidden={nod}>{order.paymentmessage && <small id="username-help" className="p-error" style={{fontSize:"small"}}>{order.paymentmessage}</small>}</div>
                </div>

                <div className="mysr-form mt-4"></div>


                <Dialog closable={false} draggable={false} className="text-center mt-" header="تقييم الخدمة" visible={displayBasic} style={{ width: '30vw' }} modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
 
                            <div className="w-100 text-center mt-">
                            <h5>:رأيك في سرعة وفعالية الخدمة</h5>
                            <Rating cancel={false} value={ratingValue} onChange={(e) => setRatingValue(e.value)} />
                            <h5>:رأيك في المسعفين</h5>
                            <Rating cancel={false} value={ratingValue0} onChange={(e) => setRatingValue0(e.value)} />
                            <h5>:قيّم تجربتك الكاملة</h5>
                            <Rating cancel={false} value={ratingValue1} onChange={(e) => setRatingValue1(e.value)} />
                            </div>
                            <div>
                            <div className="text-center mb-4">{notmessage && <small id="order-help" style={{fontSize:"small"}} className="p-error">{notmessage}</small>}</div>
                            <InputTextarea ref={notesRef} className="rtl" hidden={notsatisfied} placeholder="تعليقك وملاحظاتك لتحسين تجاربك المستقبلية" autoResize rows="3" cols="30" />
                            <div className="text-center mb-4">{nonote && <small id="order-help" style={{fontSize:"small"}} className="p-error">{nonote}</small>}</div>
                            </div>
                        </Dialog>
            </div>


    

    </>
  )
}
