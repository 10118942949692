import React,{useState,useEffect,useRef} from "react"
import { ToggleButton } from 'primereact/togglebutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomerService } from '../service/CustomerService';
import {useSelector} from 'react-redux'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { db,getToken } from "../firebase"
import { Form } from "react-bootstrap"
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import {useAuth} from '../contexts/AuthContext'
import { TabMenu } from 'primereact/tabmenu';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import { Chip } from 'primereact/chip';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addTicket, getTickets } from "../tickets";
import styles from './index.module.scss';
import {InputNumber} from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import cash from "../icons/money.png";
import bank from "../icons/transfer.png";
import terminal from "../icons/terminal.png";
import credit from "../icons/card.png";
import paid from "../icons/paid.png";
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Slider } from 'primereact/slider';
import { ProgressBar } from 'primereact/progressbar';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';
import {MdVerified} from "react-icons/md";

//import {motion} from "framer-motion"
//import { Button } from 'primereact/button';




export default function Dispatch() {
    const [customers2, setCustomers2] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [filters1, setFilters1] = useState(null);
    const [value3, setValue3] = useState(null);
    const [value4, setValue4] = useState(null);
    const [value1, setValue1] = useState('');
    //const user = useSelector ((state) => state.user.value )
   // const order = useSelector ((state) => state.order.value )
    const [us, setUs] = useState([]);
    const [all, setAll] = useState([]);
    const [para, setPara] = useState(null);
    const [nod, setNod] = useState(true);
    const [par, setPar] = useState(true);
    const [hid, setHid] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [radioValue, setRadioValue] = useState(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [dis, setDis] = useState(false);
    const priceRef = useRef();
    const timestamp = moment(new Date()).format('MMMM Do YYYY, h:mm a'); // July 12th 2022, 3:52:42 am;
    const { currentUser } = useAuth()
    const user = useSelector ((state) => state.user.value )


    const [tickID, setTickID] = useState();
    const [limit, setLimit] = useState();
    const [showLimit, setShowLimit] = useState(true);
    const [err0,setErr0] = useState();
    const [err1,setErr1] = useState();
    const [err2,setErr2] = useState();
    const [errInf,setErrInf] = useState();
    const [errPay,setErrPay] = useState();
    const [errSub,setErrSub] = useState();
    const [suc,setSuc] = useState();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [displayConfirmation1, setDisplayConfirmation1] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const op = useRef(null);
    const [noattach,setNoattach] = useState(true);
    const [nomemo,setNomemo] = useState(true);
    const toast = useRef();
    const [canceled, setCanceled] = useState(false);
    const [priced, setPriced] = useState(false);
    const [meth0d, setMeth0d] = useState(false);
    const [changeP, setChangeP] = useState(true);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [switchValue, setSwitchValue] = useState(false);
    const [switchValue1, setSwitchValue1] = useState(false);
    const [switchValue2, setSwitchValue2] = useState(false);
    const [switchValue3, setSwitchValue3] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [orderStep, setOrderStep] = useState('انتظار الدفع');
    const [aknowledged, setAknowledged] = useState(false);
    const [newSubOrder, setNewSubOrder] = useState(true); 

    const [isTokenFound, setTokenFound] = useState(false);
    const [currentToken, setCurrentToken] = useState();

    const [customers1, setCustomers1] = useState(null);
    const [loading1, setLoading1] = useState(true);
    const [filters2, setFilters2] = useState(null);

    const representatives = [
        { name: "Amy Elsner", image: 'amyelsner.png' },
        { name: "Anna Fali", image: 'annafali.png' },
        { name: "Asiya Javayant", image: 'asiyajavayant.png' },
        { name: "Bernardo Dominic", image: 'bernardodominic.png' },
        { name: "Elwin Sharvill", image: 'elwinsharvill.png' },
        { name: "Ioni Bowcher", image: 'ionibowcher.png' },
        { name: "Ivan Magalhaes", image: 'ivanmagalhaes.png' },
        { name: "Onyama Limba", image: 'onyamalimba.png' },
        { name: "Stephen Shaw", image: 'stephenshaw.png' },
        { name: "XuXue Feng", image: 'xuxuefeng.png' }
    ];

    const statuses = [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
    ];

    const countryBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img alt="flag" src="assets/demo/images/flags/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} width={30} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{rowData.country.name}</span>
            </React.Fragment>
        );
    }

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className="p-button-success"></Button>
    }

    const representativeBodyTemplate = (rowData) => {
        const representative = rowData.representative;
        return (
            <React.Fragment>
                <img alt={representative.name} src={`images/avatar/${representative.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{ verticalAlign: 'middle' }} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{representative.name}</span>
            </React.Fragment>
        );
    }

    const representativeFilterTemplate = (options) => {
        return (<>
            <div className="mb-3 text-bold">Agent Picker</div>
            <MultiSelect value={options.value} options={representatives} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" placeholder="Any" className="p-column-filter" />
        </>
        )
    }

    const representativesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{option.name}</span>
            </div>
        );
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
    }

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.balance);
    }

    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const activityBodyTemplate = (rowData) => {
        return <ProgressBar value={rowData.activity} showValue={false} style={{ height: '.5rem' }}></ProgressBar>;
    }

    const activityFilterTemplate = (options) => {
        return (
            <React.Fragment>
                <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
                <div className="flex align-items-center justify-content-between px-2">
                    <span>{options.value ? options.value[0] : 0}</span>
                    <span>{options.value ? options.value[1] : 100}</span>
                </div>
            </React.Fragment>
        )
    }

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.verified, 'text-pink-500 pi-times-circle': !rowData.verified })}></i>;
    }

    const verifiedFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
    }

    ////////////////////////////////////////////////////////////////////

    const periods = [
        { label: "12 am - 4 am", value: "00:00" },
        { label: "4 am - 8 am", value: "04:00" },
        { label: "8 am - 12 pm", value: "08:00" },
        { label: "12 pm - 4 pm", value: "12:00" },
        { label: "4 pm - 8 pm", value: "16:00" },
        { label: "8 pm - 12 am", value: "20:00" },
    ];

  const [tickets, setTickets] = useState([]);
  const [refreshTickets, setRefreshTickets] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [bookFor, setBookFor] = useState("");
  const [selectedSlotSeats, setSelectedSlotSeats] = useState(0);

  const [excludeTimes, setExcludeTimes] = useState([]);

  const [readyForBooking, setReadyForBooking] = useState(false);

  useEffect(() => { 
    
    try {
         db.collection('tickets').onSnapshot((snapshot) => {
             //console.log(snapshot.docs)
             let temp = []
          snapshot.docs.forEach((doc) => {
              //console.log(doc)
              temp.push({ id: doc.id, ...doc.data() })  
          })
          //console.log(add)
          setTickets(temp);
          });
          setReadyForBooking(true);
      }
      catch (e) { 
          console.log(e)
      }
    
}, []); // eslint-disable-line react-hooks/exhaustive-deps



  useEffect(() => {
      const normalizeStartDate = startDate
          ? moment(startDate)
          : moment().startOf("day");

      const { seats } = countSeatsOfSlot(normalizeStartDate, tickets);
      setSelectedSlotSeats(seats);

      const newExcludeTimes = [];
      tickets.forEach((t) => {
          // disable time slots of only selected date
          if (moment(t.data.from.toDate()).isSame(normalizeStartDate, "day")) {
              // count number of seats booked for this one ticket
              const { seats } = countSeatsOfSlot(t.data.from.toDate(), tickets);
              // if this ticket has been booked for 5, disable it
              if (seats >= 5) {
                  newExcludeTimes.push(t.data.from.toDate());
              }
              if (seats>3 && limit) {
                newExcludeTimes.push(t.data.from.toDate());
                setShowLimit(false);
            }
          }
      });
      setExcludeTimes(newExcludeTimes);
  }, [startDate, tickets]);

  const onBook = async () => {
      setReadyForBooking(false);

  await addTicket({
    id: tickID,
          seats: Number(bookFor),
          from: moment(startDate).startOf("hour").toDate(),
          to: moment(startDate).startOf("hour").add(4, "hour").toDate(),
      });
      setBookFor("");
      setRefreshTickets(true);
  };

  const remainingSeats = 5 - selectedSlotSeats;

  const disableBookBtn = !readyForBooking || !startDate;

////////////////////////////////////////////////////////////////////



const countSeatsOfSlot = (startTime, tickets) => {
	const sameSlotBookings = tickets.filter(
		(t) =>
			moment(t.data.from.toDate()).isSame(startTime, "hour") &&
			moment(t.data.to.toDate()).isSame(
				moment(startTime).add(4, "hour"),
				"hour"
			)
	);

	let seats = 0;
	sameSlotBookings.forEach((b) => (seats = seats + b.data.seats));

	return { tickets: sameSlotBookings, seats: seats };
};

const [selectedButton, setSelectedButton] = useState(null);

const CustomTimeInput = ({ value, onChange, excludeTimes }) => {
	// for making it easy to check against our periods
	const reformatExcludeTimes = excludeTimes.map((t) =>
		moment(t).format("HH:mm")
	);
    
	return (
		<div
			style={{
				padding: "8px",
			}}
		>
			{periods.map((p) => (
				<Button
                className={
                    selectedButton === p.value
                        ? "ml-3 p-button-raised p-button-info mr-2 mb-2 selected-button"
                        : "p-button-raised mr-2 mb-2"
                }
                icon={
                  selectedButton === p.value
                      ? "pi pi-check selected-button"
                      : ""
              }
        type="button"
					style={{
						display: "block",
						width: "100%",
						padding: "6px 3px",
						marginBottom: 3,
						cursor: "pointer",
					}}
					disabled={reformatExcludeTimes.includes(p.value)}
					onClick={() => {onChange(p.value)
                        //console.log(selectedButton)
                        setSelectedButton(p.value)}}
				>
					{p.label}
				</Button>
			))}
		</div>
	);
};


      //console.log(currentToken)

      if (currentToken) {
        let Tokenn = currentToken
        setTimeout(() => {db.collection('Notifications').doc(currentUser.uid).set({
            notificationToken: Tokenn,
          })}, 7050)
      }
    const customerService = new CustomerService();
    useEffect(() => {
        getToken(setTokenFound,setCurrentToken);
        
        
        try {
             db.collection('orders').where('status', '==', 'تحت المراجعة').onSnapshot((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc)
                  add.push({ id: doc.id, ...doc.data() })  
              })
              //console.log(add)
              setUs(add);
              });

              db.collection('orders').onSnapshot((snapshot) => {
                //console.log(snapshot.docs)
                let aii = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 aii.push({ id: doc.id, ...doc.data() })  
             })
             //console.log(add)
             setAll(aii);
             });

               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'representative': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
            'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    const getCustomers = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            return d;
        });
    }
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }
    var i=-1;
    const urgbody = (rowData) => {
        //console.log(i)
        i++
        if (i === us.length) {
            i=0;
        }
        if (us[i].urgent === 'غير طارئ') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].urgent}</span>;
        }
        if (us[i].urgent === 'طارئ') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].urgent}</span>;
        }
        //return <span className={`customer-badge status-${us[i].urgent}`}>{us[i].urgent}</span>; 
    }
    var a=-1;
    const statusBodyTemplate = (rowData) => {
        a++
        if (a === us.length) {
            a=0;
        }

        if (us[i].status === 'تحت المراجعة') {
            return <span className={`customer-badge status-${'proposal'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'انتظار الدفع') {
            return <span className={`customer-badge status-${'negotiation'}`}>{us[i].status}</span>;
        } 
        if (us[i].status === 'قيد التنفيذ') { 
            return <span className={`customer-badge status-${'new'}`}>{us[i].status}</span>; 
        }
        if (us[i].status === 'تم بنجاح') {
            return <span className={`customer-badge status-${'qualified'}`}>{us[i].status}</span>;
        }
        if (us[i].status === 'ملغى') {
            return <span className={`customer-badge status-${'طارئ'}`}>{us[i].status}</span>;
        }
    }


    var b=-1;
    const SubscriberTemplate = (rowData) => {
        b++
        if (b === us.length) {
            b=0;
        }

        if (us[i].Subscriber === true) {
            return <MdVerified style={{ fontSize: '2rem' }}/>
        }
    }


    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    const balanceTemplate = (rowData) => {
        return (
            <span className="text-bold">
                {formatCurrency(rowData.balance)}
            </span>
        )
    }
    //console.log(us)

    if (para !== null) {
       // console.log(para)
        const dropdownValues = [
            { name: 'NewYork', code: 'NY' },
            { name: 'Rome', code: 'RM' },
            { name: 'London', code: 'LDN' },
            { name: 'Istanbul', code: 'IST' },
            { name: 'Paris', code: 'PRS' }
        ];
    }
    const toggle = (event) => {
        op.current.toggle(event);
    };

    //console.log(dropdownValue)
    //console.log(radioValue)



    const rowExpansionTemplate = (data) => {
        //setSuc();
        console.log("OPENED")
        /*setValue1('');
        setValue3(null);
        setValue4(null);
        setErr1('')
        setErr2('')
        setErrInf('')
        setErrPay('')*/
        if (expandedRows.length > 1) {
            setExpandedRows(null);
        }
        
        //console.log(expandedRows)
        if (data.attachment !== null) {
            setNoattach(false)
        } else {setNoattach(true)}

        if (data.HospitalMemo !== null) {
            setNomemo(false)
        } else {setNomemo(true)}

        if (data.status === 'ملغى') {
            setCanceled(true)
        } else {setCanceled(false)}


        if (data.status === 'قيد التنفيذ' || data.status === 'تم بنجاح') {
            setPar(false)
            setCanceled(true)
        } else {setPar(true)}

        if (data.price !== '' && changeP) {
            setPriced(true)
            //setCanceled(true)
        } else {setPriced(false)}

        if (data.paymentMETHOD==="كاش" || data.paymentMETHOD==="شبكة" || data.paymentMETHOD==="تحويل بنكي" || data.paymentMETHOD==="مدفوع مسبقاً") {
            setMeth0d(true)
            //setCanceled(true)
        } else {setMeth0d(false)}


        const confirmationDialogFooter = (
            <>
            <div className="w-100 text-center mt-">
                <Button type="button" label="لا" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
                <Button type="button" label="نعم" icon="pi pi-check" onClick={() => {setDisplayConfirmation(false); decline()} } className="p-button-text" autoFocus />
                </div>
            </>
        );
        const confirmationDialogFooter1 = (
            <>
            <div className="w-100 text-center mt-">
                <Button type="button" label="لا" icon="pi pi-times" onClick={() => setDisplayConfirmation1(false)} className="p-button-text" />
                <Button type="button" label="نعم" icon="pi pi-check" onClick={() => {setDisplayConfirmation1(false); setAknowledged(true)} } className="p-button-text" autoFocus />
                </div>
            </>
        );
        setTickID(data.id);
        if (data.whichway === 'ذهاب وإياب') {
            setLimit(true);
            setBookFor(2)
        } else {
            setLimit(false);
            setBookFor(1)
        }
        const basicDialogFooter =(
            <>
        
            <Button type="button" label="تعديل" onClick={()=>{
                if (data.whichway === 'ذهاب وإياب') {setBookFor(2)}
                else if (data.whichway === 'ذهاب فقط') {setBookFor(1)}
                if (remainingSeats<2 && limit) {
                    setShowLimit(false);
                    return toast.current.show({ severity: 'error', summary: 'لا يوجد رحلات متاحة للموعد المُحدد، يرجى تغيير توقيت الموعد والمحاولة مجدداً', life: 4000 })
                    //setErrSub('لا يوجد رحلات متاحة للموعد المُحدد، يرجى تغيير توقيت الموعد والمحاولة مجدداً')
                }
                onBook()
                toast.current.show({ severity: 'success', summary: 'تم تعديل الموعد بنجاح', life: 4000 })

                setTimeout(() => {setDisplayBasic(false);}, 1050)
                setTimeout(() => {db.collection('orders').doc(data.id).update({
                    TimeFrom: moment(startDate).startOf("hour").format('MMMM Do YYYY, h:mm a'),
                    TimeTo:   moment(startDate).startOf("hour").add(4, "hour").format('MMMM Do YYYY, h:mm a'),
                  })}, 3050)

                }} icon="pi pi-check" className="p-button-raised mr-2 mb-2" disabled={disableBookBtn} />

            <Button label="اختبار" hidden={true} icon="pi pi-pencil" className="p-button-text" onClick={() => setDisplayBasic(true)} />
            
            </>
        
        
        )


        async function decline() {
    
          try { setDis(true)
    
            await db.collection('orders').doc(data.id).update({
                status: 'ملغى',
              })

              await db.collection('archive').add({
                displayName: data.displayName,
                email: data.email,
                userid: data.id,
                phonenum: data.phonenum,
                attachment: data.attachment,
                from: data.from,
                to: data.to,
                HospitalMemo: data.HospitalMemo,
                Hospital: data.Hospital,
                locationfrom: data.locationfrom,
                locationto: data.locationto,
                whichway: data.whichway,
                urgent: data.urgent,
                waiting: data.waiting,
                fromcity: data.fromcity,
                simpleFrom: data.simpleFrom,
                simpleTo: data.simpleTo,
                tocity: data.tocity,
                paramedic: data.paramedic,
                status: 'ملغى',
                createdAt: data.createdAt,
                CanceledAt: timestamp,
                CanceledBy: currentUser.displayName+',ID '+currentUser.uid,
                price:data.price,
                inOut:data.inOut,
                finished: false,
              })
              setTimeout(function() {
                setSuc('!تم حذف الطلب بنجاح')
              }, 5000); // delay by 1 second (1000 milliseconds)
            await db.collection("orders").doc(data.id).delete().then(() => {
                console.log("تم حذف الملف من الطلبات وحفظه في الأرشيف");
                setValue1('');
                setValue3(null);
                setValue4(null);
                setErr1('')
                setErr2('')
                setErrInf('')
                setErrPay('')
                setPaymentMethod('')
                setSwitchValue(false)
                setSwitchValue1(false)
                setSwitchValue2(false)
                setSwitchValue3(false)
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
              setSuc('!تم حذف الطلب بنجاح')
              setDis(false)
          } catch (error) {
              console.log(error)
              setDis(false)
          }
        }



        async function handleSubmit(e) {
            e.preventDefault()
            setDis(true)
            var orderSTEP = 'انتظار الدفع';
            var statuMessage = '';
        if (value1==="" || value3 == null || value4 == null ) {
                setErr1('')
                setErr2('')
                setErrInf('يجب إتمام المعلومات الإضافية')
                //console.log('Info')
                return setDis(false);
        }
        if (paymentMethod==="") {
                setErr1('')
                setErr2('')
                setErrInf('')
                setErrPay('لا يمكن تجاهل طريقة الدفع')
                //console.log('Payment')
                return setDis(false);
        }
        if (!priceRef.current.value && par){
            setErrInf('')
            setErrPay('')
            setErr1('يرجى تحديد السعر')
            return setDis(false);
        }
        if (!parseInt(priceRef.current.value) && par) {
            setErrInf('')
            setErrPay('')
            setErr1('الرجاء إدخال السعر بالأرقام الإنجليزية')
            return setDis(false);
        }

        if (parseInt(priceRef.current.value) < 400 && par){
            setErrInf('')
            setErrPay('')
            setErr1('السعر لا يمكن ان يقل عن ٤٠٠ ريال')
            return setDis(false);
        }
        if (radioValue === null && par) {
            setErr1('')
            setErrInf('')
            setErrPay('')
            setErr2('يرجى تحديد نوع الرحلة')
           return setDis(false);
        }  

        if (paymentMethod === "تحويل بنكي" && aknowledged === false) {
            setErr1('')
            setErr2('')
            setErrInf('')
            setDisplayConfirmation1(true);
            //setErrPay('تحذير الحوالة المالية')
            return setDis(false);
        }
        if (paymentMethod==="كاش" || paymentMethod==="شبكة" || paymentMethod==="تحويل بنكي") {
            setOrderStep('قيد التنفيذ')
            orderSTEP = 'قيد التنفيذ';
            statuMessage = "في إنتظار إختيار سيارة الإسعاف المناسبة";
            //console.log("CHANGED")
        }
        
        
          try { setDis(true)

            let inout= radioValue;

            if (radioValue === 'Out'){
                 inout = 'خارجية'
            }
            if (radioValue === 'In'){
                  inout = 'داخلية'
            }
            if (data.status === 'قيد التنفيذ') {
 
            } else {

                async function updateOrder() {
                    setSuc('...يتم تحديث الطلب');
                    await new Promise((resolve) => setTimeout(resolve, 10050));
                    await db.collection('orders').doc(data.id).update({
                        status: orderSTEP,
                        acceptedBy: user.name+',ID '+currentUser.uid,
                        acceptedAt: timestamp,  
                        price:priceRef.current.value,
                        inOut:inout,
                        userAGE: value3,
                        details: value1,
                        userWEIGHT: value4,
                        status_comment: statuMessage,
                        paymentMETHOD: paymentMethod,
                    });
                    setSuc('!تم تحديث حالة الطلب بنجاح');
                    setDis(false)
                    setValue1('');
                    setValue3(null);
                    setValue4(null);
                    setErr1('')
                    setErr2('')
                    setErrInf('')
                    setErrPay('')
                    setPaymentMethod('')
                    setSwitchValue(false)
                    setSwitchValue1(false)
                    setSwitchValue2(false)
                    setSwitchValue3(false)
                    setRadioValue(null)
                    setTimeout(() => {setSuc('');}, 15050)
                  }
                  
                  updateOrder();
                
            }

          } catch (error) {
              console.log(error)
              setDis(false)
          }
        }



/*            <Form.Group className="text-center mb-4" id="Order-urgent">
              <h5>اختيار المسعف</h5>

              <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)} options={para} optionLabel="name" placeholder="المسعفين" emptyMessage='لايوجد مسعفين مستعدين لقبول الطلب' />
              <div className="text-center mb-4">{err0 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err0}</small>}</div>
            </Form.Group>
            
            
            <button className="add" onClick={() => {

                }}>
              <img src="/plus.svg" alt="compass" />
              </button>
            
            */
//console.log(value1 , value3 , value4)
        //console.log(paymentMethod)
        return (
            <div className="w-100 text-center mt-">
                <Toast ref={toast} />
                <div className="surface-section mb-7">
    <div className="font-medium text-3xl text-900 mb-3">{data.Subscriber ? <MdVerified/> : null} طلب {data.displayName} <a rel="noopener noreferrer" href={data.attachment} target="_blank"><Button icon="pi pi-paperclip" className="p-button-rounded p-button-success p-button-text mr-2 mb-2" hidden={noattach}></Button></a></div>
    <Dialog header="تعديل موعد العميل" visible={displayBasic} style={{ width: '35vw' }} modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
    <div className="text-center mb-4 w-full">
				<h5>موعد الطلب</h5>
                <p style={{ color:"red" }} hidden={showLimit}>
					تم حجب المواعيد الغير متاحة للذهاب والإياب
				</p>
				<DatePicker
					inline
					selected={startDate}
					onChange={(date) => setStartDate(date)}
					timeFormat="HH:mm"
					timeIntervals={240}
					excludeTimes={excludeTimes}
					showTimeInput
					customTimeInput={
						<CustomTimeInput excludeTimes={excludeTimes} />
					}
				/>
				<p
			style={{
			marginTop: "8px",
            color:"green"
					}}
				>
					الرحلات المتاحة للموعد المحدد: {remainingSeats}
				</p>

                
			</div>
                </Dialog>
                <Dialog header="تأكيد قبول الحوالة المالية" visible={displayConfirmation1} onHide={() => setDisplayConfirmation1(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter1}>
          <div className="flex align-items-center justify-content-center mb-3">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            </div>
        <div align="right">
            ،طريقة الدقع المُختارة هي حوالة مالية
        </div>
        <div className="mt-3 mb-2" align="right">
        بنقرك على "قبول وتقديم الطلب" تعي ان حالة الطلب سوف تكون مقبولة بدون الحاجة للدفع
        </div>
        <div align="right">
        لذلك يُرجى التأكد من إستلام الحوالة المالية من العميل اولاً
        </div>
        <div className="mt-3" align="right">
            هل تم إستلام الحوالة المالية؟
        </div>
        <div align="center">
            بالضغط على نعم سوف تستطيع تقديم الطلب
        </div>
            
            </Dialog>
    <Button onClick={() => {navigator.clipboard.writeText(data.phonenum).then(toast.current.show({ severity: 'success', summary: '!تم نسخ رقم العميل', detail: 'يمكنك الآن لصق الرقم', life: 4000 }))}} icon="pi pi-phone" className="p-button-rounded p-button-secondary p-button-text mr-2 mb-2" style={{ fontSize: '2rem' }}/>
    <a rel="noopener noreferrer" href={`https://wa.me/${data.phonenum}`} target="_blank"><Button icon="pi pi-whatsapp" className="p-button-rounded p-button-success p-button-text mr-2 mb-2" /></a>

    <ul className="list-none p-0 m-0">
    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.simpleFrom}</div>
            <div className="text-500 w-6 md:w-2 font-medium justify-content-end">موقع العميل</div>
        </li>
    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.simpleTo}</div>
            <div className="text-500 w-6 md:w-2 font-medium justify-content-end">وجهة العميل</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.email}</div>
            <div className="text-500 w-6 md:w-2 font-medium justify-content-end">إيميل العميل</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
        <div className="w-6 md:w-2 flex justify-content-end">
                <Button label="تعديل الموعد" icon="pi pi-pencil" className="p-button-text" onClick={() => setDisplayBasic(true)} />
            </div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <Chip label={data.TimeFrom} className="mr-2"></Chip>
                <Chip label="->" className="mr-2"></Chip>
                <Chip label={data.TimeTo}></Chip>
            </div>
            <div className="text-500 w-6 md:w-2 font-medium justify-content-end">موعد العميل</div>
        </li>
        <div hidden={!priced}>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
            <div hidden = {!par} className="w-6 md:w-2 flex justify-content-end">
                <Button label="تعديل السعر" icon="pi pi-pencil" className="p-button-text" onClick={() => setChangeP(false)} />
            </div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"><Chip label={data.price+' SAR'}></Chip></div>
            <div className="text-500 w-6 md:w-2 font-medium justify-content-end">سعر الرحلة</div>
            </li>
            </div>
            <div hidden={!par || canceled || priced} >
            <li  className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"><Chip label="معلومات إضافية"  icon="pi pi-info-circle" style={{ fontWeight: 'bold' }}></Chip></div>
            </li>
            <li className="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">
            <span className="p-float-label">
            <InputNumber id="inputnumber" value={value3} onValueChange={(e) => setValue3(e.target.value)}></InputNumber>
            <label htmlFor="inputnumber">العمر</label>
            </span>
            </div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 rtl">
            <span className="p-float-label">
            <InputText defaultValue={data.details} type="text" id="inputtext" value={value1} onChange={(e) => setValue1(e.target.value)}/>
            <label htmlFor="inputtext">الحالة المرضية وتفاصيل اخرى</label>
            </span>
            </div>
            <div className="w-6 md:w-2 flex justify-content-end">
            <span className="p-float-label">
            <InputNumber id="inputnumber" value={value4} onValueChange={(e) => setValue4(e.target.value)}></InputNumber>
            <label htmlFor="inputnumber">الوزن</label>
            </span>
            </div>
            </li>
            <div className="text-center mb-4">{errInf && <small id="city-help" style={{fontSize:"small"}} className="p-error">{errInf}</small>}</div>
            <li  className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"><Chip label="طريقة الدفع"  icon="pi pi-wallet" style={{ fontWeight: 'bold' }}></Chip></div>
            </li>
            <li  className="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"></div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <Chip label="كاش"  icon={<img src={cash} />} style={{ backgroundColor: '#85bb65', color: '#ebf4e5', fontWeight: 'bold' }}></Chip> <InputSwitch checked={switchValue} onChange={(e) => {setSwitchValue(e.value) 
                    setSwitchValue1(false)
                    setSwitchValue2(false)
                    setSwitchValue3(false)
                    setPaymentMethod('كاش')}} />
                <Chip className="ml-5" label="حوالة مالية"  icon={<img src={bank} />} style={{ backgroundColor: '#5695c0', color: '#ebf4e5', fontWeight: 'bold' }} ></Chip> <InputSwitch checked={switchValue1} onChange={(e) => {setSwitchValue1(e.value)
                    setSwitchValue(false)
                    setSwitchValue2(false)
                    setSwitchValue3(false)
                    setPaymentMethod('تحويل بنكي')}} />
                <Chip className="ml-5" label="شبكة"  icon={<img src={terminal} />} style={{ backgroundColor: '#394c54', color: '#ebf4e5', fontWeight: 'bold' }} ></Chip> <InputSwitch checked={switchValue2} onChange={(e) => {setSwitchValue2(e.value)
                    setSwitchValue(false)
                    setSwitchValue1(false)
                    setSwitchValue3(false)
                    setPaymentMethod('شبكة')}} />
                <Chip className="ml-5" label="دفع الكتروني عن طريق الموقع"  icon={<img src={credit} />} style={{ backgroundColor: '#931031', color: '#ebf4e5', fontWeight: 'bold' }} ></Chip> <InputSwitch checked={switchValue3} onChange={(e) => {setSwitchValue3(e.value)
                    setSwitchValue(false)
                    setSwitchValue1(false)
                    setSwitchValue2(false)
                    setPaymentMethod('دفع الكتروني بالبطاقة')}} />
                </div>
            </li>
            </div>
            <div className="text-center mb-4">{errPay && <small id="city-help" style={{fontSize:"small"}} className="p-error">{errPay}</small>}</div>
    </ul>
</div>
 
        <Form onSubmit={handleSubmit}>
          
            <Form.Group className="text-center mb-4 rtl" id="Order-urgent" hidden={!par || canceled || priced}>
            <InputText type="text" placeholder="سعر الرحلة" ref={priceRef} ></InputText> 
            <div className="text-center mb-4">{err1 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err1}</small>}</div>
            </Form.Group>
            <Form.Group hidden={!par || canceled || priced} className="text-center mb-4" style={{display: 'flex', justifyContent: 'center'}}>

                            <div className="field-radiobutton" style={{paddingRight:8}}>
                                <RadioButton inputId="option1" name="option" value="In" checked={radioValue === 'In'} onChange={(e) => setRadioValue(e.value)} />
                                <label htmlFor="option1">داخلية</label>
                            </div>
                            <div className="field-radiobutton">
                                <RadioButton inputId="option2" name="option" value="Out" checked={radioValue === 'Out'} onChange={(e) => setRadioValue(e.value)} />
                                <label htmlFor="option2">خارجية</label>
                            </div>
            </Form.Group>
            
            <div className="text-center mb-4">{err2 && <small id="city-help" style={{fontSize:"small"}} className="p-error">{err2}</small>}</div>

            <div className="text-center mb-4">{suc && <small id="order-help" style={{fontSize:"small", color:"green"}} className="p-error">{suc}</small>}</div>
             <Form.Group className="text-center mb-4" id="Order-urgent" hidden={nomemo || canceled}>
                 <h6><Chip label={data.Hospital?.split(",")[0]} icon="pi pi-map-marker" className="mr-2 mb-2" />:وجهة العميل</h6>
            <Panel className="rtl" header="ملاحظات العميل لقسم الطوارئ" toggleable collapsed>
                        <p>{data.HospitalMemo}</p>
                    </Panel>
            </Form.Group>

            <Button label="قبول وتحديث الطلب" className="mr-2 mb-2" type="submit" disabled={dis} loading={dis} hidden={canceled} />
          </Form>
          <Button label="رفض الطلب" className="p-button-danger p-button-text mr-2 mb-2" type="" disabled={dis} loading={dis} hidden={canceled} onClick={() => setDisplayConfirmation(true)} />
          <Dialog header="تأكيد رفض الطلب" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
          <div className="flex align-items-center justify-content-center">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>هل انت متأكد من رفض هذا الطلب؟</span>
            </div>
            </Dialog>
            <div hidden={par || !canceled}>
                <div>
                <Tag className="mr-2 mb-2 " icon="pi pi-check" value={data.acceptedAt+" : تم قبول هذا الطلب في "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-user" severity="info" value={data.acceptedBy?.split(",")[0]+" : من قِبل "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div hidden={!data.ambulancePickedAt}>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
                <div>
                <Tag className="mr-2 mb-2 " icon="pi pi-check" value={data.ambulancePickedAt+" : تم إختيار السيارة في "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-car" severity="info" value={data.ambulancePickedBy?.split(",")[0]+" : من قِبل "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-credit-card" severity="success" value={meth0d
            ? " طريقة الدفع : "+data.paymentMETHOD
            : data.paidAt+" : تمت عملية الدفع في "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"></li>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-flag" value={" حالة الطلب المفصلة "} style={{ fontSize: '1rem' }}></Tag>
                </div>
                <div>
                <Tag className="mr-2 mb-2" icon="pi pi-spin pi-spinner" value={data.status_comment} style={{ fontSize: '1rem' }}></Tag>
                </div>
            </div>
            </div>
        );
    }


    const SubExpansionTemplate = (data) => {

        
        const matchingObjects = all.filter(object => object.id.startsWith(data.PhoneNum));

        var q=-1;
        const statusBadyTemplate = (rowData) => {
            q++
            if (q === matchingObjects.length) {
                q=0;
            }
    
            if (matchingObjects[q].status === 'تحت المراجعة') {
                return <span className={`customer-badge status-${'proposal'}`}>{matchingObjects[q].status}</span>;
            }
            if (matchingObjects[q].status === 'انتظار الدفع') {
                return <span className={`customer-badge status-${'negotiation'}`}>{matchingObjects[q].status}</span>;
            } 
            if (matchingObjects[q].status === 'قيد التنفيذ') { 
                return <span className={`customer-badge status-${'new'}`}>{matchingObjects[q].status}</span>; 
            }
            if (matchingObjects[q].status === 'تم بنجاح') {
                return <span className={`customer-badge status-${'qualified'}`}>{matchingObjects[q].status}</span>;
            }
            if (matchingObjects[q].status === 'ملغى') {
                return <span className={`customer-badge status-${'طارئ'}`}>{matchingObjects[q].status}</span>;
            }
        }
        
        if (memberOrderStatus1 === 'ذهاب وإياب') {
            setLimit(true);
            setBookFor(2);
        } else {
            setLimit(false);
            setBookFor(1)
        }
        async function AddingSubOrder(e) {
            e.preventDefault()
            setAddingNewDis(true)
            const accept = () => {
                toast.current.show({ severity: 'info', summary: 'عذراً', detail: 'هذه الخاصية قيد التطوير', life: 3000 });
                };
                const pending = () => {
                    toast.current.show({ severity: 'info', summary: 'قيد التنفيذ', detail: 'يتم إضافة الطلب', life: 3000 });
                    };
                    const finished = () => {
                        toast.current.show({ severity: 'success', summary: 'تمت الإضافة', detail: 'تم إضافة الطلب بنجاح', life: 3000 });
                        };

    
            if (!currentsubAddress || !currentsubAddressDet || !currentsubDest || !currentsubPrice || !paymentMethod || !crewWaiting || !memberOrderStatus || !memberOrderStatus1) {
                setErrSub('يجب تعبئة جميع الخانات المهمة')
                return setAddingNewDis(false);
            }
            /*if (parseInt(currentsubPrice) < 400) {
                setErrSub('السعر لا يقل عن ٤٠٠ ريال')
                return setAddingNewDis(false);
            }*/
            if (remainingSeats<2 && limit) {
                setShowLimit(false);
                setErrSub('لا يوجد رحلات متاحة للموعد المُحدد، يرجى تغيير توقيت الموعد والمحاولة مجدداً')
                return setAddingNewDis(false);
            } else {
                setShowLimit(true);
            }
        
          try { setAddingNewDis(true)

            let TickFrom = moment(startDate).startOf("hour").format('MMMM Do YYYY, h:mm a');
            let TickTo = moment(startDate).startOf("hour").add(4, "hour").format('MMMM Do YYYY, h:mm a');
            setTickID(data.PhoneNum+`${Date.now()}`);
            if (memberOrderStatus1 === 'ذهاب وإياب') {setBookFor(2)}
            else if (memberOrderStatus1 === 'ذهاب فقط') {setBookFor(1)}
              onBook();

                async function AddingCurrentOrder() {
                    pending();
                    await new Promise((resolve) => setTimeout(resolve, 9000));
                    await db.collection('orders').doc(data.PhoneNum+`${Date.now()}`).set({
                        uid: data.PhoneNum,
                        displayName: data.Name,
                        email: data.Email,
                        userid: data.PhoneNum,
                        userAGE: data.Age,
                        details: data.Details,
                        Subscriber: true,
                        userWEIGHT: data.Weight,
                        phonenum: data.PhoneNum,
                        status: "قيد التنفيذ",
                        status_comment: "في إنتظار إختيار سيارة الإسعاف المناسبة",
                        tempid:'',
                        paymentmessage:'',
                        paymentMETHOD: paymentMethod,
                        agreement: '',
                        attachment: null,
                        from: currentsubAddress,
                        to: currentsubDest,
                        TimeFrom: TickFrom,
                        TimeTo: TickTo,
                        HospitalMemo: '',
                        Hospital: '',
                        locationfrom: 'HMMM',
                        locationto: 'HMMM',
                        whichway: memberOrderStatus1,
                        urgent: memberOrderStatus,
                        waiting: crewWaiting,
                        fromcity: '',
                        simpleFrom: currentsubAddressDet,
                        simpleTo: currentsubDest,
                        tocity: '',
                        paramedic: [''],
                        ParaMedicAttach:[],
                        price: currentsubPrice,
                        ambulance: '',
                        Firstparaname:'',
                        Secondparaname:'',
                        acceptedBy: user.name+',ID '+currentUser.uid,
                        acceptedAt: timestamp,  
                        createdBy: user.name+',ID '+currentUser.uid,
                        createdAt: timestamp,
                        inOut:'',
                    });
                    finished();
                    setAddingNewDis(false)
                    setErrSub()
                    setPaymentMethod('')
                    setSwitchValue(false)
                    setSwitchValue1(false)
                    setCurrentsubAddress('')
                    setCurrentsubDest('')
                    setCurrentsubAddressDet('')
                    setCurrentsubPrice('')
                    setMemberOrderStatus()
                    setMemberOrderStatus1()
                    setCrewWaiting()

                   // setTimeout(() => {setSuc('');}, 15050)
                  }
                  
                  AddingCurrentOrder();
                
            
    
          } catch (error) {
              console.log(error)
              setAddingNewDis(false)
          }
        }

        return (
            <div>
                <div hidden={true} className="text-center">
                <h5>Divider</h5>
                </div>
                <Toast ref={toast} />
                    <div className="grid">
                        <div className="col-5 flex align-items-center justify-content-center">
                        <div className="flex flex-column align-items-center justify-content-center">
                <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                    <div className="card w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5 pl-8 pr-8">
                            <img src={`/demo/images/login/user1.png`} alt="Image" height="80" className="mb-3" />

                            <div className="text-900 text-3xl font-medium mb-3"> إسم المشترك {data.Name}</div>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            </li>
                            <div className="mb-2">
                            <Chip label={data.Age} className="mr-7"></Chip> :العمر
                            </div>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"/>
                            <div className="mb-2">
                            <Chip label={data.Weight} className="mr-7"></Chip> :الوزن
                            </div>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"/>
                            <div className="mb-2">
                            <Chip label={data.PhoneNum} className="mr-7"></Chip> :رقم الجوال
                            </div>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"/>
                            <div className="mb-2">
                            <Chip label={data.Address} className="mr-7"></Chip> :العنوان
                            </div>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"/>
                            <div className="mb-2">
                            <Chip label={data.Destination} className="mr-7"></Chip> :الوجهة
                            </div>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"/>
                            <div className="mb-2">
                            <Chip label={data.Details} className="mr-7"></Chip> :حالة المريض وتفاصيل اخرى
                            </div>

                        </div>

                        <div>

                        </div>
                    </div>
                </div>
            </div>
                        </div>
                        <div className="col-1">
                            <Divider layout="vertical">
                                <b>المواعيد</b>
                            </Divider>
                        </div>
                        <div className="col-5 align-items-center justify-content-center">
                        <div className=" text-center text-900 text-3xl font-medium mb-3">المواعيد</div>

                            <Divider layout="horizontal" align="center">
                                <span className="p-tag">قيد التطوير</span>
                            </Divider>
                            <div className="col-12">
                <div className="card rtl">
                        <h5>المواعيد الحالية للمشترك</h5>
                        <DataTable value={matchingObjects} rowGroupMode="subheader" groupRowsBy="representative.name"
                        sortMode="single" sortField="representative.name" sortOrder={1} scrollable scrollHeight="400px"
                        rowGroupHeaderTemplate={''} rowGroupFooterTemplate={''} responsiveLayout="scroll" emptyMessage="لايوجد مواعيد">
                        <Column field="status" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px', minWidth: '200px' }} body={statusBadyTemplate}></Column>
                        <Column field="from" header="المنزل"  style={{ minWidth: '200px' }}></Column>
                        <Column field="to" header="الوجهة" style={{ minWidth: '200px' }}></Column>
                        <Column field="TimeFrom" header="تاريخ موعد الطلب" style={{ minWidth: '200px' }}></Column>
                        <Column field="createdAt" header="تاريخ إضافة الطلب" style={{ minWidth: '200px' }}></Column>
                    </DataTable>
                </div>
            </div>
                            <Divider align="right">
                            <Button onClick={() => setNewSubOrder(prevState => !prevState)} label="إضافة طلب جديد" icon="pi pi-plus" className="p-button-outlined"></Button>
                            </Divider>
                            <div hidden={newSubOrder} className="text-center">
				<h3>إضافة طلب جديد</h3>

                
                <div align="right" className="card p-fluid">
                    <div className="field rtl">
                        <label htmlFor="name1">العنوان الخاص بالعميل في الموعد الحالي</label>
                        <InputText placeholder="يُمكن إدخال رابط قوقل ماب إن وجد" id="name1" type="text" value={currentsubAddress} onChange={(e) => setCurrentsubAddress(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="age1">العنوان بالتفصيل في الموعد الحالي</label>
                        <InputText placeholder="المدينة، الحي، الشارع، رقم المبنى، الدور" id="age1" type="text" value={currentsubAddressDet} onChange={(e) => setCurrentsubAddressDet(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="email1">الوجهة الخاصة بالعميل في الموعد الحالي</label>
                        <InputText id="email1" type="text" value={currentsubDest} onChange={(e) => setCurrentsubDest(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="email1">سعر الموعد الحالي</label>
                        <InputText placeholder="سيتم دفع هذا المبلغ للمسعفين إذا كانت طريقة الدفع كاش" id="email1" type="text" value={currentsubPrice} onChange={(e) => setCurrentsubPrice(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="age1">حالة الطلب</label>
                        <div className="grid">
                        <div className="col-12 md:col-4">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option1" name="option" value="طارئ" checked={memberOrderStatus === 'طارئ'} onChange={(e) => setMemberOrderStatus(e.value)} />
                                <label htmlFor="option1">طارئ</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option2" name="option" value="غير طارئ" checked={memberOrderStatus === 'غير طارئ'} onChange={(e) => setMemberOrderStatus(e.value)} />
                                <label htmlFor="option2">غير طارئ</label>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="field">
                        <label htmlFor="age1">نوع الطلب</label>
                        <div className="grid">
                        <div className="col-12 md:col-4">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option1" name="option" value="ذهاب فقط" checked={memberOrderStatus1 === 'ذهاب فقط'} onChange={(e) => setMemberOrderStatus1(e.value)} />
                                <label htmlFor="option1">ذهاب فقط</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option2" name="option" value="ذهاب وإياب" checked={memberOrderStatus1 === 'ذهاب وإياب'} onChange={(e) => setMemberOrderStatus1(e.value)} />
                                <label htmlFor="option2">ذهاب وإياب</label>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="field">
                        <label htmlFor="age1">إنتظار الطاقم</label>
                        <div className="grid">
                        <div className="col-12 md:col-4">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option1" name="option" value="نعم" checked={crewWaiting === 'نعم'} onChange={(e) => setCrewWaiting(e.value)} />
                                <label htmlFor="option1">نعم</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option2" name="option" value="لا" checked={crewWaiting === 'لا'} onChange={(e) => setCrewWaiting(e.value)} />
                                <label htmlFor="option2">لا</label>
                            </div>
                        </div>
                    </div>
                    </div>
                    <label htmlFor="age1">طريقة الدفع</label>
                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 mb-4">
                <Chip label="كاش"  icon={<img src={cash} />} style={{ backgroundColor: '#85bb65', color: '#ebf4e5', fontWeight: 'bold' }}></Chip> <InputSwitch checked={switchValue} onChange={(e) => {setSwitchValue(e.value) 
                    setSwitchValue1(false)
                    setPaymentMethod('كاش')}} />
                <Chip className="ml-5" label="مدفوع مُسبقاً"  icon={<img src={paid} />} style={{ backgroundColor: '#5695c0', color: '#ebf4e5', fontWeight: 'bold' }} ></Chip> <InputSwitch checked={switchValue1} onChange={(e) => {setSwitchValue1(e.value)
                    setSwitchValue(false)

                    setPaymentMethod('مدفوع مسبقاً')}} />
                </div>
                    <div className="text-center mb-4 w-full">
                        <h5>موعد الطلب</h5>
                        <p style={{ color:"red" }} hidden={showLimit}>
                            تم حجب المواعيد الغير متاحة للذهاب والإياب
                        </p>
                        <DatePicker
                            inline
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            timeFormat="HH:mm"
                            timeIntervals={240}
                            excludeTimes={excludeTimes}
                            showTimeInput
                            customTimeInput={
                                <CustomTimeInput excludeTimes={excludeTimes} />
                            }
                        />
                        <p
                    style={{
                    marginTop: "8px",
                    color:"green"
                            }}
                        >
                            الرحلات المتاحة للموعد المحدد: {remainingSeats}
                        </p>

                        
                    </div>

                    <Button label="إضافة الموعد" icon="pi pi-plus" style={{width: 150}} disabled={addingNewDis} className="mr-2 mb-2"onClick={AddingSubOrder}></Button>
                </div>

                <div className="text-center mb-4">{errSub && <small id="city-help" style={{fontSize:"small"}} className="p-error">{errSub}</small>}</div>
    
			</div>

                        </div>
                    </div>
                </div>
        );
    }
    
    const [displayBasicNewMember, setDisplayBasicNewMember] = useState(false);
    const [newMemberAdd, setNewMemberAdd] = useState(false);
    const [memberOrderStatus, setMemberOrderStatus] = useState(null);
    const [memberOrderStatus1, setMemberOrderStatus1] = useState(null);
    const [crewWaiting, setCrewWaiting] = useState(null);
    const [addingNewDis, setAddingNewDis] = useState(false);
    const [subName, setSubName] = useState();
    const [subAge, setSubAge] = useState();
    const [subAddress, setSubAddress] = useState();
    const [subAddressDet, setSubAddressDet] = useState();
    const [subEmail, setSubEmail] = useState();
    const [subWeight, setSubWeight] = useState();
    const [subDest, setSubDest] = useState();
    const [subDetails, setSubDetails] = useState();
    const [subPhone, setSubPhone] = useState();
    const [subSince, setSubSince] = useState();
    const [subData, setSubData] = useState([]);
    const [subexpandedRows, setSubexpandedRows] = useState(null);
    const [currentsubAddress, setCurrentsubAddress] = useState();
    const [currentsubAddressDet, setCurrentsubAddressDet] = useState();
    const [currentsubDest, setCurrentsubDest] = useState();
    const [currentsubPrice, setCurrentsubPrice] = useState('');


    const wizardItems = [
        { label: 'الطلبات الجديدة', command: () => {  
            
            try { setLoading2(true);
            db.collection('orders').where('status', '==', 'تحت المراجعة').onSnapshot((snapshot) => {
                //console.log(snapshot.docs)
                let add = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 add.push({ id: doc.id, ...doc.data() })  
             })
             //console.log(add)
             setUs(add);
             });
             setLoading2(false);
         }
         catch (e) { 
             console.log(e)
             setLoading2(false);
         }
       
       //setUs([order])
       }},

        { label: 'جميع الطلبات', command: () => 
            {  try { setLoading2(true);
                 db.collection('orders').onSnapshot((snapshot) => {
                     //console.log(snapshot.docs)
                     let add = []
                  snapshot.docs.forEach((doc) => {
                      //console.log(doc)
                      add.push({ id: doc.id, ...doc.data() })  
                  })
                  //console.log(add)
                  setUs(add);
                  });
                  setLoading2(false);
              }
              catch (e) { 
                  console.log(e)
                  setLoading2(false);
              }
            
            //setUs([order])
            }
    },
        { label: 'الطلبات الملغية', command: () => 
        {  try { setLoading2(true);
            db.collection('archive').where('status', '==', 'ملغى').onSnapshot((snapshot) => {
                //console.log(snapshot.docs)
                let add = []
             snapshot.docs.forEach((doc) => {
                 //console.log(doc)
                 add.push({ id: doc.id, ...doc.data() })  
             })
             //console.log(add)
             setUs(add);
             });
             setLoading2(false);
         }
         catch (e) { 
             console.log(e)
             setLoading2(false);
         }
       
       //setUs([order])
       }
    
    },
    ];



    useEffect(() => {
       
        try {
             db.collection('Subscribers').onSnapshot((snapshot) => {
                 //console.log(snapshot.docs)
                 let add = []
              snapshot.docs.forEach((doc) => {
                  //console.log(doc)
                  add.push({ id: doc.id, ...doc.data() })  
              })
              //console.log(add)
              setSubData(add);
              });

               
          }
          catch (e) { 
              console.log(e)
          }
        setLoading2(true);
        //setUs([order])
        customerService.getCustomersLarge().then(data => { setCustomers2(getCustomers(data)); setLoading2(false); });
        initFilters1();
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    async function addingNewSub(e) {
        e.preventDefault()
        setNewMemberAdd(true)
        const accept = () => {
            toast.current.show({ severity: 'info', summary: 'عذراً', detail: 'هذه الخاصية قيد التطوير', life: 3000 });
            };
            const pending = () => {
                toast.current.show({ severity: 'info', summary: 'قيد التنفيذ', detail: 'يتم إضافة مشترك', life: 3000 });
                };
                const finished = () => {
                    toast.current.show({ severity: 'success', summary: 'تمت الإضافة', detail: 'تم إضافة المشترك بنجاح', life: 3000 });
                    };
            //accept();


            if (!subPhone || !subName || !subAge || !subWeight || !subAddress || !subAddressDet || !subDest || !subDetails) {
                setErrSub('يجب تعبئة جميع الخانات المهمة')
                return setNewMemberAdd(false);
            }
            if (!parseInt(subPhone) && par) {
                setErrInf('')
                setErrPay('')
                setErrSub('الرجاء إدخال رقم الهاتف بالأرقام الإنجليزية')
                return setNewMemberAdd(false);
            }
    
      try { setNewMemberAdd(true)
        setErrSub();

        async function CreateSub() {
            //setSuc('...يتم تحديث الطلب');
            pending();
            await new Promise((resolve) => setTimeout(resolve, 10050));
            await db.collection('Subscribers').doc(subPhone).set({
                Name: subName,
                Age: subAge,
                Weight: subWeight,  
                Email:subEmail,
                Details:subDetails,
                PhoneNum: subPhone,
                Address: subAddress,
                AddressDetails: subAddressDet,
                Destination: subDest,
                SubSince: subSince,
            });
           // setSuc('!تم تحديث حالة الطلب بنجاح');
           finished();
           setDisplayBasicNewMember(false);
           setNewMemberAdd(false);
           setSubName();
           setSubPhone();
           setSubWeight();
           setSubEmail();
           setSubDetails();
           setSubAddress();
           setSubAddressDet();

            setTimeout(() => {setSuc('');}, 15050)
          }
          
          CreateSub();

            
            } catch (error) {
          console.log(error)
          setNewMemberAdd(false)
      }
    }




    const NewMemberbasicDialogFooter =(
        <>
    
        <Button type="button" label="إضافة" onClick={addingNewSub} icon="pi pi-check" className="p-button-raised mr-2 mb-2" disabled={newMemberAdd} />

        <Button label="اختبار" hidden={true} icon="pi pi-pencil" className="p-button-text" onClick={() => setDisplayBasic(true)} />
        
        </>
    
    
    )

  return (
    <> 
    <div className="grid p-fluid">
    
    <div align="right" className="w-full">
                    <div className="w-100 text-center mt-">
                        <div hidden={!isTokenFound}>
                        <Chip hidden={!isTokenFound} label="👍🏻 الإشعارات مفعلة" icon="pi pi-bell" className="mb-2" removable />
                        </div>
                        <div hidden={isTokenFound}>
                        <Chip hidden={isTokenFound} label="❗️الإشعارات غير مفعلة" icon="pi pi-bell" className="mb-2" removable />
                        </div>
                        <div hidden={isTokenFound}>
                        <Chip hidden={isTokenFound} label="يرجى تفعيل إشعارات الصفحة من ثم تحديث الصفحة" icon="pi pi-bell" className="mb-2" removable />
                        </div>
                    </div>
                </div>
            <div className="col-12 md:col-12">
    

                <div className="card">
                <div className="w-100 text-center mt-">
                
                    <h5>الطلبات</h5>
                    </div>
                    <TabMenu className="rtl" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    <DataTable value={us}  loading={loading2} scrollDirection="both" className="mt-3" emptyMessage='لا يوجد طلبات'
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data) } rowExpansionTemplate={rowExpansionTemplate}responsiveLayout="scroll">
                        <Column expander style={{ width: '5em' }} />
                        <Column field="simpleFrom" header="المنزل" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="simpleTo" header="الوجهة" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="urgent" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={hid} body={urgbody} ></Column>
                        <Column field="whichway" header="نوع الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="status" header="حالة الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} body={statusBodyTemplate}></Column>
                        <Column field="waiting" header="إنتظار الطاقم" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column field="price" header="السعر" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={nod}></Column>
                        <Column field="createdAt" header="تاريخ الطلب" style={{ flexGrow: 1, flexBasis: '160px' }} hidden={false}></Column>
                        <Column field="Subscriber" header="" style={{  flexGrow: 1, flexBasis: '160px' }} body={SubscriberTemplate} hidden={false}></Column>
                    </DataTable>
                </div>
                <div className="card">
                <Toast ref={toast} />
                <Dialog visible={displayBasicNewMember} style={{ width: '35vw' }} modal footer={NewMemberbasicDialogFooter} onHide={() => setDisplayBasicNewMember(false)}>
            <div className="text-center">
				<h3>إضافة مشترك</h3>

                <div align="right" className="card p-fluid">
                    <div className="field rtl">
                        <label htmlFor="name1">الإسم</label>
                        <InputText id="name1" type="text" value={subName} onChange={(e) => setSubName(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="email1">الإيميل</label>
                        <InputText id="email1" type="text" value={subEmail} onChange={(e) => setSubEmail(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="age1">العمر</label>
                        <InputText id="age1" type="text" value={subAge} onChange={(e) => setSubAge(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="age1">الوزن</label>
                        <InputText id="age1" type="text" value={subWeight} onChange={(e) => setSubWeight(e.target.value)} />
                    </div>
                    <div className="field rtl">
                    <label htmlFor="age1">الحالة المرضية وتفاصيل اخرى</label>
                    <InputTextarea autoResize rows="3" cols="30" value={subDetails} onChange={(e) => setSubDetails(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="PhoneNumber">رقم الجوال</label>
                        <InputText placeholder="يرجى إدخال أرقام إنجليزية فقط" id="PhoneNumber" type="text" value={subPhone} onChange={(e) => setSubPhone(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="date">تاريخ إشتراك العميل</label>
                        <InputText id="PhoneNumber" type="date" value={subSince} onChange={(e) => setSubSince(e.target.value)} />
                    </div>
                </div>

                
                <div align="right" className="card p-fluid">
                    <div className="field rtl">
                        <label htmlFor="name1">العنوان الخاص بالعميل</label>
                        <InputText placeholder="يُمكن إدخال رابط قوقل ماب إن وجد" id="name1" type="text" value={subAddress} onChange={(e) => setSubAddress(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="age1">العنوان بالتفصيل</label>
                        <InputText placeholder="المدينة، الحي، الشارع، رقم المبنى، الدور" id="age1" type="text" value={subAddressDet} onChange={(e) => setSubAddressDet(e.target.value)} />
                    </div>
                    <div className="field rtl">
                        <label htmlFor="email1">الوجهة الخاصة بالعميل</label>
                        <InputText id="email1" type="text" value={subDest} onChange={(e) => setSubDest(e.target.value)} />
                    </div>
                </div>

                <div className="text-center mb-4">{errSub && <small id="city-help" style={{fontSize:"small"}} className="p-error">{errSub}</small>}</div>
                
			</div>
                </Dialog>


                <Panel header="المشتركين" toggleable>
                    <div align="right">
                    <Button label="إضافة مشترك جديد" icon="pi pi-plus-circle" style={{width: 150}} className="mr-2 mb-2"onClick={() => setDisplayBasicNewMember(true)}></Button>
                    </div>
                
                    <DataTable value={subData} paginator className="p-datatable-gridlines" showGridlines rows={10}
                        dataKey="id" filters={filters2} filterDisplay="menu" loading={loading2} responsiveLayout="scroll" emptyMessage="لا يوجد مشتركين"
                        expandedRows={subexpandedRows} onRowToggle={(e) => setSubexpandedRows(e.data) } rowExpansionTemplate={SubExpansionTemplate}>

                        <Column expander style={{ width: '3em' }} />
                        <Column header="تاريخ الإشتراك" filterField="date" dataType="date" style={{ minWidth: '10rem' }} field="SubSince"
                            filter filterElement={dateFilterTemplate} />
                        <Column header="العنوان" filterField="country.name" style={{ minWidth: '12rem' }} field="AddressDetails" filter filterPlaceholder="البحث بالمدينة"
                            filterClear={filterClearTemplate} filterApply={filterApplyTemplate} />
                        <Column header="العمر" filterField="representative" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }} field="Age" filter filterElement={representativeFilterTemplate} />
                        <Column field="Name" header="الإسم" filter filterPlaceholder="البحث بالإسم" style={{ minWidth: '12rem' }} />

                    </DataTable>
                    </Panel>
                </div>
                <div className="w-100 text-center mt-">
                <Button label="ادفع الآن" className="p-button-rounded mr-2 mb-2" hidden={nod} />
                </div>
            </div>
            <div hidden={true} className="col-12 md:col-5">
                <div className="card"> 
                <h1>MAP</h1>
                </div>
                </div>

            </div>


    

    </>
  )
}
