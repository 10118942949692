import {createSlice} from '@reduxjs/toolkit'

var initialStateValue = {ordered:'',from:'',to:'',status:'',urgent:'',whichway:'',createdAt:'',price:'',inOut:'',waiting:'',}


export const orderSlice = createSlice({
    name: 'order',
    initialState: {value:initialStateValue}, // Can Have More Data in future
    reducers: {
        orders: (state, action) => {
            state.value = action.payload
        },
    }
})

export const {orders} = orderSlice.actions

export default orderSlice.reducer