import React, {useEffect,useState,useRef} from "react";
import { Form } from "react-bootstrap"
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { ToggleButton } from 'primereact/togglebutton';
import {useAuth} from './contexts/AuthContext'
import {useSelector} from 'react-redux'
import firebase from "firebase/app"
import { db, functions } from "./firebase"
import {orders} from './features/orders'
import { useDispatch } from "react-redux"
import { FileUpload } from 'primereact/fileupload';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addTicket, getTickets } from "./tickets";
import { Calendar } from 'primereact/calendar';
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';


import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import mapStyles from "./mapStyles";
import { memoize } from "@fullcalendar/core";

const libraries = ["places"];
const mapContainerStyle = {
  height: "100vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 24.713066,
  lng: 46.674970,
};

//type DirectionsResult = window.google.maps.DirectionsResult;








export default function SearchLocationInput() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBgP9JBNoy3vjfSrw4OaeztwMMfN0cA3EQ',
    libraries,
  });
  //const DirectionsResult = window.google.maps.DirectionsResult;
  const [home, setHome] = React.useState([]);
  const [dest, setDest] = React.useState([]);
  const [convaddress, setConvaddress] = useState(null);
  const [convaddressGo, setConvaddressGo] = useState(null);
  const [locCity, setLocCity] = useState('');
  const [locDistract, setLocdistract] = useState('');
  const [locCity1, setLocCity1] = useState('');
  const [locDistract1, setLocdistract1] = useState('');
  const [counTry , setCounTry] = useState();
  const [directions, setDirections] = useState();

 /* const onMapClick = (e) => {
    panTo({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }*/

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(17);
  }, []);

  const goFrom = React.useCallback(({ lat, lng }) => {
    noway({
      location:{lat:lat,lng:lng}
    });
    setHome((current) => [
       //  ...current,
         {
           lat: lat,
           lng: lng,
         }
       ]);
  
  }, []);

  const noway = async ({location}) => {

    let city = "";
    let distract = "";
  
    try {
      const results = await getGeocode({location});
      setConvaddress (results[0]);
      //console.log(results[0])

      for (const component of results[0].address_components) {
        const componentType = component.types[0];

        switch (componentType) {

          case "locality":
            city = component.long_name;
            
            break;
          case "administrative_area_level_1": {
           
            break;
          }

          case "political": {
            distract = component.long_name;
            
            break;
          }

          case "country": {
            setCounTry (component.long_name)
            
            break;
          }
    
            //no default
        }
      } 
      
      //console.log(country+' Country ser '+ counTry)
      setLocCity (city)
      setLocdistract(distract)

    } catch (error) {
      console.log("😱 Error: ", error);
    }
    
  };
  const nowayGo = async ({location}) => {

    let city = "";
    let country = "";
    let distract ="";

    try {
      const results = await getGeocode({location});
      setConvaddressGo (results[0]);
      //console.log(results[0])

      for (const component of results[0].address_components) {
        const componentType = component.types[0];

        switch (componentType) {

          case "locality":
            city = component.long_name;
            
            break;
          case "administrative_area_level_1": {
           
            break;
          }

          case "political": {
            distract = component.long_name;
            
            break;
          }

          case "country": {
            country = component.long_name;
            setCounTry (component.long_name)
            
            break;
          }
    
            //no default
        }
      } 
      
      //console.log(country+' Country ser '+ counTry)
      setLocCity1 (city)
      setLocdistract1(distract)

    } catch (error) {
      console.log("😱 Error: ", error);
    }
    
  };

  const goTo = React.useCallback(({ lat, lng }) => {
    nowayGo({
      location:{lat:lat,lng:lng}
    });
    setDest((current) => [
       //  ...current,
         {
           lat: lat,
           lng: lng,
         },
       ]);
       
  }, []);

  const fetchDirections = ({lat, lng}) => {
    if (home.length === 0) return;
    let dst = [
      {
        lat: lat,
        lng: lng,
      }
    ]
    const service = new window.google.maps.DirectionsService();
    service.route(
      {
        origin: home[0],
        destination: dst[0],
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK" && result) {
          setDirections(result);
        }
      }
    );
  };

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div>
<div className="grid p-fluid">
<div className="col-12 md:col-6">

<div className="card">
      

      <Search panTo={panTo} goFrom={goFrom} goTo={goTo} fetchDirections={fetchDirections} 
      convaddress={convaddress} convaddressGo={convaddressGo} locCity={locCity} locDistract={locDistract}
       locCity1={locCity1} locDistract1= {locDistract1} home={home} dest={dest} />
      </div>
    </div>

    <div className="col-12 md:col-6">

                <div className="card">
                <Locate panTo={panTo} goFrom={goFrom} />
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={center}
        options={options}
        //onClick={onMapClick}
        onLoad={onMapLoad}
      >

          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  zIndex: 50,
                  strokeColor: "#1976D2",
                  strokeWeight: 5,
                },
                suppressMarkers: true,
              }}
            />
          )}

        {home.map((marker) => (
          <Marker
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
            draggable={true}
            onDragEnd={(coord) => {
              panTo({
                lat: coord.latLng.lat(),
                lng: coord.latLng.lng(),
              });
              goFrom({
                lat: coord.latLng.lat(),
                lng: coord.latLng.lng(),
              });
              noway({
                location:{lat:coord.latLng.lat(),lng:coord.latLng.lng()}
              });
              if(!dest.length === 0){fetchDirections();}
              //console.log(dest.length)
              
            }}
            animation={window.google.maps.Animation.DROP}
            
            icon={{
              url: `/masp-marker.svg`,
              origin: new window.google.maps.Point(0, 0),
              //anchor: new window.google.maps.Point(30, 30),
              scaledSize: new window.google.maps.Size(60, 60),
            }}
          />
        ))}

        {dest.map((marker) => (
          <Marker
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
            draggable={true}
            animation={window.google.maps.Animation.DROP}
            onDragEnd={(coord) => {
              panTo({
                lat: coord.latLng.lat(),
                lng: coord.latLng.lng(),
              });
              goTo({
                lat: coord.latLng.lat(),
                lng: coord.latLng.lng(),
              });
              nowayGo({
                location:{lat:coord.latLng.lat(),lng:coord.latLng.lng()}
              });
              
              fetchDirections({
                lat: coord.latLng.lat(),
                lng: coord.latLng.lng(),
              });

          
            }}

            icon={{
              url: `/nmm.png`,
              origin: new window.google.maps.Point(0, 0),
              //anchor: new window.google.maps.Point(30, 30),
              scaledSize: new window.google.maps.Size(40, 70),
            }}
          />
        ))}

      
      </GoogleMap>
      </div>
      </div>
    </div>

    </div>
  );
}

function Locate({ panTo, goFrom }) {

 /* if(convaddress !== null){
    return console.log(convaddress);
 }*/


  return (
    <button
      className="locate"
      onClick={() => {

        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            goFrom({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });

          },
          () => null
        );
        
      }}
    >
      <img src="/locateme.svg" alt="compass" />
    </button>
  );
  
}

function Search({ panTo, goTo, goFrom, fetchDirections, convaddress, convaddressGo, home, dest, locCity, locDistract,locCity1, locDistract1,}) {
  const user = useSelector ((state) => state.user.value )
  const { currentUser } = useAuth()
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 24.713066, lng: () => 46.674970 },
      radius: 100 * 1000,
    },
  });
  const dispatch = useDispatch()
  const [to, setTo] = useState();
  const [gofrom, setGofrom] = useState();
  const [goto, setGoto] = useState();
  const [plz, setPlz] = useState();
  const [from, setFrom] = useState();
  const [dis, setDis] = useState(true);
  const [subdis, setSubdis]= useState(true);
  const [hospital, setHospital] = useState(false);
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [country1, setCountry1] = useState();
  const [city1, setCity1] = useState();
  const [distract, setDistract] = useState();
  const [distract1, setDistract1] = useState();
  const [postcode, setPostcode] = useState();
  const [postcode1, setPostcode1] = useState();
  const [route, setRoute] = useState();
  const [route1, setRoute1] = useState();
  const [selectButtonValue1, setSelectButtonValue1] = useState(null);
  const [selectButtonValueurg, setSelectButtonValueurg] = useState(null);
  const [toggleValue, setToggleValue] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [urg, setUrg] = useState(null);
  const [wait, setWait] = useState(null);
  const [waitmes, setWaitmes] = useState(null);
  const [othercity, setOthercity] = useState();
  const [othercountry, setOthercountry] = useState();
  const [req, setReq] = useState();
  const [aproving, setAproving] = useState();
  const [hosifno, setHosinfo] = useState();
  const [ordered, setOrdered] = useState();
  const [nourg, setNourg] = useState();
  const [nourgg, setNourgg] = useState(true);
  const [wichh, setWichh] = useState(true);
  const [wichh1, setWichh1] = useState(true);
  const [seatmax, setSeatmax] = useState(true);
  const [wermemo, setWermemo] = useState();
  const [noattach, setNoattach] = useState();
  const [imageUpload, setImageUpload] = useState(null);
  const storageRef = firebase.storage().ref();
  const [disattach, setDisattach] = useState(false);
  const [progress, setProgress] = useState();
  const [appurl, setAppurl] = useState(null);
  const toast = useRef(null);
  const [cordered, setCordered] = useState();
  const [ugo, setUgo] = useState(true);
  const [cC, setCC] = useState(false);
  const timestamp =  moment(new Date()).format('MMMM Do YYYY, h:mm a'); // July 12th 2022, 3:52:42 am;
  const memo = useRef(null);
  const [hosp, setHosp] = useState(null);
  const [calendarValue, setCalendarValue] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [limit, setLimit] = useState();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest


////////////////////////////////////////////////////////////////////
  const [tickets, setTickets] = useState([]);
	const [refreshTickets, setRefreshTickets] = useState(true);

	const [startDate, setStartDate] = useState(null);
	const [bookFor, setBookFor] = useState("");
	const [selectedSlotSeats, setSelectedSlotSeats] = useState(0);

	const [excludeTimes, setExcludeTimes] = useState([]);

	const [readyForBooking, setReadyForBooking] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  const periods = [
    { label: "12 am - 4 am", value: "00:00" },
    { label: "4 am - 8 am", value: "04:00" },
    { label: "8 am - 12 pm", value: "08:00" },
    { label: "12 pm - 4 pm", value: "12:00" },
    { label: "4 pm - 8 pm", value: "16:00" },
    { label: "8 pm - 12 am", value: "20:00" },
  ];
  
  const CustomTimeInput = ({ value, onChange, excludeTimes }) => {
    // for making it easy to check against our periods
    const reformatExcludeTimes = excludeTimes.map((t) =>
      moment(t).format("HH:mm")
    );
  
    return (
      <div
        style={{
          padding: "8px",
        }}
      >
        {periods.map((p) => (
          <Button
          className={
            selectedButton === p.value
                ? "ml-3 p-button-raised p-button-info mr-2 mb-2 selected-button"
                : "p-button-raised mr-2 mb-2"
        }
        icon={
          selectedButton === p.value
              ? "pi pi-check selected-button"
              : ""
      }
          type="button"
            style={{
              display: "block",
              width: "100%",
              padding: "6px 3px",
              marginBottom: 3,
              cursor: "pointer",
            }}
            disabled={reformatExcludeTimes.includes(p.value)}
            onClick={() => {onChange(p.value)
            setSelectedButton(p.value)}}
          >
            {p.label}
          </Button>
        ))}
  
      </div>
    );
  };
  
  const countSeatsOfSlot = (startTime, tickets) => {
    const sameSlotBookings = tickets.filter(
      (t) =>
        moment(t.data.from.toDate()).isSame(startTime, "hour") &&
        moment(t.data.to.toDate()).isSame(
          moment(startTime).add(4, "hour"),
          "hour"
        )
    );
  
    let seats = 0;
    sameSlotBookings.forEach((b) => (seats = seats + b.data.seats));
  
    return { tickets: sameSlotBookings, seats: seats };
  };

	useEffect(() => {
		if (refreshTickets) {
			(async () => {
        console.log(currentUser.phoneNumber)
				const fetchedTickets = await getTickets();
				setTickets(fetchedTickets);
				setRefreshTickets(false);
				setReadyForBooking(true);
			})();
		}
	}, [refreshTickets]);

	useEffect(() => {
		const normalizeStartDate = startDate
			? moment(startDate)
			: moment().startOf("day");

		const { seats } = countSeatsOfSlot(normalizeStartDate, tickets);
		setSelectedSlotSeats(seats);

		const newExcludeTimes = [];
		tickets.forEach((t) => {
			// disable time slots of only selected date
			if (moment(t.data.from.toDate()).isSame(normalizeStartDate, "day")) {
				// count number of seats booked for this one ticket
				const { seats } = countSeatsOfSlot(t.data.from.toDate(), tickets);
				// if this ticket has been booked for 5, disable it
				if (seats >= 5) {
					newExcludeTimes.push(t.data.from.toDate());
				}
        if (seats>3 && selectButtonValue1?.name === 'ذهاب وإياب') {
					newExcludeTimes.push(t.data.from.toDate());
          setSeatmax(false);
				}else if (selectButtonValue1?.name === 'ذهاب فقط'){
          setSeatmax(true);
        }
			}
		});
		setExcludeTimes(newExcludeTimes);
	}, [startDate, tickets]);

	const onBook = async () => {
		setReadyForBooking(false);

    await addTicket({
      id: currentUser.uid,
			seats: Number(bookFor),
			from: moment(startDate).startOf("hour").toDate(),
			to: moment(startDate).startOf("hour").add(4, "hour").toDate(),
		});
		setBookFor("");
		setRefreshTickets(true);
	};

	const remainingSeats = 5 - selectedSlotSeats;

	const disableBookBtn = !readyForBooking || !bookFor || !startDate;

////////////////////////////////////////////////////////////////////



  const handleInput = (e) => {
    setValue(e.target.value);
    setUgo(false);
  };

  const handleInput1 = (e) => {
    setValue(e.target.value);
    setOthercity();
    setHosinfo();
    setHosp();
    setWermemo();
    setOthercountry();
  };

  if(convaddress !== null && ugo === true){
     setGofrom(convaddress.formatted_address);
     setCC(true);
     for (const component of convaddress.address_components) {
      const componentType = component.types[5];
      switch (componentType) {
        case "country": {
         // caountry = component.long_name;
          setCountry (component.long_name);      
          break;
        }
          //no default
      }
    }
     return setUgo(false)
  }

  const handleSelect = async (address) => {

    setDis(false);
    setValue(address, false);
    clearSuggestions();
    let distract = "";
    let postcode = "";
    let city = "";
    let state = "";
    let stnum = "";
    let route ="";
    let caountry = "";

    try {
      const results = await getGeocode({ address });
      for (const component of results[0].address_components) {
        const componentType = component.types[0];

        switch (componentType) {

          case "street_number": {
            stnum = component.long_name;
            break;
          }

          case "route": {
            route = component.long_name;
            setRoute(component.long_name);
            break;
          }

          case "political": {
            distract = component.long_name;
            setDistract(component.long_name);
            break;
          }
    
          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            //Setpastcode(postcode)
            setPostcode(postcode);
            break;
          }
    
          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            
            break;
          }
          case "locality":
            city = component.long_name
            setCity (component.long_name)
            break;
          case "administrative_area_level_1": {
            state = component.short_name
            break;
          }

          case "country": {
            caountry = component.long_name;
            setCountry (component.long_name);
            
            break;
          }
    
            //no default
        }
      }

      setOthercountry ('')


      const { lat, lng } = await getLatLng(results[0]);
      setFrom({ lat, lng});
    
      goFrom({ lat, lng });
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };




  const handleSelect1 = async (address) => {
    setGofrom();
    setCC(false);
    setSubdis(false);
    setPlz(address, false);
    clearSuggestions();
    let distract1 = "";
    let postcode1 = "";
    let city1 = "";
    let state1 = "";
    let stnum1 = "";
    let route1 ="";
    let caountry = "";

    try {
      const results = await getGeocode({ address });
      for (const component of results[0].address_components) {
        const componentType = component.types[0];
    
        switch (componentType) {

          case "street_number": {
            stnum1 = component.long_name;
            break;
          }

          case "route": {
            route1 = component.long_name;
            setRoute1(component.long_name);
            break;
          }

          case "political": {
            distract1 = component.long_name;
            setDistract1(component.long_name);
            break;
          }
    
          case "postal_code": {
            postcode1 = `${component.long_name}${postcode1}`;
            setPostcode1(postcode);
            break;
          }
    
          case "postal_code_suffix": {
            postcode1 = `${postcode1}-${component.long_name}`;
            break;
          }
          case "locality":
            city1 = component.long_name
            setCity1(component.long_name)
            break;
          case "administrative_area_level_1": {
            state1 = component.short_name
            break;
          }

          case "country": {
            caountry = component.long_name;
            setCountry1 (component.long_name);
            
            break;
          }
    
            //no default
        }
      }
      if (results[0].types.includes('hospital')) {
        setHosinfo ('اقسام الطوارئ مخصصة للهلال الأحمر، في حال تسجيل بلاغ مسبق يرجى ذكره في الملاحظات')
        setHosp(address.split("،")[0]);
       }
      if (city1 === 'Mecca') {city1 = 'Makkah'}

       if (locCity !== locCity1) {
        setOthercity ('للإحاطة: سوف يتم فرض رسوم إضافية للنقل خارج المدن')
        console.log (locCity +' to '+locCity1)
      }
      let fram = convaddress.formatted_address.split(' ')
      if (!fram.includes('Saudi Arabia') && !fram.includes('السعودية') && !fram.includes('المملكة العربية السعودية') && !fram.includes('السعوديه') && !fram.includes('Saudi') && !fram.includes('Saudia')){
        setOthercountry ('يبدو أن الموقع المحدد يقع خارج النطاق المدعوم حالياً')
        console.log(fram)
      }
      
      const { lat, lng } = await getLatLng(results[0]);
      setTo({lat, lng});
      goTo({ lat, lng });
      panTo({ lat, lng });
      fetchDirections({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };


//if (toggleValue === false && urg === null) {setUrg('غير طارئ')}
if (waiting === false && wait === null) {setWait('لا')}



  async function handleSubmit(e) {
    e.preventDefault()
    
//console.log(memo.current.value.length)
    setCordered();
    setWermemo();
    setOrdered();
    setSubdis(true);
    setOthercountry();
    //setTimeout(() => {}, 1000)
    
let fromcity = locCity;
let tocity = locCity1;
if (fromcity === 'At Taif'){fromcity = 'Taif'}
if (tocity === 'At Taif'){tocity='Taif'}
if (fromcity === 'Mecca'){fromcity = 'Makkah'}
if (tocity === 'Mecca'){tocity='Makkah'}

let fram = convaddress.formatted_address.split(' ')
let taw = convaddressGo.formatted_address.split(' ')
    if (!(!(!taw.includes('Saudi Arabia') && !taw.includes('Saudi') && !taw.includes('السعودية') && !taw.includes('المملكة العربية السعودية') && !taw.includes('السعوديه')) && !(!fram.includes('Saudi Arabia') && !fram.includes('Saudi') && !fram.includes('السعودية') && !fram.includes('المملكة العربية السعودية') && !fram.includes('السعوديه')))){
       setOthercountry ('يبدو أن الموقع المحدد يقع خارج النطاق المدعوم حالياً، الرجاء التأكد من أن الموقع داخل المملكة العربية السعودية')
       return setSubdis(false);
    }
    //console.log(fram);
    //console.log(taw);
var memosa;
var agreement;
   if (memo.current && hosifno !== undefined) {
     if (memo.current.value === '' && hosifno !== undefined){
        setWermemo('لا يمكن تجاهل خانة الملاحظات')
        return setSubdis(false);
     }
     memosa = memo.current.value
      //return console.log('Hey!')
   } if (!memo.current) {
      memosa = null
     //return console.log('NO')
   }
    if (selectButtonValueurg === null){
      setNourg ('يُرجى تحديد حالة الطلب')
      setWermemo()
      return setSubdis(false);
   }
   if (selectButtonValueurg.name === 'غير طارئ' && imageUpload === null){
     setNoattach ('يرجى إرفاق الموعد في حالة الطلب الغير طارئ')
    return setSubdis(false);
 }
    if (selectButtonValue1 === null){
       setNourg ('يُرجى تحديد نوع الطلب')
       setNoattach('')
       return setSubdis(false);
    }
    if (!checkboxValue.includes("Agreed")){
      setAproving ('لا يمكن تجاهل الإقرار')
      setReq ('')
      setNoattach('')
      return setSubdis(false);
   }
   if (remainingSeats === 0){
    setAproving ('نعتذر، يبدو أن توقيت الموعد المُختار غير متاح،  يرجى إختيار توقيت اخر.')
    setReq ('')
    setNoattach('')
    return setSubdis(false);
 }
 if (remainingSeats<2 && limit) {
   //toast.current.show({ severity: 'error', summary: 'لا يوجد رحلات متاحة للموعد المُحدد، يرجى تغيير توقيت الموعد والمحاولة مجدداً', life: 4000 })
   setAproving ('لا يوجد رحلات متاحة للموعد المُحدد، يرجى تغيير توقيت الموعد والمحاولة مجدداً')
  //setErrSub('لا يوجد رحلات متاحة للموعد المُحدد، يرجى تغيير توقيت الموعد والمحاولة مجدداً')
  return setSubdis(false);
}
   if (checkboxValue.includes("Agreed")){
    agreement = ' بأن جميع المعلومات المدخلة صحيحة. وأتحمل المسؤولية اذا إتضح خلاف ذلك '+user.name+' أقر انا'
 }
    if (fromcity !== tocity) {
       setOthercity ('للإحاطة: سوف يتم فرض رسوم إضافية للنقل خارج المدن')
    }
    setAproving();
    setOthercity();
    setReq();
    setWermemo();
    let simpleFrom = fromcity+','+locDistract
    let simpleTo = tocity+','+locDistract1
    //setTimeout(() => {console.log('loading...')}, 3500)
    

  try { setSubdis(true);
    setNoattach ('')
    setNourg ('')
    setWermemo()
      /*console.log(user.name)
      console.log(currentUser.uid)
      console.log(home)
      console.log(dest)
      console.log('From '+convaddress.formatted_address)
      console.log('To '+convaddressGo.formatted_address)
      console.log(selectButtonValue1.name)
      console.log(toggleValue)
      console.log(fromcity+' AND '+tocity)*/
      if (nourgg === false) {onBeforeUpload();}


      let TickFrom = moment(startDate).startOf("hour").format('MMMM Do YYYY, h:mm a');
      let TickTo = moment(startDate).startOf("hour").add(4, "hour").format('MMMM Do YYYY, h:mm a');

      const runorder = async () => {
        try {
          await db.collection('orders').doc(currentUser.uid).get().then(doc => {
            const arch = async () => {
              try {
                const archive = functions.httpsCallable('archive');
            // call the function and pass data
           await archive({

                    displayName: doc.data().displayName,
                    email: doc.data().email,
                    userid: doc.data().userid,
                    phonenum: doc.data().phonenum,
                    tempid: doc.data().tempid,
                    paymentmessage:doc.data().paymentmessage,
                    showpay: doc.data().showpay,
                    attachment: doc.data().attachment,
                    from: doc.data().from,
                    to: doc.data().to,
                    TimeFrom: data().TimeFrom,
                    TimeTo: data().TimeTo,
                    HospitalMemo: doc.data().HospitalMemo,
                    Hospital: doc.data().Hospital,
                    locationfrom: doc.data().locationfrom,
                    locationto: doc.data().locationto,
                    whichway: doc.data().whichway,
                    urgent: doc.data().urgent,
                    waiting: doc.data().waiting,
                    fromcity: doc.data().fromcity,
                    simpleFrom: doc.data().simpleFrom,
                    simpleTo: doc.data().simpleTo,
                    tocity: doc.data().tocity,
                    paramedic: doc.data().paramedic,
                    ParaMedicAttach: doc.data().ParaMedicAttach,
                    status: 'تم بنجاح',
                    createdAt: doc.data().createdAt,
                    CompletedAt: doc.data().CompletedAt,
                    ambulancePickedAt: doc.data().ambulancePickedAt,
                    acceptedAt: doc.data().acceptedAt,
                    price:doc.data().price,
                    inOut:doc.data().inOut,
                    finished: true,
                    Calendar: doc.data().Calendar,
                    TeamLeader: doc.data().TeamLeader,
                    SecondParamedic: doc.data().SecondParamedic,
                    CarNum: doc.data().CarNum,
                    CarTemp: doc.data().CarTemp,
                    PatientName: doc.data().PatientName,
                    MedicalRecord: doc.data().MedicalRecord,
                    Weight: doc.data().Weight,
                    Height: doc.data().Height,
                    OnRoute: doc.data().OnRoute,
                    AtPatient: doc.data().AtPatient,
                    MovingFromPt: doc.data().MovingFromPt,
                    ArrivedAt: doc.data().ArrivedAt,
                    OffLoad: doc.data().OffLoad,
                    PTVS: doc.data().PTVS,
                    Bedridden: doc.data().Bedridden,
                    FoleyCatheter: doc.data().FoleyCatheter,
                    CanPtSit: doc.data().CanPtSit,
                    PegTube: doc.data().PegTube,
                    NeedOx: doc.data().NeedOx,
                    NGtube: doc.data().NGtube,
                    NeedSuction: doc.data().NeedSuction,
                    UsedAmbBefore: doc.data().UsedAmbBefore,
                    OnGoingMeds: doc.data().OnGoingMeds,
                    MaleSitter: doc.data().MaleSitter,
                    Complicationsduringtransportation: doc.data().Complicationsduringtransportation,
                    L: doc.data().L,
                    Via: doc.data().Via,
                    Treatment: doc.data().Treatment,
                    OtherComment: doc.data().OtherComment,
                    ParamedicSign: doc.data().ParamedicSign,
                    Reviver: doc.data().Reviver,
                    ReceivingSign: doc.data().ReceivingSign,
                    PersonaliD: doc.data().PersonaliD,
                    ambulance: doc.data().ambulance,
                    Firstparaname: doc.data().Firstparaname,
                    Secondparaname: doc.data().Secondparaname,
                    agreement: doc.data().agreement,
                    userAGE: doc.data().userAGE,
                    details: doc.data().details,
                    userWEIGHT: doc.data().userWEIGHT,
                    status_comment: doc.data().status_comment,
                    paymentMETHOD: doc.data().paymentMETHOD,
      

            }).then(result => {
               console.log(result.data);
            }); 
              }
              catch (e) {
                console.log(e)
              }
            }
           if (doc.exists){
            arch();
            //return console.log('YES');
            
            // DELETE HERE

            setTimeout(() => {

              const newOrder = functions.httpsCallable('newOrder');
              // call the function and pass data
              newOrder({ 
                uid: currentUser.uid,
                displayName: user.name,
                email: user.email,
                userid: currentUser.uid,
                phonenum: currentUser.phoneNumber,
                tempid:'',
                paymentmessage:'',
                agreement: agreement,
                attachment: appurl,
                from: convaddress.formatted_address,
                to: convaddressGo.formatted_address,
                TimeFrom: TickFrom,
                TimeTo: TickTo,
                HospitalMemo: memosa,
                Hospital: hosp,
                locationfrom: home,
                locationto: dest,
                whichway: selectButtonValue1.name,
                urgent: selectButtonValueurg.name,
                waiting: wait,
                fromcity: fromcity,
                simpleFrom: simpleFrom,
                simpleTo: simpleTo,
                tocity: tocity,
                paramedic: [''],
                ParaMedicAttach:[],
                ambulance: '',
                Firstparaname:'',
                Secondparaname:'',
                createdAt: timestamp,
                inOut:'',
              }).then(result => {
                 console.log(result.data);
              });
            setCordered('تم تقديم الطلب بنجاح! سيتم مراجعة وقبول الطلب في حال توفر جميع البيانات الصحيحة');
            setTimeout(() => {dispatch (orders({ordered: true, Rated: false, from:convaddress.formatted_address,to:convaddressGo.formatted_address,status:'تحت المراجعة',urgent:selectButtonValueurg.name,whichway:selectButtonValue1.name,createdAt:timestamp,price:'',inOut:'',waiting: wait, tempid: '',paymentmessage: '',showpay: false, paymentMETHOD:'', TimeFrom: TickFrom, TimeTo: TickTo,}))}, 1000)


            }, 10000)
          } else {
            //return console.log('NO');
            const newOrder = functions.httpsCallable('newOrder');
            // call the function and pass data
            newOrder({ 
              uid: currentUser.uid,
              displayName: user.name,
              email: user.email,
              userid: currentUser.uid,
              phonenum: currentUser.phoneNumber,
              tempid:'',
              paymentmessage:'',
              agreement: agreement,
              attachment: appurl,
              from: convaddress.formatted_address,
              to: convaddressGo.formatted_address,
              TimeFrom: TickFrom,
              TimeTo: TickTo,
              HospitalMemo: memosa,
              Hospital: hosp,
              locationfrom: home,
              locationto: dest,
              whichway: selectButtonValue1.name,
              urgent: selectButtonValueurg.name,
              waiting: wait,
              fromcity: fromcity,
              simpleFrom: simpleFrom,
              simpleTo: simpleTo,
              tocity: tocity,
              paramedic: [''],
              ParaMedicAttach:[],
              ambulance: '',
              Firstparaname:'',
              Secondparaname:'',
              createdAt: timestamp,
              inOut:'',
            }).then(result => {
               console.log(result.data);
            });
          setCordered('تم تقديم الطلب بنجاح! سيتم مراجعة وقبول الطلب في حال توفر جميع البيانات الصحيحة');
          setTimeout(() => {dispatch (orders({ordered: true, Rated: false, from:convaddress.formatted_address,to:convaddressGo.formatted_address,status:'تحت المراجعة',urgent:selectButtonValueurg.name,whichway:selectButtonValue1.name,createdAt:timestamp,price:'',inOut:'',waiting: wait, tempid: '',paymentmessage: '',showpay: false, paymentMETHOD:'', TimeFrom: TickFrom, TimeTo: TickTo,}))}, 1000)
          }
 

            }); 
        }
        catch (e) {
          console.log (e)
        }
      }

      onBook();
      runorder();
      
      
  } catch (error) {
    setOrdered('عُذراً يوجد لديك طلب مسبق، الرجاء التحقق من حالة الطلب او إلغائة لتتمكن من الطلب مرة اخرى');
    console.log(error)
    return setSubdis(false);
  }
}

  const whichway = [
    { name: 'ذهاب فقط', code: 'OneWay' },
    { name: 'ذهاب وإياب', code: 'RoundTrip', disabled: !{seatmax}, },
];

const norurg = [
  { name: 'طارئ', code: 'urg' },
  { name: 'غير طارئ', code: 'noturg' },
];



if (wait === 'نعم' && waitmes === null){
  setWaitmes('للإحاطة: سيتم فرض رسوم إضافية (١٥٠ ريال لكل ساعة انتظار)')
} else if (wait === 'لا' && waitmes === 'للإحاطة: سيتم فرض رسوم إضافية (١٥٠ ريال لكل ساعة انتظار)') {setWaitmes(null)}

const onSelect = (file) => {
  //console.log('Selected!!')

  if (file.files[0].size >= 1000000 ){
    return setNoattach('حجم المرفق يجب ان لا يكون اكبر من ١ ميقابايت')
  } else {
    setDisattach(true);
    setNoattach('')
   return setImageUpload(file.files[0])
  }
}

const onClear = () => {
  //console.log('Cleared!!')
  setNoattach('')
  return setDisattach(false);
}

const onBeforeUpload = () => {
  var uploadTask = storageRef.child(`appointments/${currentUser.uid + imageUpload.name}`).put(imageUpload);
  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(progress);
    //console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        //console.log('Upload is paused');
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        //console.log('Upload is running');
        break;
        default:
        break;
    }
  }, 
  (error) => {
    console.log(error)
  }, 
  () => {
    // Upload completed successfully, now we can get the download URL
    //toast.current.show({ severity: 'success', summary: 'تم الرفع بنجاح', detail: 'سيتم مراجعة الموعد بعد لحظات', life: 4000 });
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      //console.log('File available at', downloadURL);
      setAppurl(downloadURL);
      //console.log('Uploaded!!')
      const attachment = functions.httpsCallable('attachment');
      // call the function and pass data
      attachment({attachment: downloadURL, uid: currentUser.uid,}).then(result => {
         console.log(result.data);
      }); 
    });
  }
);
}

const onCheckboxChange = (e) => {
  let selectedValue = [...checkboxValue];
  if (e.checked)
      selectedValue.push(e.value);
  else
      selectedValue.splice(selectedValue.indexOf(e.value), 1);

  setCheckboxValue(selectedValue);
  //console.log(checkboxValue)
};


   //<ToggleButton checked={toggleValue} onChange={(e) =>{ if (e.value===false){setUrg('غير طارئ')} else if (e.value===true){setUrg('طارئ')} setToggleValue(e.value)}} onLabel="طارئ" offLabel="غير طارئ" />

  return (
    <>

     <div className="search">
     <Toast ref={toast} />
      <Combobox onSelect={handleSelect}>
      <ComboboxInput className = "w-full mb-3 InputText" type="text" 
          value={gofrom}
          onChange={handleInput}
          disabled={!ready}
          placeholder="نقطة الإنطلاق"
        />
        
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" && cC === false &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>

      
      <Locate panTo={panTo} goFrom={goFrom} />
    
      <Combobox onSelect={handleSelect1}>
        <ComboboxInput className = "w-full mb-3" type="text"
          value={goto}
          onChange={handleInput1}
          disabled={!ready || (dis && convaddress === null) }
          placeholder="مكان الوصول"
        /> 
        <ComboboxPopover>
          <ComboboxList>  
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      </div>
      <div className="text-center mb-4">{othercity && <small id="city-help" style={{fontSize:"small"}} className="p-error">{othercity}</small>}</div>
      <div className="text-center mb-4">{othercountry && <small id="country-help" style={{fontSize:"small"}} className="p-error">{othercountry}</small>}</div>
      <div className="text-center mb-4">{hosifno && <small id="hospital-help" style={{fontSize:"small"}} className="p-error">{hosifno}</small>}</div>
      <div className="text-center mb-4">{hosifno && <InputText className = "w-full mb-3 rtl" type="text" placeholder="الملاحظات" ref={memo} />}</div>
      <div className="text-center mb-4">{wermemo && <small id="order-help" style={{fontSize:"small"}} className="p-error">{wermemo}</small>}</div>
      

      <Form onSubmit={handleSubmit}>
          
            <Form.Group className="text-center mb-4" id="Order-urgent">
              <h5>نوع الطلب</h5>
              <SelectButton value={selectButtonValueurg} onChange={(e) =>{ if (e.value?.name==='غير طارئ'){setNourgg(false)} else if (e.value?.name==='طارئ'){setNourgg(true)} else {setNourgg(true)} setSelectButtonValueurg(e.value)}} options={norurg} optionLabel="name" />
            </Form.Group>
            
            <div className="mb-3" hidden={nourgg}><FileUpload disabled={disattach} onSelect={onSelect} onBeforeUpload={onBeforeUpload} onRemove={onClear} progressbar={progress} chooseLabel="إرفاق صورة الموعد" uploadOptions={{className:"hidden"}} cancelOptions={{className:"hidden"}} name="demo[]"  accept="image/jpeg,image/png, application/pdf" maxFileSize={1000000} invalidFileSizeMessageDetail='حجم المرفق اكبر من المسموح به'  /></div>
            <div className="text-center mb-4">{noattach && <small id="order-help" style={{fontSize:"small"}} className="p-error">{noattach}</small>}</div>
            <Form.Group>
            <SelectButton value={selectButtonValue1} onChange={(e) =>{ if (e.value?.name==='ذهاب وإياب'){setWichh(false); setBookFor(2); setLimit(true); setStartDate(null); setSelectedButton(null); setWichh1(true)} else if (e.value?.name==='ذهاب فقط'){setWichh1(false); setBookFor(1); setLimit(false); setWichh(true); setSeatmax(true); setSelectedButton(null); setStartDate(null)} else {setWichh(true); setWichh1(true); setSeatmax(true)} setSelectButtonValue1(e.value)}} options={whichway} optionLabel="name" required={true} />
            <div className="text-center mb-4">{nourg && <small id="order-help" style={{fontSize:"small"}} className="p-error">{nourg}</small>}</div>

            <div className="text-center mt-2">
            <span hidden={wichh} className="p-error font-medium">للإحاطة: سوف تكون تكلفة الذهاب والإياب ٦٢٥ ريال</span>
            <div className="text-center mt-2">
            <span hidden={wichh} className="p-error font-medium"> (بدون ضريبة القيمة المضافة) </span>
            </div>
            <span hidden={wichh1} className="p-error font-medium">للإحاطة: سوف تكون تكلفة الذهاب ٤٠٠ ريال</span>
            <div className="text-center mt-2">
            <span hidden={wichh1} className="p-error font-medium"> (بدون ضريبة القيمة المضافة) </span>
            </div>
            <div className="mt-7">
            <span hidden={seatmax} className="p-error font-medium">تم إغلاق بعض المواعيد لعدم إتاحتها لرحلات الذهاب والإياب، بإمكانك إختيار احد المواعيد المتاحة او إختيار يوم آخر</span>
            </div>
            </div>
            </Form.Group>



            <div className="text-center mb-4 w-full">
				<h5>موعد الطلب</h5>
				<DatePicker
					inline
					selected={startDate}
					onChange={(date) => setStartDate(date)}
					timeFormat="HH:mm"
					timeIntervals={240}
					excludeTimes={excludeTimes}
					showTimeInput
					customTimeInput={
						<CustomTimeInput excludeTimes={excludeTimes} />
					}
				/>
				<p
					style={{
						marginTop: "8px",
            color:"green"
					}}
				>
					الرحلات المتاحة للموعد المحدد: {remainingSeats}
				</p>

			</div>





            <Form.Group className="text-center mb-4" id="Order-urgent">
              <h5>هل تريد من الطاقم الانتظار</h5>
              <ToggleButton checked={waiting} onChange={(e) =>{ if (e.value===false){setWait('لا')} else if (e.value===true){setWait('نعم')} setWaiting(e.value)}} onLabel="نعم (سيتم فرض رسوم إضافية)" offLabel="لا" />
            </Form.Group>
            <div className="text-center mb-4">{waitmes && <small id="city-help" style={{fontSize:"small"}} className="p-error">{waitmes}</small>}</div>


            <div className="text-center mb-4">{req && <small id="city-help" style={{fontSize:"small"}} className="p-error">{req}</small>}</div>
            <div>
            <div className="field-checkbox rtl"><Checkbox inputId="checkOption1" name="option" value="Agreed" checked={checkboxValue.indexOf('Agreed') !== -1} onChange={onCheckboxChange} />
                  
                    <label className="mr-2 ml-2 mt-2" htmlFor="checkOption1"> أُقر بأن المعلومات المدخلة اعلاه صحيحة، واتحمل المسؤولية اذا اتضح خلاف ذلك. </label>
                      </div>
                </div>
                <div className="text-center mb-4">{aproving && <small id="city-help" style={{fontSize:"small"}} className="p-error">{aproving}</small>}</div>
            <Button label="تقديم الطلب" className="w-full" type="submit" disabled={subdis || disableBookBtn} />
             <div className="text-center mb-4">{ordered && <small id="order-help" style={{fontSize:"small"}} className="p-error">{ordered}</small>}</div>
             <div className="text-center mb-4">{cordered && <small id="order-help" style={{fontSize:"small", color:"green"}} className="p-error">{cordered}</small>}</div>
          </Form>


    </>
    
    
  );
}
