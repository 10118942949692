import { db } from "./firebase";

export const getTickets = async () => {
	const temp = [];
	const querySnapshot =  await db.collection('tickets');
	querySnapshot.onSnapshot((snapshot) => {
		snapshot.docs.forEach((doc) => {
			temp.push({
				id: doc.id,
				...doc.data(),
			});
		})
	});
	return temp;
};

export const addTicket = async (data) => {
	await db.collection('tickets').doc(data.id).set({data});
};
