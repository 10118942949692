import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";


const app = firebase.initializeApp({
  apiKey: "AIzaSyDcpWziB3btqwR2s9w-v4kdN8KcWlHJ-zA",
  authDomain: "noaawin.med.sa",
  projectId: "backend-noaawin-saas",
  storageBucket: "backend-noaawin-saas.appspot.com",
  messagingSenderId: "460618549749",
  appId: "1:460618549749:web:8e56411e7b898d9010f8bc",
  measurementId: "G-V42Z3RG0NL"
})
var messaging = null;
if (firebase.messaging.isSupported()){
    messaging = app.messaging();
}
export const auth = app.auth();
export const db = app.firestore();
export const functions = app.functions();
export const getToken = (setTokenFound,setCurrentToken) => {

  function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      
        return messaging.getToken({ vapidKey: process.env.Messaging_VapidKey }).then((currentToken) => {
          if (currentToken) { 
            //console.log('current token for client: ', currentToken);
            setTokenFound(true);
            setCurrentToken(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // catch error while creating client token
        });
      
      }
        else {
            console.log('Notification permission not granted.');
        }
      })}
      requestPermission()
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
export default app
