import React, {useState} from "react"
import { Route, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import {useSelector} from 'react-redux'
import Loading from '../pages/Loading';



export default function AdminRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  //const location = useLocation()
  const [roles, setRoles] = useState()
  const history = useHistory()
  const user = useSelector ((state) => state.user.value )

  return (
    <Route
      {...rest}
      render={props => {

        if (currentUser){
          setRoles(user.role)
          if (roles) {
          if (roles.includes('admin')) { return  <Component {...props} /> } else{
            return <Loading/>}
          }
        } else {return history.push("/phonelogin")}
        //return currentUser ? <Component {...props} /> : <Redirect to="/login" state={{from : location}} replace />
      }}
    ></Route>
  )
}
